import PropTypes from 'prop-types';
import React from 'react';
import {default as ReactModal} from 'react-modal';

import Button from './Button';
import InlineSVGIcon from './InlineSVGIcon';

const Modal = ({
  className,
  contentComponent,
  headerComponent,
  centered = true,
  hasCloseBtn,
  fixed = true,
  isOpen,
  onAfterOpen,
  closeTimeoutMS,
  onRequestClose,
  shouldCloseOnOverlayClick,
  style,
}) => {
  const customContent = centered
    ? {
        left: '50%',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
      }
    : {
        top: 40,
        left: 40,
        right: 40,
      };
  const customStyles = {
    overlay: {
      position: fixed ? 'fixed' : 'absolute',
      zIndex: 100,
      overflow: 'auto',
    },
    content: {
      backgroundColor: 'transparent',
      border: 0,
      borderRadius: 0,
      bottom: 'auto',
      padding: 0,
      overflow: 'inherit',
      paddingBottom: 30,
      ...customContent,
    },
    ...style,
  };
  const primaryContent = <div className="modal__content">{contentComponent}</div>;
  const headerContent = headerComponent ? (
    <header className="modal__header">{headerComponent}</header>
  ) : (
    ''
  );
  const closeButton = hasCloseBtn ? (
    <Button className="modal__close" onClick={onRequestClose}>
      <InlineSVGIcon
        className="modal__close__icon"
        classNameInner="modal__close__icon__inner"
        iconId="c-icon-cross"
      />
    </Button>
  ) : null;

  return (
    <ReactModal
      closeTimeoutMS={closeTimeoutMS}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={customStyles}
      contentLabel="Modal"
      appElement={document.querySelector('body')}
    >
      <div className={`modal ${className || ''}`}>
        {closeButton}
        {headerContent}
        {primaryContent}
      </div>
    </ReactModal>
  );
};

Modal.displayName = 'Modal';
Modal.propTypes = {
  className: PropTypes.string,
  centered: PropTypes.bool,
  closeTimeoutMS: PropTypes.number,
  contentComponent: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
  headerComponent: PropTypes.node,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
  style: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
};

export default Modal;
