import PropTypes from 'prop-types';
import React from 'react';

import InlineSVGIcon from '../InlineSVGIcon';

const RadioCheckCustomIcon = ({type}) => (
  <i className="custom-check-radio">
    {['false', 'true'].map(modName => (
      <InlineSVGIcon
        key={modName}
        className={`custom-check-radio__icon--${modName}`}
        classNameInner="custom-check-radio__icon__inner"
        iconId={`c-icon-${type === 'radio' ? type : 'checkbox'}-${
          modName === 'true' ? 'inside' : 'outside'
        }`}
      />
    ))}
  </i>
);

RadioCheckCustomIcon.displayName = 'RadioCheckCustomIcon';
RadioCheckCustomIcon.propTypes = {
  type: PropTypes.string,
};

export default RadioCheckCustomIcon;
