import PropTypes from 'prop-types';
import React from 'react';
import identity from 'lodash/fp/identity';
import omit from 'lodash/fp/omit';

import fieldHOC from './Field';
import GridWrap from '../GridWrap';
import GridCol from '../GridCol';

const prepProps = omit(['optional', 'help']);

class RangeField extends React.Component {
  static displayName = 'RangeField';

  static propTypes = {
    displayTransform: PropTypes.func,
    min: PropTypes.number,
    value: PropTypes.any,
  };

  static defaultProps = {
    displayTransform: identity,
  };

  render() {
    const {value, displayTransform, ...props} = this.props;

    return (
      <GridWrap className="gw--middle">
        <GridCol baseWidth="9-of-12">
          <input {...prepProps(props)} defaultValue={value || props.min || 0} type="range" />
        </GridCol>

        <GridCol className="g--auto">
          <div className="soft--smallest--ends">{displayTransform(value || props.min || 0)}</div>
        </GridCol>
      </GridWrap>
    );
  }
}

export default fieldHOC(RangeField);
