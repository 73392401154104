/* eslint-disable */
global.__appSettings = {
  FLAGS: {
    enablePresence: webpackDefs.FLAGS.enablePresence,
    enablePractise: webpackDefs.FLAGS.enablePractise,
  },
  analytics: {
    google: {
      playerPortal: webpackDefs.analytics.google.playerPortal,
      accountHolderPortal: webpackDefs.analytics.google.accountHolderPortal,
    },
  },
  apiUrl: webpackDefs.apiUrl,
  auth: {rememberKey: webpackDefs.auth.rememberKey},
  jwt: {
    userStorageKey: webpackDefs.jwt.userStorageKey,
    playerStorageKey: webpackDefs.jwt.playerStorageKey,
  },
  raven: {dsn: webpackDefs.raven.dsn},
  stripe: {publicKey: webpackDefs.stripe.publicKey},
  sentry: {dsn: webpackDefs.sentry.dsn},
};
