export default {
  key: 'multiple-choice-timed-fall',
  name: 'Multiple Choice Timed Fall',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'multiple-choice',
  },

  vars: {
    text: {
      type: 'text',
      label: 'Text',
      help: `
        Surround placeholder text with {braces} for it to be hidden from users in
        the question.
      `,
    },
    hint: {type: 'text', label: 'Hint', optional: true},
    choices: {
      type: 'multiple-choice',
      label: 'Choices',
      max: 3,
    },
    timeLimit: {
      type: 'slider',
      label: 'Time limit',
      help: 'Time limit a question needs to be answered within.',
      min: 1,
      max: 60,
      step: 1,
      defaultValue: 10,
    },
  },
};
