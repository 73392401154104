const getRandomGridPos = grid => {
  const numX = grid[0].length;
  const numY = grid.length;
  const randX = Math.floor(Math.random() * numX);
  const randY = Math.floor(Math.random() * numY);
  // item must be more than 10% from grid center
  const minDistFromCenterX = Math.round((numX / 2 - numX * 0.2) / 2);
  const minDistFromCenterY = Math.round((numY / 2 - numY * 0.2) / 2);
  const isCenterX = Math.abs(numX / 2 - randX) <= minDistFromCenterX;
  const isCenterY = Math.abs(numY / 2 - randY) <= minDistFromCenterY;
  // item must not site on edge of grid
  const isEdgeX = randX === 0 || randX === numX - 1;
  const isEdgeY = randY === 0 || randY === numY - 1;
  const isValidPos =
    !grid[randY][randX] && !(isCenterX && isCenterY) && !(isEdgeX || isEdgeY);

  return isValidPos ? {x: randX, y: randY} : getRandomGridPos(grid);
};

export default getRandomGridPos;
