export default {
  key: 'multiple-choice-timed-cards',
  name: 'Multiple Choice Timed Cards',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'multiple-choice-timed-cards',
  },

  vars: {
    textFront: {type: 'text', label: 'Text Front'},
    textBack: {type: 'text', label: 'Text Back'},
    choices: {
      type: 'multiple-choice',
      label: 'Choices',
      max: 4,
    },

    timeLimit: {
      type: 'slider',
      label: 'Time Limit',
      help: 'Time limit for visibility of the front of the card in seconds',
      min: 1,
      max: 60,
      step: 1,
      defaultValue: 15,
    },
  },
};
