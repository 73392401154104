import {
  isPrime,
  getLargestDivisor,
  LINEWIDTH,
  setDivisionDefaults,
  setShapeDefaults,
} from './utils';

const rectangleFactory = ({twoInstance, center, shapeType, numDivisions}) => {
  const dims = {
    width: twoInstance.width - LINEWIDTH / 2,
    height:
      shapeType === 'square'
        ? twoInstance.width - LINEWIDTH / 2
        : 2 * twoInstance.width / 3 - LINEWIDTH / 2,
  };
  const shapeObj = {
    group: twoInstance.makeGroup(),
  };

  function init() {
    initShape();
    initDivisions();
  }

  function initShape() {
    shapeObj.shape = twoInstance.makeRectangle(
      center.x,
      center.y,
      dims.width,
      dims.height
    );
    setShapeDefaults(shapeObj.shape);

    shapeObj.group.add(shapeObj.shape);
  }

  function initDivisions() {
    shapeObj.divisions = isPrime(numDivisions)
      ? makeDivisionsFromPrime()
      : makeDivisions();

    shapeObj.divisions.map(div => shapeObj.group.add(div));
  }

  function makeDivisions() {
    const numRows = getLargestDivisor(numDivisions);
    const numCols = numDivisions / numRows;
    const divWidth = dims.width / numCols;
    const divHeight = dims.height / numRows;
    let currentRow = 0;
    let currentCol = 0;

    return Array.apply(null, Array(numDivisions)).map(() => {
      const division = twoInstance.makePath(
        0,
        0,
        divWidth,
        0,
        divWidth,
        divHeight,
        0,
        divHeight
      );
      division.translation.set(
        center.x - dims.width / 2 + divWidth / 2 + divWidth * currentCol,
        center.y - dims.height / 2 + divHeight / 2 + divHeight * currentRow
      );

      setDivisionDefaults(division);

      if (currentCol + 1 === numCols) {
        currentCol = 0;
        currentRow = currentRow + 1;
      } else {
        currentCol = currentCol + 1;
      }

      return division;
    });
  }

  function makeDivisionsFromPrime() {
    const divWidth = dims.width / numDivisions;
    const divHeight = dims.height;

    return Array.apply(null, Array(numDivisions)).map((_, i) => {
      const division = twoInstance.makePath(
        0,
        0,
        divWidth,
        0,
        divWidth,
        divHeight,
        0,
        divHeight
      );
      division.translation.set(
        center.x - dims.width / 2 + divWidth / 2 + divWidth * i,
        center.y - dims.height / 2 + divHeight / 2
      );
      setDivisionDefaults(division);

      return division;
    });
  }

  init();

  return shapeObj;
};

export default rectangleFactory;
