import PropTypes from 'prop-types';
import React from 'react';

import {Fraction} from './Fraction';

const fractionRegexp = /\d+\/\d+/g;

const FractionText = ({text}) => {
  const textToSplit = text || typeof text === 'number' ? text.toString() : '';
  const textParts = textToSplit.split(fractionRegexp);
  const fractionParts = textToSplit.match(fractionRegexp) || [];
  const parts = Array.apply(null, {
    length: textParts.length + fractionParts.length,
  }).map(
    (_, i) =>
      i % 2 === 0 ? (
        <span key={i}>{textParts[Math.floor(i / 2)]}</span>
      ) : (
        <Fraction key={i} fractionText={fractionParts[Math.floor(i / 2)]} />
      ),
  );

  return <span>{parts}</span>;
};

FractionText.displayName = 'FractionText';
FractionText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export {FractionText};
