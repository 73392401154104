export default {
  key: 'bar-graph',
  name: 'Bar Graph',
  description: '',

  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'bar-graph',
  },

  vars: {
    text: {type: 'text', label: 'Text'},
    xTitle: {type: 'string', label: 'X-Axis Title'},
    yTitle: {type: 'string', label: 'Y-Axis Title'},
    data: {type: 'bar-chart-data', label: 'Chart Data'},
    choices: {type: 'multiple-choice', label: 'Choices'},
  },
};
