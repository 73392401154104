export default {
  key: 'time-input',
  name: 'Time Input',
  description: '',

  resources: {thumbnailUrl: ''},

  summary: {
    type: 'time-input',
  },

  vars: {
    text: {type: 'text', label: 'Text'},
    time: {type: 'time', label: 'Time', defaultValue: {hour: 1, minute: 0}},
    possibleAnswers: {
      type: 'list',
      label: 'Possible answers',
      help: 'For example, "five past three" for 15:05 / 3:05',
    },
  },
};
