import PropTypes from 'prop-types';
import React from 'react';
import identity from 'lodash/fp/identity';

const OffcanvasContainer = ({
  type,
  className,
  isOpen,
  primaryComponent,
  secondaryComponent,
}) => {
  const activeClassName = isOpen ? 'is-open-offcanvas' : '';
  const mainClass = ['offcanvas', type].filter(identity).join('-');

  return (
    <div className={`${mainClass} ${className || ''} ${activeClassName}`}>
      <div className={`${mainClass}__secondary`}>{secondaryComponent}</div>

      <div className={`${mainClass}__primary`}>{primaryComponent}</div>
    </div>
  );
};

OffcanvasContainer.displayName = 'OffcanvasContainer';

OffcanvasContainer.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  primaryComponent: PropTypes.node.isRequired,
  secondaryComponent: PropTypes.node.isRequired,
};

export default OffcanvasContainer;
