import Two from '../two-no-conflict';

const PI = Math.PI;

const timerDialFactory = mountElem => {
  if (!mountElem || !('nodeType' in mountElem)) {
    throw new Error('no mount element provided');
  }

  let options = {
    arcColor: '#633d8a',
    arcRatio: 1,
    bgColor: '#fff',
    height: 40,
    type: 'svg',
    width: 40,
  };
  const paths = {};
  let two;

  function init(opts = {}) {
    options = {...options, ...opts};

    two = new Two({
      height: options.height,
      type: Two.Types[options.type],
      width: options.width,
    });

    two.appendTo(mountElem);
    drawBg();
    update();

    two.renderer.domElement.setAttribute('viewBox', `0 0 ${two.width} ${two.height}`);
    two.renderer.domElement.setAttribute(
      'style',
      `
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      `,
    );
  }

  function drawBg() {
    const bg = two.makeCircle(two.width / 2, two.height / 2, two.width / 2);
    bg.noStroke();
    bg.fill = options.bgColor;

    paths.bg = bg;
  }

  function drawArc(numPoints) {
    const {width} = two;
    const {arcRatio, arcColor} = options;

    if (paths.arcSegment) {
      two.remove(paths.arcSegment);
    }

    Two.Resolution = numPoints;

    const points = Array.apply(null, Array(numPoints)).map((_, i) => {
      const pct = i / (Two.Resolution - 1);
      const theta = -2 * PI * pct - PI * 0.5;
      const x = ((width * arcRatio) / 4) * Math.cos(theta);
      const y = ((width * arcRatio) / 4) * Math.sin(theta);

      return new Two.Anchor(x, y);
    });

    const arcSegment = two.makeCurve(points, true);
    arcSegment.noFill();
    arcSegment.translation.set(two.width / 2, two.width / 2);
    arcSegment.linewidth = (width * arcRatio) / 2;
    arcSegment.stroke = arcColor;
    arcSegment.beginning = 1;

    paths.arcSegment = arcSegment;

    update();
  }

  function updateArcProp(prop, value) {
    const {arcSegment} = paths;

    if (arcSegment) {
      arcSegment[prop] = value;

      const {beginning, ending} = arcSegment;
      arcSegment.closed = beginning === 0 && ending === 1;
      update();
    }
  }

  function update() {
    two.update();
  }

  function destroyPaths() {
    Object.keys(paths).map(key => {
      two.remove(paths[key]);
      return delete paths[key];
    });
  }

  function destroy() {
    destroyPaths();
    two.clear();
  }

  return {
    destroy,
    drawArc,
    init,
    updateArcProp,
  };
};

export default timerDialFactory;
