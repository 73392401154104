import PropTypes from 'prop-types';
import React from 'react';

const Pill = ({className, children}) => (
  <span className={`pill ${className || 'pill--ntrl-min'}`}>{children}</span>
);

Pill.displayName = 'Pill';

Pill.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Pill;
