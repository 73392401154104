import PropTypes from 'prop-types';
import React from 'react';
import Notification from './Notification';

const Notifications = ({notifications, onRequestClose}) => (
  <div className="notification-container">
    {notifications.map(a => <Notification key={a.id} {...a} onRequestClose={onRequestClose} />)}
  </div>
);

Notifications.displayName = 'Notifications';
Notifications.propTypes = {
  notifications: PropTypes.array,
  onRequestClose: PropTypes.func,
};

export default Notifications;
