export default {
  key: 'mass',
  name: 'Mass',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'mass',
  },

  vars: {
    text: {type: 'text', label: 'Text'},

    unitText: {
      type: 'text',
      label: 'Unit Text',
      help: 'Text displayed on the scale',
      defaultValue: 'grams (g)',
    },

    maxWeight: {type: 'number', label: 'Max Weight'},

    labelIncrement: {type: 'number', label: 'Label Increment'},

    numTicksBetweenLabels: {
      type: 'string',
      label: 'No. Ticks Between Labels',
      help: 'The number of ticks between each label',
      defaultValue: 1,
      optional: true,
    },

    dialWeight: {
      type: 'number',
      label: 'Weight',
      help: 'The weight to display between 0 and Max Weight',
    },

    isTrue: {
      defaultValue: false,
      help: ` Check this if the player should answer true.  `,
      label: 'Is correct',
      optional: true,
      type: 'boolean',
    },
  },
};
