export default {
  key: 'fractions-select',
  name: 'Fractions Select',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'fractions-select',
  },

  vars: {
    text: {
      type: 'text',
      label: 'Text',
      help: `
        fractions must be written in the form x/y (without spaces) to be
        correctly represented in the game
      `,
    },
    shape: {
      type: 'select',
      label: 'Shape',
      options: [
        'triangle',
        'circle',
        'rectangle',
        'square',
        'pentagon',
        'hexagon',
        'octagon',
        'decagon',
        'dodecagon',
      ],
    },
    divisions: {
      type: 'number',
      label: 'Divisions',
      help: `
        Only circles, squares, and rectangles can accept any no. of sides.
        Triangles accept 2 or 3 sides, all other shapes accept only their
        intrinsic number of sides.
      `,
    },
    numerator: {type: 'number', label: 'Numerator'},
    denominator: {type: 'number', label: 'Denominator'},
  },
};
