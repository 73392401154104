export default {
  key: 'sequencing',
  name: 'Sequencing',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'multiple-choice-sequencing',
  },

  vars: {
    text: {
      type: 'text',
      label: 'Text',
    },

    sequence: {
      type: 'text',
      label: 'Sequence',
      help: `Add a sentence that will be split by spaces into a sequence.
      To group words surround them in {braces}.`,
    },

    sequenceSpeed: {
      type: 'slider',
      label: 'Sequence Speed',
      min: 10,
      max: 2000,
      step: 10,
      defaultValue: 200,
      help: 'The time (ms) between each sequence item appearing',
    },

    hideOnSequenceEnd: {
      type: 'boolean',
      label: 'Hide on Sequence End',
      defaultValue: false,
      optional: true,
    },

    options: {
      type: 'multiple-choice',
      label: 'Multiple Choice Options',
      max: 2,
    },
  },
};
