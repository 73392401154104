import {errorTypes} from '../lib/getErrorMessage';

const validateRequired = (options = {}) => value => {
  return !Boolean(value) ? {type: errorTypes.required, value, ...options} : false;
};

const validateMinLength = (options = {minLength: 8}) => value => {
  const {minLength} = options;

  return value && value.length < minLength
    ? {type: errorTypes.minLength, value, minLength, ...options}
    : false;
};

const validateNoSpecialChars = (options = {}) => value => {
  const hasSpecialCharacters = value && value.match(/[^\w\.\-]/);
  return value && hasSpecialCharacters ? {type: errorTypes.noSpecialChars, value, ...options} : false;
};

const validatePassword = value => {
  const name = 'Password';
  const required = validateRequired({name});
  const length = validateMinLength({name, minLength: 8});
  const validationFn = [required, length].find(fn => fn(value));

  return validationFn ? validationFn(value) : false;
};

const validateNewOrExistingUserPassword = (password, {_id} = {}) => {
  const isExistingUser = Boolean(_id);
  const hasTouchedPassword = Boolean(password);

  return !isExistingUser || hasTouchedPassword ? validatePassword(password) : false;
};

export {validateMinLength, validateNewOrExistingUserPassword, validateNoSpecialChars, validatePassword, validateRequired};
