import PropTypes from 'prop-types';
import React from 'react';

function getCssClass(cssClass, modifier = '') {
  return modifier.length ? `${cssClass} ${cssClass}--${modifier}` : cssClass;
}

const ChartCircle = ({label, sublabel, modifier = ''}) => {
  const width = 250;
  const height = 250;

  const centerX = width / 2;
  const centerY = height / 2;

  return (
    <svg viewBox={`0 0 ${width} ${height}`} className="chart-circle">
      <circle
        className={getCssClass('chart-circle__circle', modifier)}
        cx={centerX}
        cy={centerY}
        r={width / 2}
      />

      {label ? (
        <text
          className={getCssClass('chart-circle__label', modifier)}
          x={centerX}
          y={centerY}
          dy={sublabel ? '.25em' : '.35em'}
        >
          {label}
        </text>
      ) : null}

      {sublabel ? (
        <text
          className={getCssClass('chart-circle__sublabel', modifier)}
          x={centerX}
          y={centerY}
          dy={label ? '2.5em' : '.5em'}
        >
          {sublabel}
        </text>
      ) : null}
    </svg>
  );
};

ChartCircle.displayName = 'ChartCircle';
ChartCircle.propTypes = {
  label: PropTypes.string.isRequired,
  sublabel: PropTypes.string.isRequired,
  modifier: PropTypes.string,
};

export default ChartCircle;
