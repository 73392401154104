export default {
  key: 'drag-and-drop-order',
  name: 'Drag And Drop Order',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'category-ordered',
  },

  vars: {
    text: {
      label: 'Text',
      type: 'text',
      optional: true,
    },

    isTopToBottom: {
      label: 'order top to bottom',
      type: 'boolean',
      help: `
        should users start ordering from the top or from the bottom?
      `,
      optional: true,
    },

    categories: {
      type: 'category-list',
      help: `
        the order of items used here determines the final order users are expected
        to create
      `,
      label: 'Categories',
      name: 'drag-and-drop-category-list',
      maxLists: 3,
      hideDescription: true,
    },
  },
};
