import React from 'react';
import PropTypes from 'prop-types';

class DynamicImage extends React.Component {
  static propTypes = {
    alt: PropTypes.string,
    fallbackSrc: PropTypes.string,
    src: PropTypes.string.isRequired,
  };

  state = {resolvedSrc: null};

  isMounted = false;

  componentDidMount() {
    const {src} = this.props;

    this.isMounted = true;

    // image paths must be provided relative to this component
    import(/* webpackMode: "eager" */ `../../img/raw/${src}`).then(resolvedSrc => {
      if (this.isMounted) {
        this.setState({resolvedSrc: resolvedSrc.default});
      }
    });
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    const {alt, fallbackSrc, src: srcToResolve, ...rest} = this.props;
    const {resolvedSrc} = this.state;
    const src = resolvedSrc || fallbackSrc;

    return src ? <img src={src} alt={alt} {...rest} /> : null;
  }
}

export {DynamicImage};
