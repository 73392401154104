import PropTypes from 'prop-types';
import React from 'react';
import ChartistGraph from 'react-chartist';

class ChartBar extends React.Component {
  static displayName = 'ChartBar';
  static propTypes = {
    data: PropTypes.array.isRequired,
    labelX: PropTypes.array.isRequired,
    labelY: PropTypes.string.isRequired,
    options: PropTypes.object,
  };

  render() {
    const {labelX, labelY, data, options} = this.props;
    const opts = {
      axisX: {
        showGrid: false,
        onlyInteger: true,
      },
      axisY: {
        showGrid: false,
        onlyInteger: true,
      },
      ...options,
    };
    const chartData = {
      labels: labelX,
      series: [data],
    };

    return (
      <div className="chart-bar">
        <div className="chart-bar__label">{labelY}</div>

        <ChartistGraph type="Bar" options={opts} data={chartData} />
      </div>
    );
  }
}

export default ChartBar;
