export default {
  key: 'match-pairs',
  name: 'Match Pairs',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  meta: {
    revealSpeed: {
      type: 'slider',
      label: 'Reveal speed',
      help: `The speed at which pairs are revealed in milliseconds - higher
      makes the game easier`,
      min: 500,
      max: 5000,
      step: 10,
      defaultValue: 1000,
    },
  },

  vars: {
    pair: {
      type: 'list',
      label: 'Word Pair',
      max: 8,
      minLength: 2,
      help: `Add up to 8 values that match. Each match a player obtains is
      counted as a correct answer.`,
    },
  },
};
