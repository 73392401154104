const errorTypes = {
  email: 'email',
  match: 'match',
  maxLength: 'maxLength',
  minLength: 'minLength',
  required: 'required',
  unique: 'unique',
  noSpecialChars: 'noSpecialChars',
};

const getErrorMessage = field => {
  const {type: errorType, value, message} = field.error;

  if (message) {
    return message;
  }

  const name = field.error.name || field.name;

  switch (errorType) {
    case errorTypes.required:
      return `${name || 'This field'} is required.`;
    case errorTypes.email:
      return 'Please enter a valid email address.';
    case errorTypes.unique:
      return `'${value}' is already taken.`;
    case errorTypes.match:
      return `Must match ${name || 'Field'}.`;
    case errorTypes.maxLength:
      if (Array.isArray(value)) {
        return `${name || 'Field'} must have at most ${field.error.minLength} items.`;
      } else {
        return `${name || 'Field'} must be at most ${field.error.minLength} characters long.`;
      }
    case errorTypes.minLength:
      if (Array.isArray(value)) {
        return `${name || 'Field'} must have at least ${field.error.minLength} items.`;
      } else {
        return `${name || 'Field'} must be at least ${field.error.minLength} characters long.`;
      }
    case errorTypes.noSpecialChars:
      return `${name || 'This field'} should not contain any spaces or special characters.`;
    default:
      return `${name || 'Field'} is invalid. Reason: ${field.error.reason}`;
  }
};

export {errorTypes};

export default getErrorMessage;
