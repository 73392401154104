import '../global';
import '../lib/logging';

import React from 'react';
import {render} from 'react-dom';
import {Provider as StateProvider} from 'react-redux';
import {browserHistory} from 'react-router';
import {syncHistoryWithStore} from 'react-router-redux';

import {createAppStore} from './store';
import App from './App';

import '../../css/style-account-holder-portal.scss';

const element = document.querySelector('.js-mount-point');
const store = createAppStore({
  history: browserHistory,
});

const history = syncHistoryWithStore(browserHistory, store);

render(
  <StateProvider store={store}>
    <App history={history} />
  </StateProvider>,
  element,
);

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
