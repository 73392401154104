import React from 'react';

export default {
  categoryList: (
    <div>
      <h2>Category List</h2>

      <p> This challenge uses category lists.</p>

      <p>
        Category lists allow one to create categories to which players must assign lists of options.
        Each question is made up of two or more categories, and a list of options a user will need
        to sort into the specified categories.
      </p>

      <p>
        e.g. the first question may ask a user to sort a list of nouns from a list of verbs. The
        next question may ask the user to sort a list of common nouns from a list of proper nouns.
        This challenge will only have 2 questions, but may contain many values to sort within each
        question.
      </p>

      <p>Be sure to adjust the win criteria based on the number of questions.</p>
    </div>
  ),

  answerBoundAnimations: (
    <div>
      <h2>Answer-bound Animations</h2>

      <p>
        This challenge has an animation which is bound to the number of correct answers a player
        obtains. Each correct answer progresses the animation forward one step.
      </p>

      <p>
        When a player reaches the number of correct answers required (configured under 'Win
        Criteria'), the animation will animate to its completed state.
      </p>

      <p>Any further correct answers will not progress the animation.</p>

      <p>
        <strong>TIP:</strong>
        <br />
        Under 'Win Criteria' set the challenge to end when the user obtains the number of answers
        required.
      </p>

      <p>This will tie the animation completion in with the challenge being completed.</p>

      <p>Templates that use answer-bound animations:</p>

      <ul>
        <li>Cloze</li>
        <li>Custom Keyboard</li>
        <li>Jumbled Words</li>
        <li>Multiple Choice Images</li>
        <li>Multiple Choice List</li>
        <li>Word Elimination</li>
      </ul>
    </div>
  ),
};
