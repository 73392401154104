import PropTypes from 'prop-types';
import React from 'react';
import Alert from './Alert';

const Alerts = ({alerts, onRequestClose}) => (
  <div className="alert-container">
    {alerts.map(a => (
      <Alert key={a.id} {...a} onRequestClose={onRequestClose} />
    ))}
  </div>
);

Alerts.displayName = 'Alerts';
Alerts.propTypes = {
  alerts: PropTypes.array,
  onRequestClose: PropTypes.func,
};

export default Alerts;
