export default {
  redirectUser: '/portal',
  redirectPlayer: '/play',
  forgotten: '/forgotten',
  resendConfirmation: '/resend-confirmation',
  login: '/login',
  playLogin: '/play/login',
  signUp: '/signup',
  confirmation: '/confirmation/:token',
  resetPassword: '/reset-password/:token',

  redirectForUser(user) {
    const role = user.roles[0] || 'user';
    switch (role) {
      case 'user':
        return this.redirectUser;
      case 'player':
        return this.redirectPlayer;
      default:
        return this.redirectUser;
    }
  },
};
