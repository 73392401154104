import {createStore, applyMiddleware, compose} from 'redux';
import {routerMiddleware} from 'react-router-redux';

import reducers from './reducers';

export function createAppStore(options, initialState = {}) {
  const middlewares = [routerMiddleware(options.history)];

  return createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      window.devToolsExtension ? window.devToolsExtension() : f => f
    )
  );
}
