import PropTypes from 'prop-types';
import React from 'react';
import MediaQuery from 'react-responsive';

const breakpoints = {
  lapStart: 481,
  deskStart: 880,
  deskWideStart: 1280,
};

const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

const MediaPalm = ({children, className}) => (
  <MediaQuery query={`(max-width: ${breakpoints.lapStart - 1}px)`}>{children}</MediaQuery>
);

const MediaLap = ({children, className}) => (
  <MediaQuery query={`(min-width: ${breakpoints.lapStart}px and ${breakpoints.deskStart - 1}px}})`}>
    {children}
  </MediaQuery>
);

const MediaLapAndUp = ({children, className}) => (
  <MediaQuery query={`(min-width: ${breakpoints.lapStart}px)`}>{children}</MediaQuery>
);

const MediaPortable = ({children, className}) => (
  <MediaQuery query={`(max-width: ${breakpoints.deskStart - 1}px)`}>{children}</MediaQuery>
);

const MediaDesk = ({children, className}) => (
  <MediaQuery query={`(min-width: ${breakpoints.deskStart}px)`}>{children}</MediaQuery>
);

MediaPalm.propTypes = propTypes;
MediaLap.propTypes = propTypes;
MediaLapAndUp.propTypes = propTypes;
MediaPortable.propTypes = propTypes;
MediaDesk.propTypes = propTypes;

export {MediaPalm, MediaLap, MediaLapAndUp, MediaPortable, MediaDesk};
