import PropTypes from 'prop-types';
import React from 'react';

import InlineSVGIcon from './InlineSVGIcon';

export default class extends React.Component {
  static displayName = 'CircleInner';

  static propTypes = {
    className: PropTypes.string,
    iconId: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isButtonChild: PropTypes.bool,
  };

  getContent = () => {
    const {text, iconId} = this.props;

    return iconId ? <InlineSVGIcon iconId={iconId} /> : text;
  };

  render() {
    const {className, isButtonChild} = this.props;
    const content = this.getContent();
    const baseClassName = isButtonChild ? 'btn-circle__inner' : 'circle__inner';

    return <i className={`${baseClassName} ${className || ''}`}>{content}</i>;
  }
}
