// https://codepen.io/larrybotha/pen/mmjKQz
import Two from '../two-no-conflict';

import getRandomGridPos from './utils';

import bombFactory from './bomb';
import hintFactory from './hint';
import playerFactory from './player';

const bombDodgeFactory = mountElem => {
  if (!mountElem || !('nodeType' in mountElem)) {
    throw new Error('no mount element provided');
  }

  const eventMap = {
    mousedown: handleCursorDown,
    touchstart: handleCursorDown,
    mousemove: handleCursorMove,
    touchmove: handleCursorMove,
    mouseup: handleCursorUp,
    touchend: handleCursorUp,
  };
  let options = {
    type: 'svg',
    height: 360,
    width: 360,
    svgNodes: {},
    // hintHintCollision :: (object, string, string) -> bool
    handleHintCollision: null,
    // hintBombCollision :: Two[Group|Path] -> bool
    handleBombCollision: null,
    sameHintCollisionTimeout: 1000,
    debug: {
      all: false,
      bombs: false,
      grid: false,
      hints: false,
      player: false,
    },
  };
  const gameItems = {};
  const grid = {};
  const lastHintCollision = {
    hintId: null,
    timestamp: performance.now(),
  };
  let isDraggingPlayer = false;
  let currCursorPos;
  let canvasPos;
  let isPaused;
  let two;

  function init(opts = {}) {
    options = Object.assign({}, options, opts);
    mountElem.style.position = 'relative';
    mountElem.style.overflow = 'hidden';

    two = new Two({
      height: options.height,
      type: Two.Types[options.type],
      width: options.width,
    })
      .bind('resize', handleResize)
      .appendTo(mountElem)
      .play();

    setViewBox(options.width, options.height);

    initializeEvents();

    two.renderer.domElement.setAttribute(
      'style',
      `
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      `,
    );
  }

  function initializeEvents() {
    update();

    Object.keys(eventMap).map(type => mountElem.addEventListener(type, eventMap[type]));
  }

  function update() {
    two.update();
  }

  function drawPlayer() {
    destroyPlayer();

    if (options.svgNodes.player) {
      const player = playerFactory({
        debug: options.debug.all || options.debug.player,
        parentElem: mountElem,
        svgNode: options.svgNodes.player,
      });
      player.setCenter({
        x: Math.round(two.width / 2),
        y: Math.round(two.height / 2),
      });

      gameItems.player = player;
    }
  }

  function drawBombs(numBombs = 0) {
    destroyBombs();

    if (!options.svgNodes.bomb) return;

    const bombs = Array.apply(null, Array(numBombs)).map(() => {
      const bomb = bombFactory({
        debug: options.debug.all || options.debug.bombs,
        parentElem: mountElem,
        svgNode: options.svgNodes.bomb,
      });

      bomb.setCenter(new Two.Vector(Math.round(two.width / 2), Math.round(two.height / 2)));

      return bomb;
    });

    gameItems.bombs = bombs;

    if (!isPaused) {
      bindAnimations(true);
    }
  }

  function bindAnimations(shouldBind) {
    const bindFn = shouldBind ? 'bind' : 'unbind';
    const boundFns = two._events.update
      ? two._events.update.map((_, i) => two._events.update[i].name)
      : [];

    [detectBombCollision, animateBombs].map(fn => {
      const isBound = boundFns.indexOf(fn.name) > -1;
      const shouldApplyFn = (shouldBind && !isBound) || (!shouldBind && isBound);

      return shouldApplyFn ? two[bindFn]('update', fn) : () => {};
    });
  }

  function drawHints(labelsArr = []) {
    destroyHints();

    if (!options.svgNodes.hint) return;

    const {width, height} = two;
    const gridFactor = Math.max(Math.round(labelsArr.length / 2 + 2), 10);
    grid.dims = {
      height: height / gridFactor,
      width: width / gridFactor,
    };
    grid.rows = Array(gridFactor)
      .fill()
      .map(() => Array(gridFactor).fill(false));

    if (options.debug.all || options.debug.grid) {
      gameItems.debugGrid = grid.rows.map((row, i) =>
        row.map((_, j) => {
          const gridItem = two.makeRectangle(
            grid.dims.width * j + grid.dims.width / 2,
            grid.dims.height * i + grid.dims.height / 2,
            grid.dims.width,
            grid.dims.height,
          );
          gridItem.noFill();

          return gridItem;
        }),
      );
    }

    const hints = labelsArr.map(label =>
      hintFactory({
        debug: options.debug.all || options.debug.hints,
        label,
        numGridDivisions: gridFactor,
        svgNode: options.svgNodes.hint,
        twoInstance: two,
      }),
    );

    hints.map(hint => {
      const pos = getRandomGridPos(grid.rows);

      grid.rows = grid.rows.map((row, i) => {
        const isCurrentRow = i === pos.y;

        return isCurrentRow ? row.map((cell, j) => j === pos.x || cell) : row;
      });

      hint.setTranslation({
        x: grid.dims.width * pos.x - width / 2 + grid.dims.width / 2,
        y: grid.dims.height * pos.y - height / 2 + grid.dims.height / 2,
      });

      return hint;
    });

    gameItems.hints = hints;
  }

  function setViewBox(width, height) {
    const {domElement} = two.renderer;

    domElement.setAttribute('viewBox', `0 0 ${width} ${height}`);

    update();

    canvasPos = domElement.getBoundingClientRect();
  }

  function updateDims({height, width}) {
    two.width = +width;
    two.height = +height;
    two.trigger('resize');
  }

  function getEventPos(e) {
    const touchObject = [e.targetTouches, e.changedTouches].find(o => !!o && o.length);
    const event = touchObject ? touchObject[0] : e;

    return {
      x: event.clientX,
      y: event.clientY,
    };
  }

  function handleCursorDown(e) {
    const {target} = e;
    const elem = gameItems.player ? gameItems.player.elem : null;
    e.preventDefault();

    if (elem && (target === elem || elem.contains(target))) {
      isDraggingPlayer = true;
    }
  }

  function handleCursorMove(e) {
    if (!gameItems.player || !isDraggingPlayer || isPaused) return;

    e.preventDefault();
    currCursorPos = getEventPos(e);
    const {x: curNowX, y: curNowY} = currCursorPos;
    const {player} = gameItems;
    const {left, top} = canvasPos;
    const {scrollX, scrollY} = window;
    const playerCenter = {
      x: curNowX - left + scrollX,
      y: curNowY - top + scrollY,
    };

    player.setCenter(playerCenter);
    detectHintCollisions();
  }

  function handleCursorUp(e) {
    if (isDraggingPlayer) {
      isDraggingPlayer = false;
    }
  }

  function handleResize() {
    const {hints, bombs, player} = gameItems;

    setViewBox(two.width, two.height);

    if (hints) {
      drawHints(hints.map(hint => hint.getLabel()));
    }

    if (bombs) {
      drawBombs(gameItems.bombs.length);
    }

    if (player) {
      destroyPlayer();
    }
  }

  function setPause(pause) {
    isPaused = pause;

    bindAnimations(!pause);
  }

  function animateBombs() {
    const {bombs} = gameItems;

    if (bombs) {
      bombs.map(bomb => bomb.animate());
    }
  }

  function hasGameItem(name) {
    return !!gameItems[name];
  }

  function destroyBombs() {
    if (gameItems.bombs) {
      gameItems.bombs.map(bomb => bomb.destroy());
      delete gameItems.bombs;
    }
  }

  function detectHintCollisions() {
    const {hints, player} = gameItems;
    const playerCenter = player.getCenter();

    gameItems.hints = hints
      .filter(hint => hint.getIsActive())
      .map(hint => {
        const isCollision = hint.isCollision(playerCenter);
        const id = hint.getProp('id');
        const shouldHandleCollision =
          isCollision &&
          !(
            lastHintCollision.hintId === id &&
            performance.now() - lastHintCollision.timestamp < options.sameHintCollisionTimeout
          );

        if (shouldHandleCollision) {
          if (typeof options.handleHintCollision === 'function') {
            options.handleHintCollision(playerCenter, hint.getLabel(), hint.getProp('id'));
          }

          lastHintCollision.hintId = id;
          lastHintCollision.timestamp = performance.now();
        }

        return hint;
      });
  }

  function detectBombCollision() {
    const {bombs, player} = gameItems;

    if (!player || !bombs) return;

    const playerCenter = player.getCenter();

    gameItems.bombs = bombs
      .map(bomb => {
        const isCollision = bomb.isCollision(playerCenter);

        if (isCollision) {
          if (typeof options.handleBombCollision === 'function') {
            options.handleBombCollision(playerCenter);
          }

          bomb.destroy();
        }

        return isCollision ? false : bomb;
      })
      .filter(bomb => !!bomb);
  }

  function setHintToRemove(id) {
    gameItems.hints.map(hint => {
      const shouldDestroy = hint.getProp('id') === id;
      const destroyFn = shouldDestroy ? hint.destroy : () => {};

      destroyFn();

      return shouldDestroy ? false : hint;
    });
  }

  function animatePlayer(isHurt = false) {
    const {player} = gameItems;

    if (player) {
      player.animatePlayerFace(isHurt);
    }
  }

  function destroyPlayer() {
    if (gameItems.player) {
      gameItems.player.destroy();
      delete gameItems.player;
    }
  }

  function destroyHints() {
    if (gameItems.hints) {
      gameItems.hints.map(hint => hint.destroy());
      delete gameItems.hints;
    }

    if (gameItems.debugGrid) {
      gameItems.debugGrid.map(gridItem => two.remove(gridItem));
      delete gameItems.debugGrid;
    }
  }

  function destroyEvents() {
    Object.keys(eventMap).map(type => mountElem.removeEventListener(type, eventMap[type]));

    Object.keys(two._events).map(key => two._events[key].map(boundFn => two.unbind(key, boundFn)));
  }

  function destroy() {
    destroyBombs();
    destroyPlayer();
    destroyHints();
    two.clear();
    destroyEvents();
  }

  return {
    animatePlayer,
    destroy,
    destroyPlayer,
    drawBombs,
    drawHints,
    drawPlayer,
    hasGameItem,
    init,
    setHintToRemove,
    setPause,
    updateDims,
  };
};

export default bombDodgeFactory;
