import PropTypes from 'prop-types';
import React from 'react';

import Button from '../components/Button';
import CircleInner from '../components/CircleInner';

const ButtonCircle = ({
  className,
  classNameInner,
  disabled,
  modifiers,
  onClick,
  text,
  iconId,
  title,
}) => (
  <Button
    baseClassName="btn-circle"
    className={className}
    disabled={disabled}
    modifiers={modifiers}
    onClick={onClick}
    title={title}
  >
    <CircleInner className={classNameInner} text={text} iconId={iconId} isButtonChild />
  </Button>
);

ButtonCircle.displayName = 'ButtonCircle';

ButtonCircle.propTypes = {
  className: PropTypes.string,
  classNameInner: PropTypes.string,
  disabled: PropTypes.bool,
  iconId: PropTypes.string,
  title: PropTypes.string,
  modifiers: PropTypes.array,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ButtonCircle;
