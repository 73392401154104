import PropTypes from 'prop-types';
import React from 'react';
import Swiper from 'swiper';

import ButtonCircle from '../containers/ButtonCircle';

export default class extends React.Component {
  static displayName = 'Carousel';

  static propTypes = {
    activeIndex: PropTypes.number,
    options: PropTypes.object,
    slides: PropTypes.array.isRequired,
    useNextPrev: PropTypes.bool,
    onNextClick: PropTypes.func,
    onPrevClick: PropTypes.func,
    onInitialize: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    options: {},
    slides: [],
  };

  componentDidMount() {
    const {activeIndex, onInitialize, options, useNextPrev} = this.props;
    const baseOpts = {
      autoHeight: true,
      loop: true,
      nextButton: useNextPrev ? '.js-swiper-btn-next' : null,
      prevButton: useNextPrev ? '.js-swiper-btn-prev' : null,
      spaceBetween: 16,
    };
    const opts = {...baseOpts, ...options};

    this.instance = Swiper(this.containerRef, opts);

    if (onInitialize) onInitialize(this.instance);

    if (typeof activeIndex === 'number') this.setActiveIndex(activeIndex);
  }

  componentWillUnmount() {
    this.instance.destroy();
  }

  componentDidUpdate(prevProps) {
    const {activeIndex} = this.props;

    if (activeIndex !== prevProps.activeIndex) {
      this.setActiveIndex(activeIndex);
    }
  }

  setActiveIndex = activeIndex => {
    this.instance.slideTo(activeIndex);
  };

  getSlidesMarkup = () => {
    const {slides} = this.props;

    return (
      <div className="swiper-wrapper">
        {slides.map((slide, i) => (
          <div className="swiper-slide" key={i}>
            {slide}
          </div>
        ))}
      </div>
    );
  };

  getNavButtonMarkup = (pos, onClick) => {
    const {useNextPrev, btnClassNames} = this.props;
    const defaultBtnClassName = 'btn-circle--ntrl-min--semi-trans btn-circle--large';
    const direction = pos === 'prev' ? 'left' : 'right';
    const fn = typeof onClick === 'function' ? onClick : () => {};

    return useNextPrev ? (
      <ButtonCircle
        className={`
          js-swiper-btn-${pos}
          swiper-btn-${pos}
          ${btnClassNames ? btnClassNames[direction] : defaultBtnClassName}
        `}
        iconId={`c-icon-chevron-${direction}`}
        onClick={() => fn(this.instance)}
      />
    ) : null;
  };

  render() {
    const {options, useNextPrev, onNextClick, onPrevClick} = this.props;
    const nextBtnMarkup = this.getNavButtonMarkup('next', onNextClick);
    const prevBtnMarkup = this.getNavButtonMarkup('prev', onPrevClick);
    const slidesMarkup = this.getSlidesMarkup();

    return (
      <div
        className={`swiper-container
          ${useNextPrev ? 'has-next-prev' : ''}
          ${this.props.className}
        `}
        ref={c => (this.containerRef = c)}
      >
        {slidesMarkup}

        {options && options.pagination ? (
          <div
            className={`
              ${options.pagination.replace('.', '')}
              ${options.paginationClassName || ''}
            `}
          />
        ) : null}

        {prevBtnMarkup}
        {nextBtnMarkup}
      </div>
    );
  }
}
