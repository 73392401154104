export default {
  key: 'wheel',
  name: 'Wheel',
  description: '',
  resources: {
    thumbnailUrl: '',
  },
  vars: {
    text: {type: 'text', label: 'Text', optional: true},

    choices: {
      type: 'multiple-choice',
      label: 'Choices',
    },
  },
};
