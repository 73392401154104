export default {
  key: 'bomb-dodge',
  name: 'Bomb Dodge',
  description: '',

  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'bomb-dodge',
  },

  vars: {
    text: {
      type: 'text',
      label: 'Text',
    },

    hints: {
      type: 'string',
      label: 'Hints',
      help: `
        Use {braces} to delimit hints. e.g. {r}{a}{b}{b}{i}{t} or {3}{6}{9}{12}.
        Any characters not inside braces will be ignored.
      `,
    },

    fakeHints: {
      type: 'string',
      label: 'Fake Hints',
      optional: true,
      help: `
        Use {braces} to delimit fake hints. e.g. {r}{a}{b}{b}{i}{t} or {3}{6}{9}{12}.
      `,
    },

    delimiter: {
      type: 'string',
      label: 'Delimiter',
      optional: true,
      help: `
        The characters that will split user character selections. Leave blank for
        characters that should sit against each other in the user's answer.
        e.g. a comma space (, ) with a list of numbers will yield 1, 2, 3, 4, etc.
      `,
    },

    numBombs: {
      type: 'slider',
      label: 'Number of bombs',
      name: 'num-bombs',
      min: 1,
      max: 10,
      step: 1,
      defaultValue: 3,
    },

    numLives: {
      type: 'slider',
      label: 'Number of lives',
      name: 'num-lives',
      min: 1,
      max: 10,
      step: 1,
      defaultValue: 3,
      help: `
        The number of times a user can make an error / get hit by a bomb before losing
        a life for the overall challenge.
      `,
    },

    orderMatters: {
      type: 'boolean',
      label: 'Collect items in order',
      defaultValue: true,
      optional: true,
      help:
        'Make sure to supply characters in the order you want them collected',
    },
  },
};
