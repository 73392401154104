import PropTypes from 'prop-types';
import React from 'react';
import {SubmissionError} from 'redux-form';
import {Link} from 'react-router';

import {UserManagementContainer} from '@edu-game/assets';

import ResendConfirmationForm from '../forms/ResendConfirmation';
import LinksFooter from '../LinksFooter';
import PageTitle from '../PageTitle';

import createApp from '../../feathers';
import routes from '../../routes';

const app = createApp();

class ResendConfirmationPage extends React.Component {
  static displayName = 'ResendConfirmationPage';

  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = {};

  handleSubmit = values => {
    return app
      .service('/account/confirmations')
      .create({email: values.email, resend: 'true'})
      .then(() => {
        this.setState({success: true});
      })
      .catch(err => {
        throw new SubmissionError({_error: err});
      });
  };

  render() {
    const {history} = this.props;
    const {success} = this.state;

    return (
      <UserManagementContainer showBack history={history}>
        <PageTitle>Resend account confirmation</PageTitle>

        {success ? (
          <div className="alert--success">
            Confirmation resent! &nbsp;
            <Link className="fc--ntrl-min" to={routes.login}>
              Log in
            </Link>
          </div>
        ) : (
          <div>
            <div className="push--smaller--bottom">
              <ResendConfirmationForm onSubmit={this.handleSubmit} />
            </div>

            <LinksFooter />
          </div>
        )}
      </UserManagementContainer>
    );
  }
}

export default ResendConfirmationPage;
