import React from 'react';

import descriptions from './shared/descriptions';

export default {
  key: 'multiple-choice-list',
  name: 'Multiple Choice List',
  description: <div>{descriptions.answerBoundAnimations}</div>,
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'multiple-choice',
  },

  vars: {
    text: {type: 'text', label: 'Text'},
    choices: {
      type: 'multiple-choice',
      label: 'Choices',
      max: 3,
    },
  },
};
