import PropTypes from 'prop-types';
import React from 'react';
import {Field, reduxForm as connectForm} from 'redux-form';
import {combineValidators} from 'redux-form-revalid';

import {Button, InputField, InputFieldMessage, Loader} from '@edu-game/assets';
import {validatePassword} from '@edu-game/assets/src/js/validations';

function matches(field) {
  return (value, values) => {
    if (values[field] !== value) {
      return {message: 'Passwords do not match.'};
    }

    return false;
  };
}

class ResetPasswordForm extends React.Component {
  static displayName = 'ResetPasswordForm';

  static propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
  };

  render() {
    const {handleSubmit, submitting, error} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="password"
          msgClassName="fc--red-alpha-ltst"
          component={InputField}
          type="password"
          label="New Password"
          className="field--trans"
          withIcon
        />

        <Field
          name="confirmPassword"
          msgClassName="fc--red-alpha-ltst"
          component={InputField}
          type="password"
          label="Confirm Password"
          className="field--trans"
          withIcon
        />

        {error ? (
          <InputFieldMessage clasName="fc--red-alpha-ltst" tagName="p">
            {error.message}
          </InputFieldMessage>
        ) : null}

        <Button type="submit" modifiers={['purple-alpha', 'full']} disabled={submitting}>
          Reset Password &nbsp;
          {submitting ? <Loader className="loader--spin" numIcons={1} /> : null}
        </Button>
      </form>
    );
  }
}

export {ResetPasswordForm};

export default connectForm({
  form: 'ForgottenPassword',
  validate: combineValidators({
    confirmPassword: matches('password'),
    password: validatePassword,
  }),
})(ResetPasswordForm);
