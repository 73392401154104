export default {
  key: 'reflection',
  name: 'Reflection',
  description: '',

  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'reflection',
  },

  vars: {
    data: {
      name: 'reflection-data',
      type: 'reflection-canvas',
      label: 'Reflection Data',
      help: 'Click the points users will provide the reflections for',
    },
  },
};
