import PropTypes from 'prop-types';
import React from 'react';

import Logo from './Logo';
import ButtonCircle from './ButtonCircle';

const UserManagementContainer = ({children, className, history, showBack}) => (
  <div className={`user-management ${className || ''}`}>
    {showBack && history ? (
      <div className="user-management__back">
        <ButtonCircle
          modifiers={['purple-alpha', 'larger']}
          onClick={history.goBack}
          iconId="c-icon-arrow-left"
        />
      </div>
    ) : null}

    <div className="user-management__content">
      <Logo className="user-management__logo" />

      {children}
    </div>
  </div>
);

UserManagementContainer.displayName = 'UserManagementContainer';
UserManagementContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  history: PropTypes.object,
  showBack: PropTypes.bool,
};

export default UserManagementContainer;
