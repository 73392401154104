const {localStorage, sessionStorage} = global;

const localOrSessionStore = ({key}) => {
  let shouldPersist = localStorage.getItem(key) === 't';

  return {
    setShouldPersist(persist) {
      shouldPersist = !!persist;
      if (shouldPersist) {
        localStorage.setItem(key, 't');
      } else {
        localStorage.removeItem(key);
      }
    },

    isPersist() {
      return shouldPersist;
    },

    getItem(name) {
      if (shouldPersist) {
        return localStorage.getItem(name);
      }

      return sessionStorage.getItem(name);
    },

    setItem(name, value) {
      if (shouldPersist) {
        localStorage.setItem(name, value);
        sessionStorage.removeItem(name);
      } else {
        sessionStorage.setItem(name, value);
        localStorage.removeItem(name);
      }
    },

    removeItem(name) {
      if (shouldPersist) {
        return localStorage.removeItem(name);
      }

      return sessionStorage.removeItem(name);
    },
  };
};

// prevent tests from failing
const fn = typeof localStorage !== 'undefined' ? localOrSessionStore : () => {};

export default fn;
