/* eslint-disable no-underscore-dangle */
import Two from '../two-no-conflict';

const playStates = {
  IDLE: 'idle',
  PLAYING: 'playing',
};

const waveCanvasFactory = mountElem => {
  if (!mountElem || !('nodeType' in mountElem)) {
    throw new Error('no mount element provided');
  }

  const paths = {};
  const waveData = [];
  let options = {
    width: 360,
    height: 360,
    type: 'svg',
    // onAnimEnd :: twoInstance -> null
    onAnimEnd: null,
    // onProgress :: Number -> null
    onProgress: null,
    gradients: [
      {
        from: '#f6bfbb',
        to: '#f08cba',
      },
      {
        from: '#f08cba',
        to: '#ff6a76',
      },
      {
        from: '#fbe0a3',
        to: '#f9eb6f',
      },
    ],
    waveVelocityRange: [1, 3],
  };
  let currProgress = 0;
  let duration = 10 * 1000;
  let playState = playStates.IDLE;
  let timeElapsed = 0;
  let timeAtPause;
  let timeAtLastTick;
  let two;

  function init(opts) {
    options = Object.assign({}, options, opts);
    two = new Two({
      type: Two.Types[options.type],
      width: options.width,
      height: options.height,
    })
      .bind('resize', handleResize)
      .bind('update', animateWavesHorizontally)
      .play();

    two.appendTo(mountElem);
    setViewBox(options.width, options.height);
    initGroup();

    two.renderer.domElement.setAttribute(
      'style',
      `
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      `,
    );
  }

  function initGroup() {
    paths.group = two.makeGroup();
    paths.group.translation.set(paths.group.translation.x, two.height);
  }

  function addWave(_, waveIndex) {
    const {width, height} = two;
    const waveWidth = Math.ceil(width);
    const numPeaks = 6;
    const bottomAnchors = [
      new Two.Anchor(waveWidth, height, 0, 0, 0, 0, Two.Commands.line),
      new Two.Anchor(0, height, 0, 0, 0, 0, Two.Commands.line),
    ];
    const waveAnchors = Array.apply(null, Array(numPeaks)).map((_a, i) => {
      const scalar = i % 2 === 0 ? -1 : 1;
      const y =
        i === 0 || i === numPeaks - 1
          ? 0
          : (scalar * height * Math.floor(Math.random() * (5 - 1 + 1) + 1)) / 1000;
      const command = i === 0 ? 'move' : 'curve';
      const anchorPos = (waveWidth / numPeaks) * 0.45;

      return new Two.Anchor(
        (waveWidth / (numPeaks - 1)) * i,
        y * (1 + waveIndex),
        -anchorPos,
        0,
        anchorPos,
        0,
        Two.Commands[command],
      );
    });
    const vectors = waveAnchors.concat(bottomAnchors);
    const firstWave = new Two.Path(vectors, true, false, true);
    const secondWave = new Two.Path(vectors, true, false, true);
    const waveGroupHori = two.makeGroup();
    const waveGroupVert = two.makeGroup();

    waveGroupVert.add(firstWave, secondWave);
    waveGroupHori.add(waveGroupVert);
    paths.group.add(waveGroupHori);
    two.update();

    const waveYOffset = firstWave._renderer.elem.getBBox().y;
    const linearGradient = getWaveGradient(waveIndex);
    const velMax = Math.max.apply(null, options.waveVelocityRange);
    const velMin = Math.min.apply(null, options.waveVelocityRange);

    waveData[waveIndex] = {
      vel: new Two.Vector(Math.random() * (velMax - velMin + 1) + velMin, 0),
      width: waveWidth,
    };

    [firstWave, secondWave].map((wave, i) => {
      wave.noStroke();
      wave.translation.set(-waveWidth * i, -waveYOffset);
      wave.opacity = 0.7;
      wave.fill = linearGradient;

      return wave;
    });
  }

  function getWaveGradient(waveIndex) {
    const {gradients} = options;
    const gradient = gradients[waveIndex % gradients.length];

    return two.makeLinearGradient(
      two.width / 2,
      0,
      two.width / 2,
      two.height / 7,
      new Two.Stop(0, gradient.from),
      new Two.Stop(1, gradient.to),
    );
  }

  function drawWaves(numWaves) {
    if (paths.group) {
      paths.group.remove(paths.group.children);
    }

    Array.apply(null, Array(numWaves)).map(addWave);
    two.update();
  }

  function animateWavesVertically() {
    const timeDelta = performance.now() - timeAtLastTick;

    if (timeAtPause) {
      timeAtPause = undefined;
    }

    if (timeAtLastTick) {
      setTimeElapsed(timeElapsed + timeDelta);
    }

    timeAtLastTick = performance.now();

    if (timeElapsed + timeDelta > duration) {
      stop();
      setTimeElapsed(duration);

      if (typeof options.onAnimEnd === 'function') {
        options.onAnimEnd(two);
      }
    }
  }

  function animateWavesHorizontally() {
    const {group} = paths;

    if (group && group.children) {
      group.children.map((ch, i) => {
        if (!waveData[i]) return ch;

        const {vel, width} = waveData[i];
        const shouldReset = ch.translation.x >= width;

        if (vel && !shouldReset) {
          ch.translation.addSelf(vel);
        } else {
          ch.translation.subSelf({x: width, y: 0});
        }

        return ch;
      });
    }
  }

  function isPlaying() {
    return playState === playStates.PLAYING;
  }

  function setDuration(dur) {
    duration = dur;
  }

  function setTimeElapsed(elapsed) {
    timeElapsed = elapsed;

    setProgress(elapsed / duration);
  }

  function setProgress(progress) {
    const {group} = paths;
    const {height} = two;
    const shouldUpdateTranslation = progress <= 1 && progress >= 0;

    currProgress = shouldUpdateTranslation ? progress : currProgress;

    if (group && shouldUpdateTranslation) {
      group.translation.set(group.translation.x, height - height * currProgress);

      if (typeof options.onProgress === 'function') {
        options.onProgress(currProgress);
      }
    }
  }

  function play() {
    timeAtLastTick = performance.now();
    playState = playStates.PLAYING;
    bindVerticalWaveAnimation();
  }

  function pause() {
    timeAtPause = performance.now();
    playState = playStates.IDLE;
    unbindVerticalWaveAnimation();
  }

  function stop() {
    unbindVerticalWaveAnimation();
    playState = playStates.IDLE;
    setTimeElapsed(0);
    timeAtPause = undefined;
    timeAtLastTick = undefined;
  }

  function reset() {
    stop();
    destroyPaths();
    initGroup();
  }

  function bindVerticalWaveAnimation() {
    const isBound = two._events.update && two._events.update.indexOf(animateWavesVertically) > -1;

    if (!isBound) {
      two.bind('update', animateWavesVertically);
    }
  }

  function unbindVerticalWaveAnimation() {
    const isBound = two._events.update && two._events.update.indexOf(animateWavesVertically) > -1;

    if (isBound) {
      two.unbind('update', animateWavesVertically);
    }
  }

  function setViewBox(width, height) {
    two.renderer.domElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
  }

  function handleResize() {
    setViewBox(two.width, two.height);
    drawWaves(paths.group.children.length);
    two.update();
  }

  function updateDims({height, width}) {
    two.width = parseInt(width, 10);
    two.height = parseInt(height, 10);
    two.trigger('resize');
  }

  function removeEvents() {
    Object.keys(two._events).map(event => two._events[event].map(fn => two.unbind(event, fn)));
  }

  function destroyPaths() {
    delete paths.group;
    two.clear();
    two.update();
  }

  function destroy() {
    destroyPaths();
    removeEvents();

    return true;
  }

  return {
    destroy,
    drawWaves,
    isPlaying,
    init,
    pause,
    play,
    reset,
    setDuration,
    setTimeElapsed,
    stop,
    updateDims,
  };
};

export default waveCanvasFactory;
