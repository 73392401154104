export default {
  key: 'fractions-multiple-choice',
  name: 'Fractions Multiple Choice',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'fractions-mc',
  },

  vars: {
    text: {
      type: 'text',
      label: 'Text',
      help: 'Fractions are automatically formatted if they are in the form x/y.',
    },

    shape: {
      type: 'select',
      label: 'Shape',
      options: [
        'triangle',
        'circle',
        'rectangle',
        'square',
        'pentagon',
        'hexagon',
        'octagon',
        'decagon',
        'dodecagon',
      ],
    },

    divisions: {
      type: 'number',
      label: 'Divisions',
      help: `
        Only circles, squares, and rectangles can accept any no. of sides.
        Triangles accept 2 or 3 sides, all other shapes accept only their
        intrinsic number of sides.
      `,
    },

    numSelected: {type: 'number', label: 'No. Divisions Shaded'},

    choices: {
      type: 'multiple-choice',
      label: 'Multiple Choice Options',
      max: 4,
    },
  },
};
