export default {
  key: 'multiple-choice-timed',
  name: 'Multiple Choice Timed',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'multiple-choice-timed',
  },

  vars: {
    text: {type: 'text', label: 'Text'},
    hints: {
      type: 'list',
      label: 'Hints',
      optional: true,
      help: `
        Add options for users to evaluate against the question.
      `,
    },
    choices: {
      type: 'multiple-choice',
      label: 'Choices',
    },
    timeLimit: {
      type: 'slider',
      label: 'Time Limit',
      help: 'Time limit for this question in seconds',
      min: 1,
      max: 60,
      step: 1,
      defaultValue: 30,
    },
  },
};
