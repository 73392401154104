import PropTypes from 'prop-types';
import React from 'react';

const Fraction = ({fractionText}) => {
  const [numerator, denominator] = fractionText.split('/');

  return (
    <span className="fraction-text">
      <span className="fraction-text__numerator">{numerator}</span>

      <span className="fraction-text__denominator">{denominator}</span>
    </span>
  );
};

Fraction.displayName = 'Fraction';

Fraction.propTypes = {
  fractionText: PropTypes.string.isRequired,
};

export {Fraction};
