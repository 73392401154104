import PropTypes from 'prop-types';
import React from 'react';

import InlineSVGIcon from './InlineSVGIcon';

const Loader = ({children, className, iconId, numIcons, tagName}) => {
  const CustomTag = tagName ? tagName : 'span';

  return (
    <CustomTag className={`loader ${className || 'loader--spin'}`}>
      {Array.apply(null, Array(numIcons || 3)).map((_, i) => (
        <InlineSVGIcon key={i} className="loader__icon icon" iconId={iconId || 'c-icon-star'} />
      ))}

      {children ? <CustomTag className="loader__text">{children}</CustomTag> : null}
    </CustomTag>
  );
};

Loader.displayName = 'Loader';
Loader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  iconId: PropTypes.string,
  numIcons: PropTypes.number,
  tagName: PropTypes.string,
};

export default Loader;
