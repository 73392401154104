export default {
  key: 'multiple-choice-letters',
  name: 'Multiple Choice Letters',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'multiple-choice-letters',
  },

  vars: {
    text: {type: 'text', label: 'Text'},
    choices: {
      type: 'multiple-choice',
      label: 'Choices',
      max: 4,
    },
  },
};
