// https://codepen.io/larrybotha/pen/vJEPjM?editors=0010
import Two from '../two-no-conflict';

const animationStates = {
  ANIMATING: 'animating',
  COMPLETE: 'complete',
  IDLE: 'idle',
};

const cakeFactory = mountElem => {
  if (!mountElem || !('nodeType' in mountElem)) {
    throw new Error('no mount element provided');
  }

  const paths = {};
  let options = {
    colors: ['#ff6a76', '#fd926f', '#f8c554', '#fbe0a3', '#cb9fc5', '#bd83b5'],
    friction: 0.0115,
    gravity: 0.5,
    height: 280,
    numCandles: 4,
    numLayers: 4,
    onCandleAnimEnd: null,
    onLayerAnimEnd: null,
    svgNodes: {icing: null, candle: null},
    type: 'svg',
    velocity: {x: 0, y: 3},
    width: 280,
  };
  let two;

  function init(opts) {
    options = {...options, ...opts};
    two = new Two({
      height: options.height,
      type: Two.Types[options.type],
      width: options.width,
    })
      .bind('resize', handleResize)
      .play();

    two.appendTo(mountElem);
    setViewBox(options.width, options.height);

    drawCake();

    two.renderer.domElement.setAttribute(
      'style',
      `
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      `,
    );
  }

  function getLayerWidth() {
    const {height, width} = two;
    const layerWidth = Math.min(width, height / 2);

    // always use an even value for layer width to prevent artifacts
    return (layerWidth / 2) % 2 === 0 ? layerWidth : layerWidth + 1;
  }

  function getLayerHeight() {
    const {height} = two;
    const {numLayers} = options;
    const layerHeight = Math.min(36, Math.round(height / 2 / numLayers));

    // always use an even value for layer height to prevent artifacts
    return (layerHeight / 2) % 2 === 0 ? layerHeight : layerHeight + 1;
  }

  function interpretCandleImage() {
    const {candle} = options.svgNodes;
    const layerHeight = getLayerHeight();

    if (candle && !paths.candleRef) {
      const candleRef = two.interpret(candle);
      candleRef.translation.set(candleRef.translation.x, -layerHeight * 3);
      candleRef.opacity = 0;

      paths.candleRef = candleRef;
    }
  }

  function prepareCandles() {
    const {height} = two;
    const {candleRef} = paths;

    if (candleRef) {
      const {numCandles, numLayers} = options;
      const layerWidth = getLayerWidth();
      const dims = candleRef.getBoundingClientRect(true);
      // 90% of total width
      const layoutWidth = layerWidth * 0.9;
      const candlesStart = (two.width - layoutWidth) / 2;
      const layerHeight = getLayerHeight();

      paths.candles = Array.apply(null, Array(numCandles)).map((_, i) => {
        const candle = candleRef.clone();
        candle.vel = new Two.Vector(options.velocity.x, options.velocity.y);
        candle.visible = false;
        candle.translation.set(
          candlesStart - dims.width / 2 + ((i + 1 / 2) * layoutWidth) / options.numCandles,
          candleRef.translation.y - i * layerHeight * 2,
        );
        candle.animationState = animationStates.IDLE;
        candle.opacity = 1;
        candle.yFinal = height - (numLayers + 1) * layerHeight - dims.height + 1;

        return candle;
      });

      two.add(...paths.candles);
    }
  }

  function interpretIcingImage() {
    const {icing} = options.svgNodes;

    if (icing && !paths.icing) {
      const icingGroup = two.interpret(icing);
      icingGroup.visibility = false;
      icingGroup.origDims = icingGroup.getBoundingClientRect();

      paths.icing = icingGroup;
    }
  }

  function prepareIcing() {
    const {height, width} = two;
    const {icing} = paths;
    const layerHeight = getLayerHeight();
    const layerWidth = getLayerWidth();

    if (icing) {
      const dims = icing.origDims;
      const icingWidth = dims.width % 2 === 0 ? dims.width : dims.width - 1;
      // we need to add 2 to layerWidth and subtract 1 from translation.x to remove artifacts
      const scale = (layerWidth + 2) / icingWidth;
      icing.translation.set((width - layerWidth) / 2 - 1, -layerHeight);
      icing.vel = new Two.Vector(options.velocity.x, options.velocity.y);
      icing.scale = scale;
      icing.yFinal = height - (options.numLayers + 1) * layerHeight;

      // store initial vertices data
      icing.origPathsData = icing.children
        .filter(ch => ch._renderer.type === 'path')
        .map(ch => ({
          pathId: ch.id,
          vertices: ch.vertices.map(({x, y}) => new Two.Vector(x, y)),
          verticesAnimated: ch.vertices.map(() => false),
        }));

      // move vertices to starting position and add animation data
      icing.children
        .filter(ch => ch._renderer.type === 'path')
        .map(path => {
          path.vertices.map(v => {
            const distanceToZero = Math.abs(v.distanceTo({x: v.x, y: -path.position.y}));
            const addSubFn = distanceToZero > 0 ? 'subSelf' : 'addSelf';

            v.vel = new Two.Vector(0, distanceToZero / dims.height);
            v[addSubFn]({x: 0, y: distanceToZero});

            return v;
          });

          return path;
        });

      paths.icing = icing;
    }
  }

  function drawLayers() {
    destroyLayers();
    const {height, width} = two;
    const {numLayers} = options;
    const layerHeight = getLayerHeight();
    const layerWidth = getLayerWidth();

    const layerGroups = Array.apply(null, Array(numLayers)).map((_, i) => {
      const color = options.colors[i % options.colors.length];
      const group = two.makeGroup();
      const layer = two.makePath(0, 0, layerWidth, 0, layerWidth, layerHeight, 0, layerHeight);
      layer.noStroke();
      layer.fill = color;

      const gradient = two.makeLinearGradient(
        layerWidth / 2,
        -layerHeight / 2,
        layerWidth / 2,
        layerHeight / 2,
        new Two.Stop(0, '#fff', 0.2),
        new Two.Stop(0.5, '#fff', 0),
      );
      const overlay = layer.clone();
      overlay.fill = gradient;

      group.add(layer, overlay);
      group.center();
      group.translation.set(width / 2, -layerHeight / 2);
      group.visible = false;
      group.vel = new Two.Vector(options.velocity.x, options.velocity.y);
      group.yFinal = height - (layerHeight + layerHeight * i);
      group.animationState = animationStates.IDLE;

      return group;
    });

    paths.layerGroups = layerGroups;
  }

  function drawCake() {
    drawLayers();
    interpretCandleImage();
    prepareCandles();
    interpretIcingImage();
    prepareIcing();
  }

  function showNextLayer() {
    const {layerGroups} = paths;
    const {numLayers} = options;
    const nextLayerIndex = layerGroups
      .map(({animationState}) => animationState)
      .indexOf(animationStates.IDLE);

    const nextLayer = layerGroups[nextLayerIndex];

    if (nextLayer) {
      nextLayer.visible = true;
      nextLayer.animationState = animationStates.ANIMATING;

      const boundFns = two._events.update ? two._events.update.map(fn => fn.name) : [];

      if (boundFns.indexOf('animateNextLayer') === -1) {
        two.bind('update', animateNextLayer);
      }
    }

    if (nextLayerIndex === numLayers - 1) {
      two.bind('update', animateIcing);

      setTimeout(() => {
        two.bind('update', animateCandles);
      }, 250);
    }
  }

  function animateNextLayer() {
    const {layerGroups} = paths;
    const animationComplete = layerGroups.every(
      ({animationState}) => animationState === animationStates.COMPLETE,
    );

    if (animationComplete) {
      two.unbind('update', animateNextLayer);
    }

    layerGroups
      .filter(({animationState}) => animationState === animationStates.ANIMATING)
      .map((group, i) => {
        const {yFinal} = group;
        const {y: yCurr} = group.translation;

        if (yFinal > yCurr) {
          group.translation.addSelf(group.vel);
          group.vel.y = group.vel.y + options.gravity;
        } else {
          group.translation.set(group.translation.x, yFinal);
          group.animationState = animationStates.COMPLETE;

          if (options.onLayerAnimEnd) {
            options.onLayerAnimEnd(i);
          }
        }

        return group;
      });
  }

  function animateCandles() {
    const {candles} = paths;

    if (!candles) return;

    const animationComplete = candles.every(
      ({animationState}) => animationState === animationStates.COMPLETE,
    );

    if (animationComplete) {
      two.unbind('update', animateCandles);
    }

    candles.map(animateCandle);
  }

  function animateCandle(candle, index) {
    const {y} = candle.translation;
    const {animationState} = candle;

    if (animationState === animationStates.IDLE) {
      candle.visible = true;
      candle.animationState = animationStates.ANIMATING;
    }

    if (candle.yFinal > y) {
      candle.translation.addSelf(candle.vel);
      candle.vel.y = candle.vel.y + options.gravity;
    } else {
      if (candle.animationState === animationStates.ANIMATING && options.onCandleAnimEnd) {
        options.onCandleAnimEnd(index);
      }

      candle.translation.set(candle.translation.x, candle.yFinal);
      candle.animationState = animationStates.COMPLETE;
    }
  }

  function animateIcing() {
    if (!paths.icing) {
      return;
    }

    const {icing} = paths;
    const {origPathsData} = icing;
    const {y} = icing.translation;
    const animationComplete = origPathsData.every(oData => oData.verticesAnimated.every(Boolean));

    if (animationComplete) {
      two.unbind('update', animateIcing);
    }

    if (!icing.visible) {
      icing.visible = true;
    }

    if (icing.yFinal > y) {
      icing.translation.addSelf(icing.vel);
      icing.vel.y = icing.vel.y + options.gravity;
    } else {
      icing.translation.set(icing.translation.x, icing.yFinal);
    }

    origPathsData.map(oData => {
      const path = icing.getById(oData.pathId);
      const origVerts = oData.vertices;
      const {verticesAnimated} = oData;

      path.vertices.map((v, i) => {
        const origVert = origVerts[i];
        const distance = v.distanceTo(origVert);

        if (Math.abs(distance) > 0.1) {
          const addSubFn = distance < 0 ? 'subSelf' : 'addSelf';
          v[addSubFn](v.vel);
          v.vel.y = v.vel.y - v.vel.y * options.friction;
        } else {
          v.set(origVert.x, origVert.y);
          verticesAnimated[i] = true;
        }

        return v;
      });

      return oData;
    });
  }

  function setViewBox(width, height) {
    two.renderer.domElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
  }

  function handleResize() {
    setViewBox(two.width, two.height);
    reset();
  }

  function reset() {
    destroyPaths();
    unbindUpdateListeners();
    drawCake();
  }

  function setOptions(newOpts = {}) {
    options = {...options, ...newOpts};
    reset();
  }

  function updateDims({height, width}) {
    two.width = parseInt(width, 10);
    two.height = parseInt(height, 10);
    two.trigger('resize');
  }

  function unbindUpdateListeners() {
    if (two._events.update) {
      two._events.update.map(fn => two.unbind('update', fn));
    }
  }

  function unbindEvents() {
    Object.keys(two._events).map(key => two._events[key].map(fn => two.unbind(key, fn)));
  }

  function destroyLayers() {
    if (paths.layerGroups) {
      two.remove(paths.layerGroups);
      delete paths.layerGroups;
    }
  }

  function destroyPaths() {
    two.clear();
    Object.keys(paths).map(key => delete paths[key]);
  }

  function destroy() {
    destroyPaths();
    unbindEvents();

    return true;
  }

  return {
    destroy,
    init,
    reset,
    setOptions,
    showNextLayer,
    updateDims,
  };
};

export default cakeFactory;
