export default {
  key: 'cartesian-plane',

  name: 'Cartesian Plane',

  description: '',

  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'cartesian-plane',
  },

  vars: {
    text: {type: 'text', label: 'Text'},
    plane: {type: 'cartesian-plane', label: 'Cartesian Plane'},
    x: {type: 'number', label: 'x', help: 'x value of the correct answer'},
    y: {type: 'number', label: 'y', help: 'y value of the correct answer'},
  },
};
