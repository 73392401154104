import PropTypes from 'prop-types';
import React from 'react';

const InputFieldMessage = ({className, children, tagName}) => {
  const CustomTagName = tagName || 'div';

  return (
    <CustomTagName className={`field__msg ${className || 'field__msg--error'}`}>
      {children}
    </CustomTagName>
  );
};

InputFieldMessage.displayName = 'InputFieldMessage';
InputFieldMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  tagName: PropTypes.string,
};

export default InputFieldMessage;
