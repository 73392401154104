import React from 'react';
import {Link} from 'react-router';

import routes from '../routes';

// const DefaultFooterContent = () => (
//   <div>
//     <Link className="fc--ntrl-min" to={routes.login}>
//       Parent login
//     </Link>

//     <span> | </span>

//     <Link className="fc--ntrl-min" to={routes.playLogin}>
//       Student login
//     </Link>
//   </div>
// );

const DefaultFooterContent = () => (
  <Link className="fc--ntrl-min" to={routes.login}>
    Back to Login page
  </Link>
);

const LinksFooter = ({children}) => {
  return (
    <div className="fs--milli text--center">{children ? children : <DefaultFooterContent />}</div>
  );
};

export default LinksFooter;
