let Chartist = {};
let ctAxisTitle = {};

if (typeof window !== 'undefined') {
  Chartist = require('chartist');
  global.Chartist = Chartist;
  ctAxisTitle = require('chartist-plugin-axistitle');
}

export {ctAxisTitle};

export default Chartist;
