import Two from '../two-no-conflict';

import {
  convertDigitalTimeToRadians,
  convertRadiansToDigitalTime,
  flatMap,
  COLORS,
  PI,
  TAU,
} from './utils';

const HAND_TYPES = {
  minute: 'minute',
  hour: 'hour',
};

const clockFactory = mountElem => {
  if (!mountElem || !('nodeType' in mountElem)) {
    throw new Error('no mount element provided');
  }

  const eventMap = {
    mousedown: handleCursorDown,
    touchstart: handleCursorDown,
    mousemove: handleCursorMove,
    touchmove: handleCursorMove,
    mouseup: handleCursorUp,
    touchend: handleCursorUp,
  };
  const linewidth = 12;
  const distanceFromEdge = 20;
  const timers = {};
  const dialShapes = {};
  const debugShapes = {};
  let hands = {};
  let labels = [];
  let options = {
    debug: false,
    hasEvents: false,
    height: 360,
    dialPoints: 12,
    type: Two.Types.svg,
    width: 360,
  };
  let center;
  let dialWidth;
  let two;
  let activeHandKey;
  let cursorDownPos;
  let handRotationAtCursorDown = 0;

  function init(opts) {
    options = Object.assign({}, options, opts);

    two = new Two({
      type: options.type,
      width: options.width,
      height: options.height,
    }).appendTo(mountElem);

    center = {
      x: two.width / 2,
      y: two.height / 2,
    };
    dialWidth = two.width / 2 - distanceFromEdge;

    initializeDials();
    initializeHands();

    if (options.debug) {
      initializeDebugShapes();
    }

    if (options.hasEvents) {
      initializeEvents();
    }

    two.update();
    two.renderer.domElement.setAttribute('viewBox', `0 0 ${two.width} ${two.height}`);
    two.renderer.domElement.setAttribute(
      'style',
      `
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      `,
    );
  }

  function initializeDials() {
    dialShapes.dialGroup = two.makeGroup();
    dialShapes.dialShadow = drawDialShadow();
    dialShapes.dialShadow.addTo(dialShapes.dialGroup);
    dialShapes.dialBg = drawDialBg();
    dialShapes.dialBg.addTo(dialShapes.dialGroup);

    dialShapes.dialCenter = two.makeCircle(center.x, center.y, 6).noStroke();
    dialShapes.dialCenter.fill = COLORS.dialCenter;
    dialShapes.dialCenter.addTo(dialShapes.dialGroup);

    labels = drawLabels();
    labels.map(label => label.addTo(dialShapes.dialGroup));
  }

  function initializeHands() {
    const handGroups = {
      [HAND_TYPES.minute]: drawHandGroup((3 / 5) * dialWidth - distanceFromEdge),
      [HAND_TYPES.hour]: drawHandGroup((1 / 2) * dialWidth - distanceFromEdge),
    };

    hands = [HAND_TYPES.minute, HAND_TYPES.hour].reduce((acc, handKey) => {
      const path = handGroups[handKey];
      const hand = {
        angles: {
          primary: path.rotation,
          delta: 0,
        },
        path,
      };

      return {...acc, [handKey]: hand};
    }, {});

    setTime();
  }

  function initializeEvents() {
    const domElement = two.renderer.domElement;
    two.update();

    Object.keys(eventMap).map(type => domElement.addEventListener(type, eventMap[type]));
  }

  function getSnapAngle() {
    const {dialPoints} = options;

    return TAU / dialPoints;
  }

  function initializeDebugShapes() {
    const fontSize = linewidth * 2.5;
    const group = two.makeGroup();
    const time = two.makeText('', center.x, center.y - fontSize);
    time.size = fontSize;

    group.add(time);

    debugShapes.group = group;
    debugShapes.time = time;

    two.add(debugShapes.group);
  }

  function drawDialBg() {
    const dialBg = drawDial();

    dialBg.fill = COLORS.bgFill;
    dialBg.stroke = COLORS.bgStroke;
    dialBg.linewidth = linewidth;

    return dialBg;
  }

  function drawDialShadow() {
    const shadow = drawDial();

    shadow.fill = 'black';
    shadow.opacity = 0.25;
    shadow.noStroke();
    shadow.translation.set(shadow.translation.x, shadow.translation.y + linewidth);
    shadow.scale = 1.025;

    return shadow;
  }

  function drawDial() {
    return two.makeCircle(center.x, center.y, dialWidth);
  }

  function drawLabels() {
    const {dialPoints} = options;

    const labels = Array.apply(null, Array(dialPoints)).map((_, i) => i + 1);

    return labels.map((num, i) => {
      const rotationScalar = (i + 1) / labels.length;
      const theta = PI - rotationScalar * TAU;
      const textX = (center.x - distanceFromEdge * 2.75) * Math.sin(theta);
      const textY = (center.y - distanceFromEdge * 2.75) * Math.cos(theta);
      const text = two.makeText(num.toString(), textX + center.x, textY + center.y);
      text.fill = COLORS.text;
      text.size = linewidth * 2.5;

      return text;
    });
  }

  function drawHandGroup(length) {
    const handLinewidth = 4;
    const arrowLength = 6;
    // the fraction of PI | 180deg to make arc
    const arcAngleScalar = 1 / 4;
    const handArm = two.makeLine(0, 0, length, length);
    const arrow = two
      .makePath(
        center.x + length - arrowLength,
        center.y + length + arrowLength,
        center.x + length,
        center.y + length,
        center.x + length + arrowLength,
        center.y + length + arrowLength,
        true,
      )
      .noFill();
    arrow.rotation = Math.PI / 2 + Math.PI / 4;
    arrow.stroke = COLORS.hand;
    arrow.linewidth = handLinewidth;
    arrow.join = 'miter';
    arrow.cap = 'round';

    const hitArea = two.makeArcSegment(0, 0, 0, Math.hypot(length, length), 0, PI * arcAngleScalar);
    const groupOuter = two.makeGroup();
    const groupInner = two.makeGroup(handArm);
    const groupInnerDims = groupInner.getBoundingClientRect();

    handArm.linewidth = handLinewidth;
    handArm.stroke = COLORS.hand;

    arrow.translation.set(
      groupInnerDims.width - handLinewidth / 2,
      groupInnerDims.height - handLinewidth / 2,
    );

    hitArea.noStroke().noFill();
    hitArea.opacity = 0;
    // rotate 1.5x the scalar, minus the angle of the containing rectangle
    hitArea.rotation = PI * 1.5 * arcAngleScalar - Math.atan(length / length);

    if (options.debug) {
      hitArea.opacity = 0.5;
      hitArea.fill = COLORS.hitArea;
    }

    groupInner.add(hitArea, arrow);
    groupInner.cap = 'round';
    // rotate inner back so that hand points up when outer rotation is 0
    groupInner.rotation = -(PI / 2 + Math.atan(length / length));

    groupOuter.translation.set(center.x, center.y);
    groupOuter.add(groupInner);

    if (options.hasEvents) {
      two.update();
      // eslint-disable-next-line no-underscore-dangle
      groupOuter._renderer.elem.setAttribute('style', 'cursor: pointer');
    }

    return groupOuter;
  }

  function handleCursorDown(e) {
    if (!hasHands()) return;

    const event = e.targetTouches ? e.targetTouches[0] : e;
    cursorDownPos = getEventPos(event);
    activeHandKey = [HAND_TYPES.hour, HAND_TYPES.minute].reduce((accumulator, handKey) => {
      // eslint-disable-next-line no-underscore-dangle
      const handGroupElem = hands[handKey].path._renderer.elem;
      let acc = accumulator;

      if (handGroupElem.contains(event.target)) {
        acc = handKey;
      }

      return acc;
    }, null);

    if (activeHandKey) {
      const activeHandAngles = hands[activeHandKey].angles;
      handRotationAtCursorDown = activeHandAngles.primary;
    }
  }

  function handleCursorMove(e) {
    e.preventDefault();

    if (!hasHands()) return;

    if (activeHandKey && cursorDownPos) {
      const eventPos = getEventPos(e);
      const centerBounds = dialShapes.dialCenter._renderer.elem.getBoundingClientRect();
      const centerPos = {
        x: centerBounds.left + centerBounds.width / 2,
        y: centerBounds.top + centerBounds.height / 2,
      };

      const angleAtCursorDown = Math.atan2(
        cursorDownPos.y - centerPos.y,
        cursorDownPos.x - centerPos.x,
      );

      const angleAtCursorNow = Math.atan2(eventPos.y - centerPos.y, eventPos.x - centerPos.x);
      const deltaRotation = handRotationAtCursorDown + angleAtCursorNow - angleAtCursorDown;
      const rotation = (TAU + deltaRotation) % TAU;

      setHandAngle(activeHandKey, rotation);
    }
  }

  function handleCursorUp() {
    if (!hasHands()) return;

    cursorDownPos = null;

    const snapAngle = getSnapAngle();

    if (activeHandKey) {
      const {angles} = hands[activeHandKey];
      const delta = activeHandKey === 'hour' ? 0 : angles.delta;
      const rotation = angles.primary - delta;
      const deltaRotation = rotation % snapAngle;
      const shouldSnapUp = deltaRotation > snapAngle / 2;
      const gammaRotation = shouldSnapUp ? snapAngle : 0;
      const newRotation = rotation - deltaRotation + gammaRotation;

      setHandAngle(activeHandKey, newRotation);
    }

    activeHandKey = null;
  }

  function getEventPos(e) {
    const {clientX: x, clientY: y} = e.targetTouches ? e.targetTouches[0] : e;

    return {x, y};
  }

  function setHandsColor(color) {
    if (!hasHands()) return;

    Object.keys(hands).map(key => {
      const handPath = hands[key].path;
      const pathsArray = [Two.Line, Two.Path].map(type => handPath.getByType(type));

      flatMap(pathsArray).map(path => (path.stroke = color));

      return handPath;
    });

    dialShapes.dialCenter.fill = color;
    two.update();
  }

  function setHandAngle(handType, angle) {
    if (!hasHands()) return;

    const activeHand = hands[handType];
    const normalizedAngle = angle % TAU;

    if (handType === HAND_TYPES.minute) {
      setRelativeHourHandAngle(normalizedAngle);
    }

    activeHand.angles.primary = normalizedAngle;

    [HAND_TYPES.hour, HAND_TYPES.minute].forEach(type => {
      const hand = hands[type];
      const rotation = hand.angles.primary + hand.angles.delta;

      hand.path.rotation = rotation;
    });

    if (options.onRotationChange) {
      options.onRotationChange(getTime(), handType, Boolean(cursorDownPos));
    }

    if (options.debug) {
      updateDebugShapes();
    }

    two.update();
  }

  function updateDebugShapes() {
    const {hour, minute} = getTime();
    const time = `${hour}:${minute}`;

    debugShapes.time.value = time;
  }

  /*
   * Move hour hand relative to minute hand
   *
   * This needs to be called before the minute hand angle is set, otherwise we can't
   * determine if the minute hand is moving forwards or backwards
   *
   * If the minute hand is to be set to 0, and it is currently in the 55 - 59m
   * range, we need to set the hour hand primary angle to 1 hour ahead, and delta
   * to 0. Otherwise we retain the current primary hour angle, and
   *
   * @params {number} newMinuteAngle - new minute angle in radians
   */
  function setRelativeHourHandAngle(newMinuteAngle) {
    const minuteHand = hands[HAND_TYPES.minute];
    const hourHand = hands[HAND_TYPES.hour];
    const minuteAngleDelta = newMinuteAngle - minuteHand.angles.primary;
    const snapAngle = getSnapAngle();
    const newHourDelta = (snapAngle * (newMinuteAngle / TAU)) % snapAngle;
    const oldHourDelta = hourHand.angles.delta;
    const isClockWise =
      newHourDelta - oldHourDelta < snapAngle / 2 || oldHourDelta - newHourDelta > snapAngle / 2;
    const crossedHourThreshold =
      Math.abs(minuteAngleDelta) > PI || (newMinuteAngle === 0 && minuteHand.angles.primary > PI);
    const moveHourForward = isClockWise && crossedHourThreshold;
    const moveHourBack = !isClockWise && crossedHourThreshold;

    if (moveHourForward) {
      hourHand.angles.primary = (hourHand.angles.primary + snapAngle) % TAU;
    }

    if (moveHourBack) {
      hourHand.angles.primary = (TAU + hourHand.angles.primary - snapAngle) % TAU;
    }

    hourHand.angles.delta = newHourDelta;
  }

  /*
   * Set the time on the clock by passing in a digital time object. Sets the clock
   * to a random position parameter if not provided
   *
   * @param {object} [time] - The time to set
   * @param {number} time.hour - the hour component
   * @param {number} time.minute - the minute component
   */
  function setTime(time) {
    if (!hasHands()) return;

    const snapAngle = getSnapAngle();
    const timeInRadians = time
      ? convertDigitalTimeToRadians(time, options.dialPoints)
      : {hour: 0, minute: 0};

    [HAND_TYPES.hour, HAND_TYPES.minute].map(handType => {
      const minuteAngle = hands[HAND_TYPES.minute].angles.primary;
      const nextMinuteAngle = timeInRadians[HAND_TYPES.minute];
      const currAngle = timeInRadians[handType];
      const isHourHand = handType === HAND_TYPES.hour;
      const hourRequiresOffset =
        (minuteAngle >= 0 && nextMinuteAngle === 0) ||
        (minuteAngle > nextMinuteAngle && nextMinuteAngle >= 0);
      const angle = isHourHand && hourRequiresOffset ? currAngle - snapAngle : currAngle;

      return animateHand(handType, angle);
    });
  }

  function animateHand(handKey, angle) {
    if (!hasHands()) return;

    const hand = hands[handKey];
    const handAngle = hand.angles.primary;
    const increment = 0.05;
    let angleRemaining = angle > handAngle ? angle - handAngle : TAU - handAngle + angle;

    if (timers[handKey]) clearInterval(timers[handKey]);

    timers[handKey] = setInterval(() => {
      const currAngle = hand.angles.primary;
      const nextAngle = currAngle + increment;

      if (angleRemaining < increment) {
        clearInterval(timers[handKey]);
        setHandAngle(handKey, currAngle + angleRemaining);
      } else {
        setHandAngle(handKey, nextAngle);
        angleRemaining = angleRemaining - increment;
      }
    }, 10);
  }

  /*
   * Get the current time in a digital format
   *
   * @return {object} time - current time
   * @return {number} time.hour - current time
   * @return {number} time.minute - current time
   */
  function getTime() {
    const timeInRadians = {
      [HAND_TYPES.minute]: hands[HAND_TYPES.minute].angles.primary,
      [HAND_TYPES.hour]: hands[HAND_TYPES.hour].angles.primary,
    };

    return convertRadiansToDigitalTime(timeInRadians, options.dialPoints);
  }

  function hasHands() {
    return Object.keys(hands).length > 0;
  }

  function destroy() {
    destroyHands();
    destroyDialShapes();
    destroyLabels();

    if (options.debug) {
      destroyDebugShapes();
    }

    two.clear();
    two.update();

    destroyEvents();
    destroyTimers();
  }

  function destroyHands() {
    return Object.keys(hands).map(key => {
      two.remove(hands[key].path);

      return delete hands[key];
    });
  }

  function destroyDebugShapes() {
    return Object.keys(debugShapes).map(key => {
      two.remove(debugShapes[key].path);

      return delete debugShapes[key];
    });
  }

  function destroyDialShapes() {
    return Object.keys(dialShapes).map(key => {
      two.remove(dialShapes[key]);

      return delete dialShapes[key];
    });
  }

  function destroyLabels() {
    return (labels = labels.map(path => two.remove(path)));
  }

  function destroyEvents() {
    const domElement = two.renderer.domElement;

    return Object.keys(eventMap).map(type => domElement.removeEventListener(type, eventMap[type]));
  }

  function destroyTimers() {
    return Object.keys(timers).forEach(key => {
      if (timers[key]) clearInterval(timers[key]);
    });
  }

  return {
    destroy,
    getTime,
    init,
    setHandsColor,
    setTime,
  };
};

export {HAND_TYPES};

export default clockFactory;
