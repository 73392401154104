import React from 'react';
import {moneyOptionsFields} from './shared/money';

export default {
  key: 'money-solve',
  name: 'Money Solve',
  description: (
    <div>
      <hr />

      <h2>Money Solve Values</h2>

      <p>Provide the value a user should match in pounds.</p>

      <p>
        e.g.:<br />
        50p would be <code>0.50</code>. <br />
        £50 would be <code>50</code>. <br />
        £50.50 would be <code>50.50</code>.
      </p>
    </div>
  ),

  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'money-solve',
  },

  vars: {
    text: {type: 'text', label: 'Text'},
    value: {type: 'number', label: 'Value', placeholder: 'e.g. 1.45'},
    options: moneyOptionsFields,
  },
};
