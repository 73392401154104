import PropTypes from 'prop-types';
import React from 'react';

import clockFactory, {HAND_TYPES} from '../lib/clock';

class ClockCanvas extends React.Component {
  static displayName = 'ClockCanvas';

  static propTypes = {
    debug: PropTypes.bool,
    hasEvents: PropTypes.bool,
    time: PropTypes.shape({
      hour: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      minute: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  };

  static defaultProps = {
    debug: false,
    dialPoints: 12,
    hasEvents: false,
  };

  lastAngle = null;

  componentDidMount() {
    const {debug, hasEvents, dialPoints, time} = this.props;
    const mount = this.clockMount;

    this.clock = clockFactory(mount);
    this.clock.init({
      debug,
      dialPoints,
      hasEvents,
      onRotationChange: this.handleRotationChange,
    });

    this.clock.setTime(time);
  }

  componentDidUpdate(prevProps) {
    const {handsColor, time} = this.props;

    if (handsColor !== prevProps.handsColor) {
      this.setHandsColor();
    }

    if (time !== prevProps.time) {
      this.setTime();
    }
  }

  componentWillUnmount() {
    this.clock.destroy();

    this.lastAngle = null;
  }

  /*
   * handle when the clock's hand angle is changed
   *
   * @param {object} digitalTime - the digital format of the clock's current time
   * @param {number} digitalTime.hour - the hour of the clock's current time
   * @param {number} digitalTime.minute - the minute of the clock's current time
   * @param {string} lastHandType - whether the last hand movement was the hour or
   *                                minute hand
   * @param {boolean} isActive - is the user moving the hand or not
   */
  handleRotationChange = (digitalTime, lastHandType, isCursorDown) => {
    const {dialPoints, onRotationChange} = this.props;
    const time = digitalTime[lastHandType];
    const timeDivisor = lastHandType === HAND_TYPES.minute ? 60 : dialPoints;

    const angleInDegrees = ((time / timeDivisor) * 360) % 360;
    const flooredAngle = Math.floor(angleInDegrees);
    const angleThreshold = 360 / dialPoints;

    if (isNaN(this.lastAngle)) {
      this.lastAngle = flooredAngle - (flooredAngle % angleThreshold);
    }

    const thresholdExceeded =
      Math.abs(flooredAngle) >= Math.abs(this.lastAngle) + angleThreshold ||
      Math.abs(flooredAngle) < Math.abs(this.lastAngle);

    if ((thresholdExceeded || !isCursorDown) && typeof onRotationChange === 'function') {
      setTimeout(() => {
        this.lastAngle = flooredAngle - (flooredAngle % angleThreshold);
        onRotationChange(digitalTime, lastHandType);
      }, 0);
    }
  };

  setTime = () => {
    const {time} = this.props;

    this.clock.setTime(time);
  };

  setHandsColor = () => {
    const {handsColor} = this.props;

    this.clock.setHandsColor(handsColor);
  };

  render() {
    return (
      <div
        className="time__clock-container"
        data-testid="clock-canvas"
        ref={c => (this.clockMount = c)}
      />
    );
  }
}

export {ClockCanvas};

export default ClockCanvas;
