import PropTypes from 'prop-types';
import React from 'react';

const Breadcrumbs = ({crumbs, onClick}) => {
  const clickHandler = c => e => {
    if (onClick) {
      e.preventDefault();
      onClick(c.path, c);
    }
  };

  return (
    <div className="breadcrumbs">
      {crumbs.map((c, i) => (
        <span key={i}>
          {c.active ? (
            <span>{c.text}</span>
          ) : (
            <a onClick={clickHandler(c)} href={c.path}>
              {c.text}
            </a>
          )}
          {i < crumbs.length - 1 ? <span> &gt; </span> : null}
        </span>
      ))}
    </div>
  );
};

Breadcrumbs.displayName = 'Breadcrumbs';
Breadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

export default Breadcrumbs;
