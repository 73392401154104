import Two from '../two-no-conflict';

// http://codepen.io/anon/pen/ozZJbw
const PI = Math.PI;
const TAU = 2 * PI;
const COLORS = {
  GREY: '#808080',
  PURPLE: '#633d8a',
  YELLOW: '#f9c654',
  WHITE: 'white',
};

const dialFactory = mount => {
  if (!mount || !('nodeType' in mount)) {
    throw new Error('no mount element provided');
  }

  const paths = {};
  let options = {
    centerRadius: 280 / 50,
    dialFaceLinewidth: 1,
    height: 280,
    width: 280,
    type: 'svg',
    tickLength: 280 / 25,
    onAnimate: () => {},
  };
  let timer;
  let two;

  function init(opts = {}) {
    options = Object.assign({}, options, opts);
    two = new Two({
      type: Two.Types[options.type],
      width: Math.min(options.width, options.height),
      height: Math.min(options.width, options.height),
    }).appendTo(mount);

    drawDialFace();
    drawDialHand();
    drawDialCenter();

    setViewBox(options.width, options.height);

    two.renderer.domElement.setAttribute(
      'style',
      `
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        max-width: 100%;
        height: auto;
      `,
    );
  }

  function setViewBox(width, height) {
    const dim = Math.min(width, height);

    two.renderer.domElement.setAttribute('viewBox', `0 0 ${dim} ${dim}`);
  }

  function drawDialFace() {
    const {dialFaceLinewidth} = options;

    if (paths.dialFace) destroyDialFace();

    const dialFace = two.makeCircle(
      two.width / 2,
      two.height / 2,
      two.width / 2 - dialFaceLinewidth * 2,
    );
    dialFace.fill = COLORS.WHITE;
    dialFace.stroke = COLORS.YELLOW;
    dialFace.linewidth = dialFaceLinewidth;

    paths.dialFace = dialFace;
  }

  function drawDialCenter() {
    const {centerRadius} = options;
    if (paths.dialCenter) destroyDialCenter();

    const dialCenter = two.makeCircle(two.width / 2, two.height / 2, centerRadius).noStroke();
    dialCenter.fill = COLORS.PURPLE;

    paths.dialCenter = dialCenter;
  }

  function setUnitText(text) {
    const {centerRadius} = options;
    if (paths.unitText) destroyUnitText();

    if (typeof text === 'string') {
      const unitTextPath = two.makeText(text, two.width / 2, two.height / 2 + centerRadius * 3);
      unitTextPath.size = two.width / 25;
      unitTextPath.fill = COLORS.GREY;

      paths.unitText = unitTextPath;
    }
  }

  function setLabels(labels = []) {
    const {tickLength} = options;

    if (paths.labels) destroyLabels();

    const radius = two.width / 2;

    paths.labels = labels.map((num, i) => {
      const rotationScalar = i / labels.length;
      const theta = PI - rotationScalar * PI * 2;
      const textX = (radius - tickLength * 2.5) * Math.sin(theta);
      const textY = (radius - tickLength * 2.5) * Math.cos(theta);
      const textPath = two.makeText(num.toString(), textX + radius, textY + radius);
      textPath.fill = COLORS.PURPLE;
      textPath.size = radius / 10;

      return textPath;
    });
  }

  function setTicks(ticks, labels) {
    const {tickLength} = options;

    if (paths.ticks) destroyTicks();

    const radius = two.width / 2;
    const boldTickIndex = ticks.length / labels.length;
    const numTicks = ticks.length;

    paths.ticks = ticks.map((_, i) => {
      const rotationScalar = i / numTicks;
      const theta = PI - rotationScalar * PI * 2;
      const tickX1 = radius * Math.sin(theta);
      const tickY1 = radius * Math.cos(theta);
      const tickX2 = (radius - tickLength) * Math.sin(theta);
      const tickY2 = (radius - tickLength) * Math.cos(theta);
      const tickPath = two.makeLine(
        radius + tickX1,
        radius + tickY1,
        radius + tickX2,
        radius + tickY2,
      );
      tickPath.stroke = COLORS.YELLOW;
      tickPath.linewidth = i % boldTickIndex === 0 ? 3 : 1;

      return tickPath;
    });
  }

  function drawDialHand() {
    if (paths.hand) destroyHand();

    const hand = two.makeLine(
      0,
      0,
      two.width / 2 - (two.width / 2) * 0.5,
      two.height / 2 - (two.height / 2) * 0.5,
    );
    const groupOuter = two.makeGroup();
    const groupInner = two.makeGroup(hand);
    const groupInnerDims = groupInner.getBoundingClientRect();

    hand.linewidth = 3;
    hand.stroke = COLORS.PURPLE;
    hand.opacity = 0.7;
    hand.cap = 'round';
    // rotate inner back so that hand points up when outer rotation is 0
    groupInner.rotation = -(PI / 2 + Math.atan(groupInnerDims.width / groupInnerDims.height));

    groupOuter.translation.set(two.width / 2, two.height / 2);
    groupOuter.add(groupInner);

    paths.hand = groupOuter;
  }

  function setHandAngle(angle) {
    const {hand} = paths;

    if (!hand) {
      clearInterval(timer);
    } else {
      hand.rotation = angle;
    }

    update();
  }

  function animateHand(angle) {
    const {onAnimate} = options;
    const {hand} = paths;
    const {rotation} = hand;
    const degreesToIncrement = 5;
    const increment = (degreesToIncrement * PI) / 180;
    let remaining = angle > rotation ? angle - rotation : TAU - rotation + angle;

    if (timer) clearInterval(timer);

    timer = setInterval(() => {
      if (remaining < increment) {
        clearInterval(timer);
        setHandAngle(angle);
      } else {
        remaining = remaining - increment;
        setHandAngle(hand.rotation + increment);
      }

      if (typeof onAnimate === 'function') {
        onAnimate();
      }
    }, 50);
  }

  function update() {
    two.update();
  }

  function destroyDialFace() {
    two.remove(paths.dialFace);
    delete paths.dialFace;
  }

  function destroyDialCenter() {
    two.remove(paths.dialCenter);
    delete paths.dialCenter;
  }

  function destroyHand() {
    two.remove(paths.hand);
    delete paths.hand;
  }

  function destroyLabels() {
    if (paths.labels) {
      paths.labels.map(l => two.remove(l));
      delete paths.labels;
    }
  }

  function destroyTicks() {
    if (paths.ticks) {
      paths.ticks.map(t => two.remove(t));
      delete paths.ticks;
    }
  }

  function destroyUnitText() {
    two.remove(paths.unitText);
    delete paths.unitText;
  }

  function destroy() {
    destroyDialCenter();
    destroyDialFace();
    destroyLabels();
    destroyHand();
    destroyTicks();
    destroyUnitText();
    two.clear();
  }

  return {
    animateHand,
    destroy,
    init,
    setHandAngle,
    setUnitText,
    setLabels,
    setTicks,
    update,
  };
};

export default dialFactory;
