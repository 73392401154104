import {LINEWIDTH, setDivisionDefaults, setShapeDefaults} from './utils';

const PI = Math.PI;

const circleFactory = ({twoInstance, center, numDivisions}) => {
  const shapeObj = {
    group: twoInstance.makeGroup(),
  };

  function init() {
    initShape();
    initDivisions();
  }

  function initShape() {
    shapeObj.shape = twoInstance.makeCircle(
      center.x,
      center.y,
      (twoInstance.width - LINEWIDTH) / 2
    );
    setShapeDefaults(shapeObj.shape);

    shapeObj.group.add(shapeObj.shape);
  }

  function initDivisions() {
    const arcAngle = 2 * PI / numDivisions;

    shapeObj.divisions = Array.apply(null, Array(numDivisions)).map((_, i) => {
      const arcSeg = twoInstance.makeArcSegment(
        center.x,
        center.y,
        0,
        (twoInstance.width - LINEWIDTH) / 2,
        0,
        arcAngle
      );
      arcSeg.rotation = i * arcAngle;
      setDivisionDefaults(arcSeg);
      shapeObj.group.add(arcSeg);

      return arcSeg;
    });
  }

  init();

  return shapeObj;
};

export default circleFactory;
