export default {
  key: 'multiple-choice-flip',
  name: 'Multiple Choice Flip',
  description: '',

  summary: {
    type: 'multiple-choice-flip',
  },

  resources: {
    thumbnailUrl: '',
  },
  vars: {
    text: {type: 'text', label: 'Text'},
    cards: {
      type: 'list',
      label: 'Cards',
      help: `
        Add cards for users to compare.
      `,
      max: 2,
    },
    choices: {
      type: 'multiple-choice',
      label: 'Choices',
    },
  },
};
