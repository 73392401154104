import PropTypes from 'prop-types';
import React from 'react';

class GridCol extends React.Component {
  static displayName = 'GridCol';

  static propTypes = {
    baseWidth: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.any,
    tagName: PropTypes.string,
    viewports: PropTypes.object,
  };

  static defaultProps = {
    className: '',
  };

  getVpClasses = () => {
    const {viewports} = this.props;

    return viewports
      ? Object.keys(viewports).reduce((acc, key) => `${acc} g-${key}-${viewports[key]}`, '')
      : '';
  };

  render() {
    const {className, baseWidth, children, tagName} = this.props;
    const CustomTagName = `${tagName || 'div'}`;
    const vpClasses = this.getVpClasses();
    const baseWidthClassName = baseWidth ? `g-${baseWidth}` : '';

    return (
      <CustomTagName className={`g ${className} ${baseWidthClassName} ${vpClasses}`}>
        {children}
      </CustomTagName>
    );
  }
}

export default GridCol;
