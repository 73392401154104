import PropTypes from 'prop-types';
import React from 'react';

import fieldHOC from './Field';
import RadioCheckCustomIcon from './RadioCheckCustomIcon';

const RadioCheckGroupField = ({options, value, defaultValue, ...props}) => (
  <div className="radio-group">
    {Object.keys(options || {}).map((o, i) => (
      <div key={o}>
        <input
          {...Object.assign({className: 'visuallyhidden'}, props)}
          checked={value === o || (value === '' && defaultValue && defaultValue === o)}
          type="radio"
          value={o}
          id={`${props.id || props.name}-${i}`}
        />
        &nbsp;
        <label
          className={`${props.disabled ? 'is-disabled' : ''}`}
          htmlFor={`${props.id || props.name}-${i}`}
        >
          <RadioCheckCustomIcon type="radio" /> {options[o]}
        </label>
      </div>
    ))}
  </div>
);

RadioCheckGroupField.displayName = 'RadioCheckGroupField';

RadioCheckGroupField.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
};

export default fieldHOC(RadioCheckGroupField);
