import PropTypes from 'prop-types';
import React from 'react';
import Transition from 'react-motion-ui-pack';

const TransitionFadeInOut = ({component, children}) => (
  <Transition component={component} enter={{opacity: 1}} leave={{opacity: 0}}>
    {children || <span key="fallback" />}
  </Transition>
);

TransitionFadeInOut.displayName = 'TransitionFadeInOut';
TransitionFadeInOut.propTypes = {
  component: PropTypes.object,
  children: PropTypes.any,
};

export default TransitionFadeInOut;
