import PropTypes from 'prop-types';
import React from 'react';
import {Router, Route} from 'react-router';
import {replace} from 'react-router-redux';
import {connect} from 'react-redux';

import logPageView from '../lib/analytics';
import PlayLoginPage from './components/routes/PlayLoginPage';
import LoginPage from './components/routes/LoginPage';
import SignUpPage from './components/routes/SignUpPage';
import ForgottenPasswordPage from './components/routes/ForgottenPasswordPage';
import ResetPasswordPage from './components/routes/ResetPasswordPage';
import ConfirmationPage from './components/routes/ConfirmationPage';
import ResendConfirmationPage from './components/routes/ResendConfirmationPage';

import routes from './routes';

function redirector(path) {
  return connect(
    s => s,
    {replace},
  )(props => {
    /* eslint react/prop-types:0 */
    props.replace(path);
    return <span />;
  });
}

const Routes = ({history}) => (
  <Router history={history} onUpdate={logPageView('accountHolderPortal')}>
    {/* <Route path={routes.playLogin} component={PlayLoginPage} /> */}
    <Route path={routes.login} component={LoginPage} />
    {/* <Route path={routes.signUp} component={SignUpPage} /> */}
    <Route path={routes.forgotten} component={ForgottenPasswordPage} />
    <Route path={routes.resetPassword} component={ResetPasswordPage} />
    <Route path={routes.confirmation} component={ConfirmationPage} />
    <Route path={routes.resendConfirmation} component={ResendConfirmationPage} />
    <Route path="*" component={redirector('/login')} />
  </Router>
);

Routes.displayName = 'Routes';
Routes.propTypes = {
  history: PropTypes.object.isRequired,
};

Routes.shouldComponentUpdate = () => false;

export default Routes;
