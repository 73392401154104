import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';
import Isvg from 'react-inlinesvg';

import cakeFactory from '../lib/cake-canvas';

import icingSvg from '../../img/raw/inline/games/challenges/word-elimination/icing.svg';
import candleSvg from '../../img/raw/inline/games/challenges/word-elimination/candle.svg';

const SVGS = [{name: 'candle', url: candleSvg}, {name: 'icing', url: icingSvg}];

class CakeCanvas extends React.Component {
  static displayName = 'CakeCanvas';

  static defaultProps = {
    className: 'cake-canvas',
    numCandles: 4,
  };

  static propTypes = {
    className: PropTypes.string,
    numLayers: PropTypes.number.isRequired,
    numCandles: PropTypes.number,
    layersVisible: PropTypes.number,
    onCandleAnimEnd: PropTypes.func,
    onLayerAnimEnd: PropTypes.func,
  };

  state = {
    numSvgsLoaded: 0,
    width: 0,
    height: 0,
  };

  componentDidUpdate(prevProps) {
    const {layersVisible} = this.props;

    if (!this.cakeCanvas) return;

    if (layersVisible !== prevProps.layersVisible) {
      this.handleNewLayer();
    }
  }

  componentWillUnmount() {
    if (this.cakeCanvas) {
      this.cakeCanvas.destroy();
    }

    window.removeEventListener('resize', this.updateCanvasDims);
  }

  handleNewLayer = () => {
    this.cakeCanvas.showNextLayer();
  };

  handleSvgLoad = () => {
    this.setState(
      ({numSvgsLoaded}) => ({numSvgsLoaded: numSvgsLoaded + 1}),
      () => {
        if (this.state.numSvgsLoaded === SVGS.length && !this.cakeCanvas) {
          this.initialiseCakeCanvas();
        }
      },
    );
  };

  initialiseCakeCanvas = () => {
    const {onCandleAnimEnd, onLayerAnimEnd, numCandles, numLayers} = this.props;
    const mount = this.mountRef;
    const svgNodes = SVGS.reduce((acc, {name}) => {
      acc[name] = findDOMNode(this[`${name}Svg`]).querySelector('svg');

      return acc;
    }, {});

    this.nextLayerTimers = [];

    window.addEventListener('resize', this.updateCanvasDims);

    this.cakeCanvas = cakeFactory(mount);
    this.cakeCanvas.init({
      height: mount.offsetHeight,
      numCandles,
      numLayers,
      onCandleAnimEnd,
      onLayerAnimEnd,
      svgNodes,
      width: mount.offsetWidth,
    });
  };

  updateCanvasDims = () => {
    const {layersVisible} = this.props;
    const {offsetWidth, offsetHeight} = this.mountRef;

    this.cakeCanvas.updateDims({width: offsetWidth, height: offsetHeight});
    this.cakeCanvas.reset();

    if (this.nextLayerTimers.length) {
      this.nextLayerTimers = this.nextLayerTimers
        .map(id => {
          clearTimeout(id);

          return false;
        })
        .filter(o => !!o);
    }

    this.nextLayerTimers = Array.apply(null, Array(layersVisible)).map((_, i) =>
      setTimeout(this.cakeCanvas.showNextLayer, i * 150),
    );
  };

  render() {
    const {className} = this.props;

    return (
      <div className={`${className}`} data-testid="cake-canvas">
        <div className={`${className}__images`}>
          {SVGS.map(({name, url}) => (
            <Isvg
              cacheGetRequests
              key={name}
              onLoad={this.handleSvgLoad}
              ref={c => (this[`${name}Svg`] = c)}
              src={url}
            />
          ))}
        </div>

        <div
          className={`${className}__canvas`}
          data-testid="cake-canvas"
          ref={c => (this.mountRef = c)}
        />
      </div>
    );
  }
}

export default CakeCanvas;
