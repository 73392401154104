import PropTypes from 'prop-types';
import React from 'react';

import bubbleCanvasFactory from '../lib/bubble-canvas';

class BubbleCanvas extends React.Component {
  static defaultProps = {className: 'bubble-canvas'};

  static propTypes = {
    className: PropTypes.string,
    explodeCoords: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    explodeShape: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    options: PropTypes.object,
  };

  state = {
    width: 0,
    height: 0,
  };

  componentDidMount() {
    const {options} = this.props;
    const mount = this.mountRef;

    window.addEventListener('resize', this.updateCanvasDims);
    this.bubbleCanvas = bubbleCanvasFactory(mount);
    this.bubbleCanvas.init(options);
    this.updateCanvasDims();
  }

  componentDidUpdate(prevProps) {
    const {options, explodeCoords, explodeShape} = this.props;

    if (explodeShape && explodeShape !== prevProps.explodeShape) {
      this.handleShapeExplode();
    }

    if (explodeCoords && explodeCoords !== prevProps.explodeCoords) {
      this.handleExplode();
    }

    if (options !== prevProps.options) {
      this.updateOptions();
    }
  }

  componentWillUnmount() {
    this.bubbleCanvas.destroy();
    window.removeEventListener('resize', this.updateCanvasDims);
  }

  handleShapeExplode = () => {
    const {explodeShape} = this.props;

    this.bubbleCanvas.explodeShape(explodeShape);
  };

  handleExplode = () => {
    const {explodeCoords} = this.props;

    this.bubbleCanvas.explode(explodeCoords);
  };

  updateOptions = () => {
    const {options} = this.props;

    this.bubbleCanvas.setOptions(options);
  };

  updateCanvasDims = () => {
    const {offsetWidth, offsetHeight} = this.mountRef;

    this.bubbleCanvas.updateDims({width: offsetWidth, height: offsetHeight});
  };

  render() {
    const {className} = this.props;

    return <div className={className} ref={c => (this.mountRef = c)} />;
  }
}

export default BubbleCanvas;
