import PropTypes from 'prop-types';
import React from 'react';

const Alert = ({type, children, message, onRequestClose, id}) => (
  <div className={`alert--${type}`}>
    <button className="alert__close" onClick={() => onRequestClose && onRequestClose(id)}>
      ×
    </button>
    {children}
    {message}
  </div>
);

Alert.displayName = 'Alert';

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'danger', 'warn', 'info', 'success']),
  children: PropTypes.element,
  onRequestClose: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  message: PropTypes.string,
};

Alert.defaultProps = {type: 'info'};

export default Alert;
