import React from 'react';
import omit from 'lodash/fp/omit';

import fieldHOC from './Field';

const prepProps = omit(['optional', 'help']);

const TextAreaField = props => (
  <textarea autoCapitalize="none" autoComplete="none" autoCorrect="none" {...prepProps(props)} />
);

TextAreaField.displayName = 'TextAreaField';

export {TextAreaField};

export default fieldHOC(TextAreaField);
