import PropTypes from 'prop-types';
import React from 'react';

import CircleInner from './CircleInner';

const Circle = ({className, classNameInner, text, iconId}) => (
  <i className={`circle ${className || ''}`}>
    <CircleInner className={classNameInner} text={text} iconId={iconId} />
  </i>
);

Circle.displayName = 'Circle';

Circle.propTypes = {
  className: PropTypes.string,
  classNameInner: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconId: PropTypes.string,
};

export default Circle;
