import PropTypes from 'prop-types';
import React from 'react';

import Loader from '../components/Loader';

const LoaderFullScreen = ({className}) => (
  <div className={`loader-container--fullscreen ${className || ''}`}>
    <Loader className="loader--large loader--spin" />
  </div>
);

LoaderFullScreen.displayName = 'LoaderFullScreen';
LoaderFullScreen.propTypes = {
  className: PropTypes.string,
};

export default LoaderFullScreen;
