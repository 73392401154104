import PropTypes from 'prop-types';
import React from 'react';
import {Field, reduxForm as connectForm} from 'redux-form';
import {combineValidators} from 'redux-form-revalid';
import isEmail from 'redux-form-revalid/validations/isEmail';

import {Button, InputField, InputFieldMessage, Loader} from '@edu-game/assets';

class ResendConfirmationForm extends React.Component {
  static displayName = 'ResendConfirmationForm';

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  render() {
    const {handleSubmit, submitting, error} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="email"
          msgClassName="fc--red-alpha-ltst"
          component={InputField}
          type="email"
          label="Email address"
          className="field--trans"
          withIcon
          fieldProps={{autoFocus: true}}
        />

        {error ? (
          <InputFieldMessage className="fc--red-alpha-ltst" tagName="p">
            {error.message}
          </InputFieldMessage>
        ) : null}

        <Button type="submit" modifiers={['purple-alpha', 'full']}>
          Resend Confirmation &nbsp;
          {submitting ? <Loader className="loader--spin" numIcons={1} /> : null}
        </Button>
      </form>
    );
  }
}

export default connectForm({
  form: 'resetConfimation',
  validate: combineValidators({
    email: isEmail(),
  }),
})(ResendConfirmationForm);
