import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import '../global';

const {sentry: sentrySettings} = global.__appSettings;

// if (process.env.NODE_ENV !== 'development') {
Sentry.init({
  dsn: sentrySettings.dsn,
  environment: process.env.NODE_ENV,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  // To set a uniform sample rate
  tracesSampleRate: 1,
});
// }
