import PropTypes from 'prop-types';
import React from 'react';

import fieldHOC from './Field';
import ListInput from './list/ListInput';
import ListItem from './list/ListItem';

class ListField extends React.Component {
  static displayName = 'ListField';

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
    max: PropTypes.number,
  };

  handleItemRemove = (item) => {
    const {onChange, value: items} = this.props;
    onChange(items.filter(i => i !== item));
  };

  handleSubmit = (v) => {
    const {onChange, value, max} = this.props;
    const items = (value || []).concat(v);
    if (max && items.length > max) {
      return;
    }
    onChange(items);
  };

  render() {
    const {value, max} = this.props;
    const items = value || [];
    const field =
      max && items.length === max ? (
        <p>max number of items added: {max}</p>
      ) : (
        <ListInput onSubmit={this.handleSubmit} />
      );

    return (
      <div>
        <div className="push--smaller--bottom">{field}</div>

        {items.map((item, i) => (
          <ListItem key={i} item={item} onRemoveClick={this.handleItemRemove} />
        ))}
      </div>
    );
  }
}

ListField.defaultProps = {value: []};

export default fieldHOC(ListField);
