/* eslint-disable no-underscore-dangle */
import Two from '../two-no-conflict';

const bombFactory = ({debug, parentElem, svgNode}) => {
  const paths = {};
  let center = new Two.Vector(0, 0);
  let bombMount;
  let dims;
  let parentDims;
  let two;

  function init() {
    bombMount = document.createElement('div');
    bombMount.style.position = 'absolute';
    parentElem.insertAdjacentElement('afterbegin', bombMount);
    two = new Two({
      type: Two.Types.svg,
    })
      .appendTo(bombMount)
      .play();

    paths.bomb = two.interpret(svgNode);
    dims = paths.bomb.getBoundingClientRect(true);
    two.width = dims.width;
    two.height = dims.height;
    paths.bomb.translation.set(two.width / 2, two.height / 2);
    paths.bomb.center();
    paths.bomb.vel = new Two.Vector(getVelocityComponent(), getVelocityComponent());
    parentDims = parentElem.getBoundingClientRect();
    two.update();
  }

  function getVelocityComponent() {
    return (Math.random() * (3 - 1) + 1) * (Math.random() > 0.5 ? 1 : -1);
  }

  function animate() {
    const {bomb} = paths;
    const {height, width} = dims;
    const {height: pHeight, width: pWidth} = parentDims;
    const {x, y} = center;
    const continueX = x > width / 2 && x < pWidth - width / 2;
    const continueY = y > height / 2 && y < pHeight - height / 2;

    if (!continueX) bomb.vel.x = -bomb.vel.x;
    if (!continueY) bomb.vel.y = -bomb.vel.y;

    center.addSelf(bomb.vel);
    setTransform();
  }

  function setCenter(vector) {
    center = vector;

    setTransform();
  }

  function setTransform() {
    const {width, height} = dims;

    bombMount.style.transform = `translate3d(
      ${center.x - width / 2}px,
      ${center.y - height / 2}px,
      0
    )`;
  }

  function isCollision(point) {
    const {height, width} = dims;

    return center.distanceTo(point) <= Math.sqrt(width * height);
  }

  function destroy() {
    Object.keys(paths).map(key => {
      two.remove(paths[key]);

      return delete paths[key];
    });

    parentElem.removeChild(bombMount);
    bombMount = undefined;
  }

  init();

  return {
    animate,
    destroy,
    isCollision,
    setCenter,
  };
};

export default bombFactory;
