import omit from 'lodash/fp/omit';
import memoize from 'lodash/fp/memoize';

const omitName = omit('name');

const types = [
  {name: 'desert-sands', title: 'Desert Sands'},
  {name: 'uppity-island', title: 'Uppity Island'},
  {name: 'jeepers-jungle', title: 'Jeepers Jungle'},
  {name: 'winter-freeze', title: 'Winter Freeze'},
  {name: 'crayona-kingdom', title: 'Crayona Kingdom'},
  {name: 'lava-island', title: 'Lava Island'},
  {name: 'howling-kingdom', title: 'Howling Kingdom'},
  {name: 'hovering-heights', title: 'Hovering Heights'},
  {name: 'jelly-isle', title: 'Jelly Isle'},
  {name: 'sunken-island', title: 'Sunken Island'},
  {name: 'floatsome-realm', title: 'Floatsome Realm'},
];

types.asObject = memoize(() =>
  types.reduce((obj, world) => ({...obj, [world.name]: omitName(world)}), {})
);

types.forSelect = memoize(() =>
  types.reduce((obj, world) => ({...obj, [world.name]: world.title}), {})
);

export default types;
