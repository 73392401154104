import PropTypes from 'prop-types';
import React from 'react';
import omit from 'lodash/fp/omit';

import fieldHOC from './Field';

const prepProps = omit(['optional', 'component', 'options', 'defaultOption']);

function renderOptions(options) {
  let opts = options;

  if (Array.isArray(options)) {
    // Convert array of options to object with same key and value
    opts = options.reduce((obj, o) => Object.assign(obj, {[o]: o}), {});
  }
  const isNested = Object.keys(opts).some(k => typeof opts[k] === 'object');

  if (isNested) {
    return Object.keys(opts || {}).map(o => (
      <optgroup key={`optgroup-${o}`} label={o}>
        {Object.keys(opts[o]).map(k => (
          <option key={`${o}-${k}`} value={k}>
            {opts[o][k].title || opts[o][k] || k}
          </option>
        ))}
      </optgroup>
    ));
  }

  return Object.keys(opts || {}).map(o => (
    <option key={o} value={o}>
      {opts[o].title || opts[o] || o}
    </option>
  ));
}

const SelectField = props => {
  const {options, defaultOption} = props;

  const [defaultKey, defaultVal] = defaultOption || [null, ''];

  return (
    <select {...prepProps(props)}>
      {defaultOption !== false ? <option value={defaultKey}>{defaultVal}</option> : null}
      {options ? renderOptions(options) : null}
    </select>
  );
};

SelectField.displayName = 'SelectField';

SelectField.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  defaultOption: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

SelectField.defaultProps = {defaultOption: false};

export default fieldHOC(SelectField);
