import PropTypes from 'prop-types';
import React from 'react';

const ProgressBar = ({modifiers, percent}) => (
  <span className={`progress-bar ${modifiers.map(m => `progress-bar--${m}`)}`}>
    <span className="progress-bar__progress" style={{width: `${percent}%`}} />
  </span>
);

ProgressBar.displayName = 'ProgressBar';
ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  modifiers: PropTypes.array,
};
ProgressBar.defaultProps = {
  modifiers: [],
};

export default ProgressBar;
