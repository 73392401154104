import PropTypes from 'prop-types';
import React from 'react';

import InlineSVGIcon from './InlineSVGIcon';

const Notification = ({type, children, message, onRequestClose, id}) => (
  <div className={`notification notification--${type}`}>
    <button className="notification__close" onClick={() => onRequestClose && onRequestClose(id)}>
      <InlineSVGIcon iconId="c-icon-cross" />
    </button>
    {children}
    {typeof message === 'object' ? <span dangerouslySetInnerHTML={message} /> : message}
  </div>
);

Notification.displayName = 'Notification';

Notification.propTypes = {
  type: PropTypes.oneOf(['dark']),
  children: PropTypes.element,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onRequestClose: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Notification.defaultProps = {type: 'dark'};

export default Notification;
