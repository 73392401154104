import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';
import {SubmissionError} from 'redux-form';

import {UserManagementContainer} from '@edu-game/assets';

import PlayLoginForm from '../forms/PlayLogin';
import LinksFooter from '../LinksFooter';
import PageTitle from '../PageTitle';

import createApp, {storage} from '../../feathers';
import routes from '../../routes';

const {location} = global;
const {jwt} = global.__appSettings;

const app = createApp({authentication: {storageKey: jwt.playerStorageKey}});

class PlayLoginPage extends React.Component {
  static displayName = 'PlayLoginPage';

  static propTypes = {
    route: PropTypes.object.isRequired,
  };

  state = {isChecking: storage.isPersist() && storage.getItem(jwt.playerStorageKey)};

  componentWillMount() {
    if (this.state.isChecking) {
      app
        .authenticate()
        .then(({user}) => {
          if (user) {
            this.redirectToApp(user);
            return;
          }
          this.setState({isChecking: false});
        })
        .catch(() => {
          this.setState({isChecking: false});
          storage.setShouldPersist(false);
        });
    }
  }

  redirectToApp = user => {
    location.href = routes.redirectForUser(user);
  };

  handleSubmit = values => {
    this.setState({isLoggingIn: true});
    storage.setShouldPersist(values.remember);

    return app
      .authenticate({
        password: values.password,
        strategy: 'player-local',
        username: values.username,
      })
      .then(({user}) => {
        this.redirectToApp(user);
      })
      .catch(err => {
        this.setState({isLoggingIn: false});
        throw new SubmissionError({_error: err});
      });
  };

  render() {
    const {history} = this.props;
    const {isChecking, isLoggingIn} = this.state;

    return (
      <UserManagementContainer className="user-management--player" history={history} showBack>
        <PageTitle className="fs--giga u-ff--alt push--smaller--bottom">Student login</PageTitle>

        <p className="text--center">
          <Link className="fc--ntrl-min" to={routes.login}>
            Switch to parent login
          </Link>
        </p>

        {isChecking ? null : (
          <div>
            <div className="push--smaller--bottom">
              <PlayLoginForm onSubmit={this.handleSubmit} isLoading={isLoggingIn} />
            </div>

            <LinksFooter>
              <Link className="fc--ntrl-min" to={routes.login}>
                Parent login
              </Link>
            </LinksFooter>
          </div>
        )}
      </UserManagementContainer>
    );
  }
}

export default PlayLoginPage;
