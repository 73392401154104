import PropTypes from 'prop-types';
import React from 'react';

import shapeFactory from '../lib/shape-canvas';

class ShapeCanvas extends React.Component {
  static displayName = 'ShapeCanvas';

  static defaultProps = {
    attachEvents: false,
    className: 'shape-canvas',
    numRandSelected: 0,
    onDeselect() {},
    onSelect() {},
    selection: [],
    width: 360,
    height: 360,
  };

  static propTypes = {
    attachEvents: PropTypes.bool,
    className: PropTypes.string,
    divisions: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.number,
    numRandSelected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onDeselect: PropTypes.func,
    onSelect: PropTypes.func,
    selectedColor: PropTypes.string,
    selection: PropTypes.array,
    shapeName: PropTypes.string,
    width: PropTypes.number,
  };

  componentDidMount() {
    const {
      attachEvents,
      divisions,
      height,
      numRandSelected,
      selectedColor,
      selection,
      shapeName,
      width,
    } = this.props;
    const mount = this.mountRef;

    this.shape = shapeFactory(mount);
    this.shape.init({
      attachEvents,
      divisions,
      height,
      onDeselect: this.handleDeselection,
      onSelect: this.handleSelection,
      selectedColor,
      shapeName,
      width,
    });
    this.drawShape();

    if (selection.length > 0) {
      this.setSelection();
    }

    if (numRandSelected > 0) {
      this.setRandomSelection();
    }
  }

  componentWillUnmount() {
    this.shape.destroy();
  }

  drawShape = () => {
    const {shapeName, divisions} = this.props;

    this.shape.setOptions({shapeName, divisions});
    this.shape.drawShape();
  };

  handleDeselection = () => {
    const {onDeselect} = this.props;
    const fn = typeof onDeselect === 'function' ? onDeselect : () => {};

    fn(this.shape.getSelection());
  };

  handleSelection = () => {
    const {onSelect} = this.props;
    const fn = typeof onSelect === 'function' ? onSelect : () => {};

    fn(this.shape.getSelection());
  };

  setSelection = () => {
    const {selection} = this.props;

    this.shape.setSelection(selection);
  };

  setRandomSelection = () => {
    const {numRandSelected} = this.props;

    this.shape.setRandomSelection(+numRandSelected);
  };

  render() {
    const {className} = this.props;

    return (
      <div data-testid="shape-canvas" className={`${className}`} ref={c => (this.mountRef = c)} />
    );
  }
}

export default ShapeCanvas;
