import PropTypes from 'prop-types';
import React from 'react';

import cartesianPlaneFactory from '../lib/cartesian-plane';

class CartesianPlaneCanvas extends React.Component {
  static displayName = 'CartesianPlaneCanvas';
  static defaultProps = {className: 'cartesian-plane-canvas'};

  static propTypes = {
    className: PropTypes.string,
    domain: PropTypes.array,
    range: PropTypes.array,
    points: PropTypes.array,
  };

  state = {
    width: 0,
    height: 0,
  };

  componentDidMount() {
    const mount = this.mountRef;

    window.addEventListener('resize', this.updateCanvasDims);
    this.cartesianPlaneCanvas = cartesianPlaneFactory(mount);
    this.cartesianPlaneCanvas.init();
    this.updateCanvasDims();
  }

  componentDidUpdate(prevProps) {
    const {domain, range, points} = this.props;
    const shouldUpdatePoints =
      prevProps.points.length !== points.length ||
      !points.every((point, i) =>
        Object.keys(point).every(key => point[key] === prevProps.points[i][key]),
      );

    if (domain !== prevProps.domain || range !== prevProps.range) {
      this.updateBounds();
    }

    if (shouldUpdatePoints) {
      this.updatePoints();
    }
  }

  componentWillUnmount() {
    this.cartesianPlaneCanvas.destroy();
    window.removeEventListener('resize', this.updateCanvasDims);
  }

  updateBounds = () => {
    const {domain, range} = this.props;

    this.cartesianPlaneCanvas.setBounds({domain, range});
  };

  updatePoints = () => {
    const {points} = this.props;

    this.cartesianPlaneCanvas.destroyPoints();

    points.map(point => this.cartesianPlaneCanvas.addPoint(point));
  };

  updateCanvasDims = () => {
    const {offsetWidth, offsetHeight} = this.mountRef;

    this.cartesianPlaneCanvas.updateDims({
      width: offsetWidth,
      height: offsetHeight,
    });
    this.updatePoints();
    this.updateBounds();
  };

  render() {
    const {className} = this.props;

    return (
      <div
        className={className}
        data-testid="cartesian-plane-canvas"
        ref={c => (this.mountRef = c)}
      />
    );
  }
}

export default CartesianPlaneCanvas;
