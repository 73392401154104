import Two from '../two-no-conflict';

const cartesianPlaneFactory = mountElem => {
  if (!mountElem || !('nodeType' in mountElem)) {
    throw new Error('no mount element provided');
  }

  const padding = 26;
  const paths = {};
  let options = {
    colors: {primary: '#633d89'},
    domain: [-5, 5],
    range: [-5, 5],
    type: 'svg',
    height: 360,
    width: 360,
  };
  let points = [];
  let two;
  let nextPointId = 0;

  function init(opts = {}) {
    options = {...options, ...opts};

    two = new Two({
      type: Two.Types[options.type],
      width: options.width,
      height: options.height,
    }).bind('resize', handleResize);

    two.appendTo(mountElem);
    setViewBox(options.width, options.height);

    two.renderer.domElement.setAttribute(
      'style',
      `
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      `,
    );
  }

  function getNumGridElems() {
    const {domain, range} = options;
    const numX = Math.max(...domain) - Math.min(...domain);
    const numY = Math.max(...range) - Math.min(...range);
    const max = Math.max(numX, numY);

    return {x: max, y: max};
  }

  function getNumGridLines() {
    const numGridElems = getNumGridElems();

    return {
      x: numGridElems.x - 1,
      y: numGridElems.y - 1,
    };
  }

  function getGridCenterIndexes() {
    const {domain, range} = options;
    const numLines = getNumGridLines();
    const x = 0 - (1 + Math.min.apply(null, domain));
    const y = numLines.y + Math.min.apply(null, range);

    return {x, y};
  }

  function getGridElemDims() {
    const numGridElems = getNumGridElems();
    const {width, height} = two;
    const gridElemWidth = (width - 2 * padding) / numGridElems.x;
    const gridElemHeight = (height - 2 * padding) / numGridElems.y;
    const dim = Math.min(gridElemWidth, gridElemHeight);

    return {
      width: dim,
      height: dim,
    };
  }

  function getTotalLines() {
    const numLines = getNumGridLines();

    return numLines.x + numLines.y;
  }

  function getOffsets() {
    const numLines = getNumGridLines();
    const elemDims = getGridElemDims();
    const gridWidth = (numLines.x + 1) * elemDims.width;
    const gridHeight = (numLines.y + 1) * elemDims.height;
    const unitsUnusedX = (two.width - padding * 2 - gridWidth) / elemDims.width;
    const unitsUnusedY = (two.height - padding * 2 - gridHeight) / elemDims.height;

    return {
      x: padding + (elemDims.width * unitsUnusedX) / 2,
      y: padding + (elemDims.height * unitsUnusedY) / 2,
    };
  }

  function drawBg() {
    if (paths.bg) {
      two.clear();
      delete paths.bg;
    }
    const {width, height} = two;
    const numGridElems = getNumGridElems();
    const gridElemDims = getGridElemDims();

    const bg = two.makeRectangle(
      width / 2,
      height / 2,
      numGridElems.x * gridElemDims.width,
      numGridElems.y * gridElemDims.height,
    );
    bg.noStroke();
    bg.fill = 'rgba(255, 255, 255, .2)';

    paths.bg = bg;
  }

  function drawPlane() {
    drawBg();
    drawGrid();
    drawLabels();
    update();
  }

  function drawGrid() {
    const numLines = getNumGridLines();
    const elemDims = getGridElemDims();
    const totalLines = getTotalLines();
    const centerIndexes = getGridCenterIndexes();
    const offsets = getOffsets();

    paths.lines = Array.apply(null, Array(totalLines)).map((_, i) => {
      const isXLine = i > numLines.y - 1;
      const dimAlpha = isXLine ? 'width' : 'height';
      const dimBeta = isXLine ? 'height' : 'width';
      const axisNumLines = numLines[isXLine ? 'x' : 'y'];
      const centerIndex = centerIndexes[isXLine ? 'x' : 'y'];
      const modIndex = i % axisNumLines;
      const x1 = isXLine ? elemDims[dimAlpha] * (modIndex + 1) + offsets.x : offsets.x;
      const x2 = isXLine ? x1 : two[dimBeta] - x1;
      const y1 = !isXLine ? elemDims[dimAlpha] * (modIndex + 1) + offsets.y : offsets.y;
      const y2 = !isXLine ? y1 : two[dimBeta] - y1;
      const line = two.makeLine(x1, y1, x2, y2);
      line.stroke = options.colors.primary;
      line.opacity = modIndex === centerIndex ? 1 : 0.3;

      return line;
    });
  }

  function drawLabels() {
    const {domain, range} = options;
    const {x: cx, y: cy} = getGridCenterIndexes();
    const numLines = getNumGridLines();
    const elemDims = getGridElemDims();
    const totalLines = getTotalLines() + 4;
    const minDomain = Math.min(...domain);
    const minRange = Math.min(...range);
    const offsets = getOffsets();

    paths.labels = Array.apply(null, Array(totalLines)).map((_, i) => {
      const isXLabel = i > numLines.y + 1;
      const axisNumLabels = numLines[isXLabel ? 'x' : 'y'] + 2;
      const modIndex = i % axisNumLabels;
      const label = (isXLabel ? minDomain : minRange) + modIndex;
      const offsetX = ((modIndex <= cx ? -1 : 1) * elemDims.width) / 4;
      const offsetY = ((numLines.y - modIndex <= cy ? -1 : 1) * elemDims.height) / 4;
      const x = isXLabel
        ? // x-line x-value
          modIndex * elemDims.width + offsetX
        : // y-line x-value
          elemDims.width * (cx + 1) - Math.abs(offsetX);
      const y = !isXLabel
        ? // y-line y-value
          (numLines.y - modIndex + 1) * elemDims.height + offsetY
        : // x-line y-value
          elemDims.height * (cy + 1) + Math.abs(offsetY);
      const text = two.makeText(label || '', x + offsets.x, y + offsets.y);
      text.size = Math.min(elemDims.width, elemDims.height) / 3;
      text.alignment = 'center';
      text.fill = options.colors.primary;

      return text;
    });
  }

  function addPoint({label, x, y}) {
    const center = getGridCenterIndexes();
    const elemDims = getGridElemDims();
    const offsets = getOffsets();
    const xPos = (center.x + x + 1) * elemDims.width + offsets.x;
    const yPos = (center.y - y + 1) * elemDims.height + offsets.y;
    const r = Math.min(elemDims.width, elemDims.height) / 2;

    const pointPath = two.makeCircle(xPos, yPos, r);
    pointPath.fill = options.colors.primary;
    pointPath.noStroke();

    const labelPath = two.makeText(label || '', xPos, yPos);
    labelPath.fill = 'white';
    labelPath.size = r;

    points = points.concat({
      id: nextPointId++,
      label,
      labelPath,
      pointPath,
      x,
      y,
    });
    two.update();
  }

  function getPoints() {
    return points;
  }

  function destroyPointById(id) {
    points = points
      .map(point => {
        if (point.id === id) destroyPointPaths(point);

        return point;
      })
      .filter(point => point.id !== id);
  }

  function destroyPointPaths(pointObj) {
    two.remove(pointObj.pointPath, pointObj.labelPath);

    return delete pointObj.pointPath && delete pointObj.labelPath;
  }

  function destroyPoints() {
    points = points.map(point => destroyPointPaths(point)).filter(val => !val);
  }

  function update() {
    two.update();
  }

  function setBounds({domain, range}) {
    const oldPointDetails = points.map(({label, x, y}) => ({label, x, y}));
    options = {...options, domain, range};
    destroyPlane();
    destroyPoints();
    drawPlane();

    oldPointDetails.map(addPoint);
  }

  function setViewBox(width, height) {
    two.renderer.domElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
  }

  function updateDims({height, width}) {
    two.width = +width;
    two.height = +height;
    two.trigger('resize');
  }

  function destroyPlane() {
    ['lines', 'labels'].map(type => {
      two.remove(paths[type]);
      return delete paths[type];
    });
  }

  function destroyPaths() {
    Object.keys(paths).map(key => {
      two.remove(paths[key]);
      return delete paths[key];
    });
  }

  function destroy() {
    destroyPaths();
    destroyPoints();
    two.clear();
  }

  function handleResize() {
    setViewBox(two.width, two.height);
    destroyPaths();
    drawBg();
    drawPlane();
  }

  return {
    addPoint,
    destroyPointById,
    destroyPoints,
    destroy,
    init,
    getPoints,
    setBounds,
    updateDims,
  };
};

export default cartesianPlaneFactory;
