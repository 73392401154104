import {COLORS} from './constants';

const PI = Math.PI;

const reflectionBgFactory = twoInstance => {
  const groupNames = ['bgGroup', 'rotationGroup'];
  const groups = {};
  let currentAngle;

  function init() {
    groupNames.map(n => initGroup(n));

    draw(twoInstance.width, twoInstance.height);
    setAngle(0);
  }

  function initGroup(name) {
    groups[name] = twoInstance.makeGroup();
  }

  function draw() {
    groupNames.map(n => {
      removeBg(n);
      return drawGroupBg(n);
    });
  }

  function isRotationGroup(groupName) {
    return groupName === 'rotationGroup';
  }

  function getBgWidth() {
    const {width, height} = twoInstance;

    return Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
  }

  function getBg(name) {
    const isRotation = isRotationGroup(name);
    const bgWidth = getBgWidth();
    const fillColor = COLORS[isRotation ? 'SECONDARY' : 'PRIMARY'];
    const {x, y} = {
      x: isRotation ? 0 : bgWidth / 2,
      y: isRotation ? bgWidth / 2 : 0,
    };
    const bg = twoInstance.makeRectangle(x, y, bgWidth, bgWidth);
    bg.noStroke();
    bg.fill = fillColor;

    return bg;
  }

  function drawGroupBg(name) {
    const {width, height} = twoInstance;
    const isRotation = isRotationGroup(name);
    const bg = getBg(name);
    const group = groups[name];

    group.translation.set(0, height / 2);

    group.bg = bg;
    bg.addTo(group);

    if (isRotation) {
      group.translation.set(width / 2, height / 2);
      group.rotation = currentAngle;
    }
  }

  function removeBg(name) {
    const group = groups[name];

    if (group && group.bg) {
      group.remove(group.bg);
      delete group.bg;
    }
  }

  function removeGroups() {
    groupNames.map(n => {
      twoInstance.remove(groups[n], groups[n].bg);

      return delete groups[n].bg;
    });
  }

  function setAngle(angleInRad) {
    if (currentAngle !== angleInRad) {
      currentAngle = angleInRad;

      animateBg();

      if (groups.rotationGroup.rotation !== angleInRad) {
        twoInstance.bind('update', animateBg);
      }
    }
  }

  function animateBg() {
    const {rotationGroup} = groups;

    if (rotationGroup.rotation < currentAngle + PI * 2) {
      rotationGroup.rotation = rotationGroup.rotation + 0.1;
    } else {
      rotationGroup.rotation = currentAngle;
      twoInstance.unbind('update', animateBg);
    }
  }

  function destroy() {
    removeGroups();
    twoInstance.unbind('update', animateBg);
  }

  init();

  return {
    destroy,
    draw,
    setAngle,
  };
};

export default reflectionBgFactory;
