import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';
import {SubmissionError} from 'redux-form';
import pick from 'lodash/fp/pick';

import {UserManagementContainer} from '@edu-game/assets';

import SignUpForm from '../forms/SignUp';
import LinksFooter from '../LinksFooter';
import PageTitle from '../PageTitle';

import createApp, {storage} from '../../feathers';
import routes from '../../routes';

const {location} = global;

const {jwt} = global.__appSettings;

const app = createApp({authentication: {storageKey: jwt.userStorageKey}});
const registerService = app.service('register');

class SignUpPage extends React.Component {
  static displayName = 'SignUpPage';

  static propTypes = {
    route: PropTypes.object.isRequired,
  };

  handleSubmit = values => {
    const params = pick(['email', 'password'], values);
    return registerService
      .create(params)
      .then(() => {
        storage.setShouldPersist(true);
        return app
          .authenticate({
            strategy: 'user-local',
            email: params.email,
            password: params.password,
          })
          .then(({user}) => {
            location.href = routes.redirectForUser(user);
          });
      })
      .catch(err => {
        throw new SubmissionError({email: err});
      });
  };

  render() {
    const {history} = this.props;

    return (
      <UserManagementContainer showBack history={history}>
        <PageTitle>Sign up</PageTitle>

        <div className="push--smaller--bottom">
          <SignUpForm onSubmit={this.handleSubmit} />
        </div>

        <LinksFooter>
          Already have an account? &nbsp;
          <Link className="fc--ntrl-min" to={routes.login}>
            Log in
          </Link>
        </LinksFooter>
      </UserManagementContainer>
    );
  }
}

export default SignUpPage;
