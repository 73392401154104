import PropTypes from 'prop-types';
import React from 'react';
import CircleInner from './CircleInner';

class Button extends React.Component {
  static displayName = 'Button';

  static defaultProps = {
    'data-testid': 'button',
    baseClassName: 'btn',
    className: '',
    type: 'button',
  };

  static propTypes = {
    baseClassName: PropTypes.string,
    className: PropTypes.string,
    'data-testid': PropTypes.string,
    disabled: PropTypes.bool,
    modifiers: PropTypes.array,
    onClick: PropTypes.func,
    children: PropTypes.node,
    type: PropTypes.string,
    iconId: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
  };

  getClassName = () => {
    const {baseClassName, className, modifiers} = this.props;

    return modifiers && modifiers.length
      ? modifiers.reduce(
          (acc, mod) => `${acc} ${baseClassName} ${baseClassName}--${mod}`,
          className,
        )
      : className || `${baseClassName}--purple-alpha`;
  };

  handleClick = e => {
    const {disabled, onClick} = this.props;

    if (!disabled && onClick) onClick(e);
  };

  renderInner = () => {
    const {children, iconId} = this.props;

    if (iconId) {
      return (
        <CircleInner isButtonChild iconId={iconId}>
          {children}
        </CircleInner>
      );
    }

    return children;
  };

  render() {
    const {disabled, style, type, title} = this.props;
    const className = this.getClassName();

    return (
      <button
        className={className}
        data-testid={this.props['data-testid']}
        disabled={Boolean(disabled)}
        onClick={this.handleClick}
        ref={c => (this.buttonElem = c)}
        style={style}
        title={title}
        type={type}
      >
        {this.renderInner()}
      </button>
    );
  }
}

export default Button;
