const daysOfTheWeek = [
  {
    name: 'Sunday',
    abbreviation: 'S',
  },
  {
    name: 'Monday',
    abbreviation: 'M',
  },
  {
    name: 'Tuesday',
    abbreviation: 'T',
  },
  {
    name: 'Wednesday',
    abbreviation: 'W',
  },
  {
    name: 'Thursday',
    abbreviation: 'T',
  },
  {
    name: 'Friday',
    abbreviation: 'F',
  },
  {
    name: 'Saturday',
    abbreviation: 'S',
  },
];

export default daysOfTheWeek;
