import React from 'react';

import descriptions from './shared/descriptions';

export default {
  key: 'multiple-choice-image',
  name: 'Multiple Choice With Image',
  description: <div>{descriptions.answerBoundAnimations}</div>,
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'multiple-choice-image',
  },

  vars: {
    text: {type: 'text', label: 'Text'},
    imgUrl: {
      type: 'image',
      label: 'Image',
      metadata: {category: 'question'},
    },
    choices: {
      type: 'multiple-choice',
      label: 'Choices',
      max: 4,
    },
  },
};
