import React from 'react';

import descriptions from './shared/descriptions';

export default {
  key: 'cloze',
  name: 'Cloze',
  description: <div>{descriptions.answerBoundAnimations}</div>,
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'cloze',
  },

  vars: {
    text: {
      type: 'text',
      label: 'Text',
      help: `Add a sentence with the correct placeholder words in curly braces.
      e.g. The {quick} brown {fox} jumps over the {lazy} {dog}.`,
    },

    fakeChoices: {
      type: 'list',
      label: 'Fake Choices',
      help: 'Add other words that will be listed excluding the correct words.',
      optional: true,
    },
  },
};
