import PropTypes from 'prop-types';
import React from 'react';

class DropdownMenu extends React.Component {
  static displayName = 'DropdownMenu';
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    items: PropTypes.array,
  };

  static defaultProps = {
    className: '',
    items: [],
  };

  render() {
    const {className, children, isOpen, items} = this.props;

    return (
      <div className={`dropdown-menu-container ${className}`}>
        {children}

        <ul className={`dropdown-menu ${isOpen ? 'is-open' : ''}`}>
          {items.map((item, i) => (
            <li key={i} className="dropdown-menu__item">
              {item}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default DropdownMenu;
