import PropTypes from 'prop-types';
import React from 'react';
import ChartistGraph from 'react-chartist';

import Chartist, {ctAxisTitle} from '../lib/chartist-node-safe';

const BAR_HEIGHT = 44;
const COLORS = ['#8cd0e0', '#bcdae6', '#5d94b4', '#93bedb', '#375f77'];

const defaults = {
  horizontalBars: true,
  chartPadding: {
    bottom: 25,
    left: 20,
    right: 20,
    top: 15,
  },
  axisX: {
    offset: 30,
  },
  axisY: {
    offset: 0,
    showLabel: false,
  },
  classNames: {
    chart: 'ct-chart-bar bar-graph-game__chart',
  },
};

class ChartBarChallenge extends React.Component {
  static displayName = 'ChartBarChallenge';

  static defaultProps = {axisTitles: {}};

  static propTypes = {
    axisTitles: PropTypes.shape({
      xTitle: PropTypes.string,
      yTitle: PropTypes.string,
    }),
    rawData: PropTypes.array.isRequired,
  };

  componentWillUpdate() {
    this.chart.chartist.detach();
  }

  componentDidUpdate() {
    this.chart.chartist.update();
  }

  shouldComponentUpdate({rawData}) {
    const {rawData: pRawData} = this.props;

    return (
      rawData.length !== pRawData.length ||
      !rawData.every(
        ({value, label}, i) => value === pRawData[i].value && label === pRawData[i].label,
      )
    );
  }

  getChartData = () => {
    const {rawData} = this.props;
    const labels = rawData.reduce((acc, nextObj) => acc.concat(nextObj.label), []);
    const seriesData = rawData.reduce((acc, nextObj) => acc.concat({value: nextObj.value}), []);

    return {
      labels,
      series: [
        {
          className: 'bar-graph-game-series',
          data: seriesData,
        },
      ],
    };
  };

  getChartOptions = () => {
    const {axisTitles, rawData} = this.props;
    const {xTitle, yTitle} = axisTitles;
    const series = rawData.map(obj => obj.value);
    const chartHeight = `
      ${BAR_HEIGHT * rawData.length +
        defaults.chartPadding.top +
        defaults.chartPadding.bottom +
        defaults.axisX.offset}px`;

    return {
      ...defaults,
      axisX: {
        ...defaults.axisX,
        onlyInteger: series.every(
          val =>
            Number(val)
              .toString()
              .indexOf('.') === -1,
        ),
      },
      height: chartHeight,
      width: '100%',

      plugins: [
        ctAxisTitle({
          axisX: {
            axisClass: 'ct-axis-title',
            axisTitle: xTitle,
            offset: {
              x: 0,
              y: defaults.chartPadding.bottom + defaults.chartPadding.top,
            },
            textAnchor: 'middle',
          },

          axisY: {
            axisClass: 'ct-axis-title',
            axisTitle: yTitle,
            flipTitle: true,
            offset: {
              x: 0,
              y: defaults.chartPadding.left / 2,
            },
            textAnchor: 'middle',
          },
        }),
      ],
    };
  };

  handleDraw = data => {
    if (data.type === 'bar') {
      const x2Animation = {
        begin: 150 * data.index,
        dur: 1000,
        easing: Chartist.Svg.Easing.easeOutBack,
        from: data.x1,
        to: data.x2,
      };
      const labelPos = {
        x: data.x1 + defaults.chartPadding.left,
        y: data.y2 + 6,
      };
      const labelText = data.axisY.options.ticks[data.index];

      data.element.animate({x2: x2Animation});
      data.element._node.setAttribute('stroke', COLORS[data.index % COLORS.length]);
      data.element._node.style.strokeWidth = `${data.axisY.stepLength}px`;

      data.element
        .root()
        .elem('text', labelPos, 'ct-bar-label')
        .text(labelText);
    }
  };

  render() {
    const data = this.getChartData();
    const options = this.getChartOptions();

    return (
      <ChartistGraph
        data={data}
        listener={{draw: this.handleDraw}}
        options={options}
        ref={c => (this.chart = c)}
        type="Bar"
      />
    );
  }
}

export default ChartBarChallenge;
