import React from 'react';

export default {
  key: 'word-search',
  name: 'Word Search',
  description: (
    <div>
      <hr />

      <h2>Word Search Layout</h2>

      <p>
        This template calculates the dimensions of the grid by finding the most
        square layout given the number of characters provided.
      </p>

      <p>
        It will always generate a rectangle, but the number of columns and rows
        that make up the grid are ultimately determined by the total number of
        characters provided.
      </p>

      <p>
        For the best layout, the number of characters in your words, and the
        fake characters, should be as close to a square number as possible.
      </p>

      <p>
        e.g. for a challenge with one word that is 5 characters long, you should
        aim for a total of 9, 16, or 25 characters in total once you add fake
        characters. This will make the most square word search.
      </p>

      <p>
        The word search favours a portrait layout, so the longest side of the
        grid will always be in a vertical direction.
      </p>

      <hr />

      <h2>Words In Sequence</h2>

      <p>
        Usage: <code>&#123;bear&#125;</code>
        <br /> Will output <em>bear</em> in sequence
      </p>

      <p>
        Words that need to be output in sequence are dependent on the layout of
        the grid. If there is not enough space on the grid for a word to be
        output in sequence, the word search will fall back to outputting
        characters at random locations.
      </p>

      <p>
        For challenges with multiple words, the odds of each subsequent word
        being output in sequence decrease, since each word has to compete with
        previously output words for space. This is because as a word is added to
        the grid, so space for new words is diminished.
      </p>

      <p>
        If there is no space for a new word to be output in sequence, that word
        will fall back to being output in random locations in the grid.
      </p>

      <h2>Multiple characters per tile</h2>

      <p>
        Usage: <code>&#123;b[ea]r&#125;</code>
        <br /> Will output <em>bear</em> in sequence with 3 tiles:
      </p>

      <ul>
        <li>b</li>
        <li>ea</li>
        <li>r</li>
      </ul>

      <p>
        Space is limited on tiles, so keeping grouped characters to a maximum of
        2 is a good idea.
      </p>

      <p>Characters can be grouped both for words and fake characters.</p>
    </div>
  ),

  resources: {
    thumbnailUrl: '',
  },

  vars: {
    text: {type: 'text', label: 'Question / Hint'},
    words: {
      type: 'list',
      label: 'Words',
      help: `
        Add words for users to find. Words inside {braces} will be placed in a
        sequence where possible. Add grouped characters in [parens].
      `,
    },
    fakeChars: {
      type: 'text',
      label: 'Fake Characters',
      help: `
        You can safely use letters here that are also in the the answer words. Add grouped characters in [parens].
      `,
    },
  },
};
