import {getPolygonDivisions, LINEWIDTH, setDivisionDefaults, setShapeDefaults} from './utils';

export default function polygonFactory({twoInstance, center, numSides, numDivisions}) {
  const shapeObj = {
    group: twoInstance.makeGroup(),
  };

  function init() {
    initShape();
    initDivisions();
  }

  function initShape() {
    shapeObj.shape = twoInstance.makePolygon(
      center.x,
      center.y,
      twoInstance.width / 2 - LINEWIDTH / 2,
      numSides,
    );
    setShapeDefaults(shapeObj.shape);
    shapeObj.group.add(shapeObj.shape);
  }

  function initDivisions() {
    const canDivide = numSides % numDivisions === 0;

    if (!canDivide && numSides !== 3 && process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error('must provide numDivisions which is divisible by numSides');

      return [];
    }

    shapeObj.divisions = numSides === 3 && numDivisions === 2 ? divideInHalves() : divideRegular();

    return shapeObj.divisions.map(div => shapeObj.group.add(div));
  }

  function divideInHalves() {
    const dims = {
      width: twoInstance.width,
      height: twoInstance.height,
    };
    const vertices = shapeObj.shape.vertices;
    const baseVertexIndex = 1;
    const baseVertex = vertices[baseVertexIndex];
    const remainingVertexIndexes = [
      (baseVertexIndex + 1) % vertices.length,
      (baseVertexIndex - 1) % vertices.length,
    ];

    return remainingVertexIndexes.map(vertIndex => {
      const halfway = {
        x: (vertices[(baseVertexIndex - 1) % 3].x + vertices[(baseVertexIndex + 1) % 3].x) / 2,
        y: (vertices[(baseVertexIndex - 1) % 3].y + vertices[(baseVertexIndex + 1) % 3].y) / 2,
      };

      const division = twoInstance.makePath(
        baseVertex.x,
        baseVertex.y,
        halfway.x,
        halfway.y,
        vertices[vertIndex].x,
        vertices[vertIndex].y,
      );

      division.translation.set(
        dims.width / 2 + vertices[vertIndex].x / 2,
        dims.height / 2 - vertices[vertIndex].y / 2,
      );
      setDivisionDefaults(division);

      return division;
    });
  }

  function divideRegular() {
    const dims = {
      width: twoInstance.width,
      height: twoInstance.width,
      ox: center.x,
      oy: center.y,
    };

    return getPolygonDivisions(twoInstance, dims, shapeObj.shape);
  }

  init();

  return shapeObj;
}
