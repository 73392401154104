import PropTypes from 'prop-types';
import React from 'react';
import {Field, reduxForm as connectForm} from 'redux-form';

import {combineValidators} from 'redux-form-revalid';

import {Button, InputField, InputFieldMessage, Loader} from '@edu-game/assets';
import {validateRequired, validatePassword} from '@edu-game/assets/src/js/validations';

class PlayLoginForm extends React.Component {
  static propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
  };

  render() {
    const {handleSubmit, submitting, error, isLoading} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="username"
          component={InputField}
          type="text"
          label="Username"
          disabled={isLoading}
          withIcon
          className="field--trans"
        />

        <Field
          name="password"
          component={InputField}
          type="password"
          label="Password"
          disabled={isLoading}
          withIcon
          className="field--trans"
        />

        <Field
          className="field--trans"
          name="remember"
          component={InputField}
          disabled={isLoading}
          type="checkbox"
          label="Remember me"
        />

        {error ? <InputFieldMessage tagName="p">{error.message || error}</InputFieldMessage> : null}

        <Button type="submit" modifiers={['red-alpha-lt', 'full']}>
          {submitting || isLoading ? (
            <span>
              <Loader className="loader--spin" numIcons={1} /> Signing in...
            </span>
          ) : (
            'Sign in'
          )}
        </Button>
      </form>
    );
  }
}

export default connectForm({
  form: 'LoginForm',
  validate: combineValidators({
    username: validateRequired({name: 'Username'}),
    password: validatePassword,
  }),
})(PlayLoginForm);
