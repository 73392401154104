import PropTypes from 'prop-types';
import React from 'react';

class ListInput extends React.Component {
  static displayName = 'ListInput';

  static propTypes = {onSubmit: PropTypes.func.isRequired};

  handleKeyDown = e => {
    if (e.which === 13) {
      const {onSubmit} = this.props;
      const {target} = e;

      e.preventDefault();

      onSubmit(target.value);
      target.value = '';
      target.focus();
    }
  };

  render() {
    return (
      <input
        onKeyDown={this.handleKeyDown}
        placeholder="Type then press enter"
        ref={c => (this.input = c)}
        {...this.props}
      />
    );
  }
}

export default ListInput;
