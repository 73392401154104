const physicsJSNonRotatingCircle = Physics =>
  Physics.body
    ? Physics.body('non-rotating-circle', 'circle', parent => ({
        init(opts) {
          parent.init.call(this, opts);

          Object.defineProperty(this.state.angular, 'pos', {
            get() {
              return 0;
            },
            set() {},
          });
        },
      }))
    : null;

export default physicsJSNonRotatingCircle;
