import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';
import {SubmissionError} from 'redux-form';
import {UserManagementContainer} from '@edu-game/assets';

import ResetPasswordForm from '../forms/ResetPassword';
import LinksFooter from '../LinksFooter';
import PageTitle from '../PageTitle';

import createApp from '../../feathers';
import routes from '../../routes';

const app = createApp();

class ResetPasswordPage extends React.Component {
  static displayName = 'ResetPasswordPage';

  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {};

  handleSubmit = values => {
    const {token} = this.props.routeParams;
    return app
      .service('/passwords')
      .update(token, {
        password: values.password,
      })
      .then(() => {
        this.setState({success: true});
      })
      .catch(err => {
        throw new SubmissionError({
          _error: {
            message:
              err.message === 'Invalid token'
                ? 'This token is invalid or has expired.'
                : err.message,
          },
        });
      });
  };

  render() {
    const {history} = this.props;
    const {success} = this.state;

    return (
      <UserManagementContainer showBack history={history}>
        <PageTitle>Reset password</PageTitle>

        {success ? (
          <div className="alert--success">
            Your password has been changed. Please &nbsp;
            <Link className="fc--ntrl-min" to={routes.login}>
              Log in
            </Link>
          </div>
        ) : (
          <div>
            <div className="push--small--bottom">
              <ResetPasswordForm onSubmit={this.handleSubmit} />
            </div>

            <LinksFooter />
          </div>
        )}
      </UserManagementContainer>
    );
  }
}

export default ResetPasswordPage;
