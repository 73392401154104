import React from 'react';

import descriptions from './shared/descriptions';

export default {
  key: 'custom-keyboard',
  name: 'Custom Keyboard',
  description: <div>{descriptions.answerBoundAnimations}</div>,
  resources: {
    thumbnailUrl: '',
  },
  vars: {
    text: {type: 'text', label: 'Text'},
    answer: {type: 'string', label: 'Answer'},
    fakeChars: {type: 'string', label: 'Fake Characters'},
    hintLength: {type: 'number', label: 'Hint Length', defaultValue: '0'},
  },
};
