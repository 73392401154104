const categories = [
  {
    name: 'counting-number-sequences',
    title: 'Counting & number sequences',
    subject: 'maths',
  },
  {
    name: 'place-value-ordering',
    title: 'Place value & ordering',
    subject: 'maths',
  },
  {
    name: 'estimating-rounding',
    title: 'Estimating & rounding',
    subject: 'maths',
  },
  {name: 'fractions', title: 'Fractions', subject: 'maths'},
  {name: 'calculations', title: 'Calculations', subject: 'maths'},
  {name: 'money', title: 'Money', subject: 'maths'},
  {name: 'shapes-space', title: 'Shapes & Space', subject: 'maths'},
  {name: 'problem-solving', title: 'Problem solving', subject: 'maths'},
  {name: 'time', title: 'Time', subject: 'maths'},
  {name: 'graphs', title: 'Graphs', subject: 'maths'},

  {name: 'vocabulary', title: 'Vocabulary', subject: 'english'},
  {name: 'spelling', title: 'Spelling', subject: 'english'},
  {name: 'comprehension', title: 'Comprehension', subject: 'english'},
  {name: 'punctuation', title: 'Punctuation', subject: 'english'},
  {name: 'grammar', title: 'Grammar', subject: 'english'},

  {name: 'number-place-value', title: 'Number & place value', subject: 'maths'},
  {name: 'calculations', title: 'Calculations', subject: 'maths'},
  {
    name: 'fractions-decimal-percentages',
    title: 'Fractions, decimal & percentages',
    subject: 'maths',
  },
  {name: 'ratio-proportion', title: 'Ratio & proportion', subject: 'maths'},
  {name: 'algebra', title: 'Algebra', subject: 'maths'},
  {name: 'measurement', title: 'Measurement', subject: 'maths'},
  {name: 'geometry-properties of shape', title: 'Geometry - properties of shape', subject: 'maths'},
  {
    name: 'geometry-position & direction',
    title: 'Geometry - position & direction',
    subject: 'maths',
  },
  {name: 'statistics', title: 'Statistics', subject: 'maths'},

  {name: 'other', title: 'Other'},
];

categories.forSelect = () =>
  categories.reduce(
    (obj, c) =>
      Object.assign(obj, {
        [c.subject || 'Other']: Object.assign(obj[c.subject || 'Other'] || {}, {
          [c.name]: c.title,
        }),
      }),
    {},
  );

export default categories;
