import React from 'react';

import descriptions from './shared/descriptions';

export default {
  key: 'jumbled-words',
  name: 'Jumbled Words',
  description: <div>{descriptions.answerBoundAnimations}</div>,
  resources: {
    thumbnailUrl: '',
  },
  vars: {
    text: {type: 'text', label: 'Text'},
    words: {
      type: 'text',
      label: 'Words',
      help: 'Type the correct sentence. The words will be automatically jumbled.',
    },
  },
};
