import React from 'react';
import {Link} from 'react-router';

import {UserManagementContainer} from '@edu-game/assets';

import ForgottenPasswordForm from '../forms/ForgottenPassword';
import LinksFooter from '../LinksFooter';
import PageTitle from '../PageTitle';

import createApp from '../../feathers';
import routes from '../../routes';

const app = createApp();

class ForgottenPasswordPage extends React.Component {
  static displayName = 'ForgottenPasswordPage';
  state = {};

  handleSubmit = values => {
    return app
      .service('/passwords')
      .create({
        email: values.email,
      })
      .then(() => {
        this.setState({success: true});
      });
  };

  render() {
    const {history} = this.props;
    const {success} = this.state;

    return (
      <UserManagementContainer showBack history={history}>
        <PageTitle>Forgotten password</PageTitle>

        {success ? (
          <div className="alert--success">
            If your email address exists in our system, we've sent you further instructions.
            <Link className="fc--ntrl-min" to={routes.login}>
              {' '}
              Log in
            </Link>
          </div>
        ) : (
          <div>
            <div className="push--small--bottom">
              <ForgottenPasswordForm onSubmit={this.handleSubmit} />
            </div>

            <LinksFooter />
          </div>
        )}
      </UserManagementContainer>
    );
  }
}

export default ForgottenPasswordPage;
