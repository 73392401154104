import Two from '../two-no-conflict';

const animatedStrokeFactory = mountElem => {
  if (!mountElem || !('nodeType' in mountElem)) {
    throw new Error('no mount element provided');
  }

  const paths = {};
  let options = {
    colorStops: [
      {
        color: '#7cceb1',
        offset: 0,
      },
      {
        color: '#faef8e',
        offset: 1,
      },
    ],
    height: 280,
    width: 280,
    type: 'svg',
    // onAnimEnd :: twoInstance -> null
    onAnimEnd: null,
    // onProgress :: Number -> null
    onProgress: null,
    linewidth: 12,
    radius: 20,
    // shapeCreator :: TwoInstance -> Object -> Two.Path
    shapeCreator: (twoInstance, opts) => {
      const {width, height} = twoInstance;

      return twoInstance.makeRoundedRectangle(
        width / 2,
        height / 2,
        width - opts.linewidth,
        height - opts.linewidth,
        opts.radius,
      );
    },
    // lengthCalculator :: Two.Path -> Number
    lengthCalculator: path => path.length,
  };
  let currProgress = 0;
  let duration = 10 * 1000;
  let timeElapsed = 0;
  let pathLength;
  let isPlaying;
  let timeAtPause;
  let timeAtLastTick;
  let two;

  function init(opts) {
    options = {...options, ...opts};
    two = new Two({
      height: options.height,
      type: Two.Types[options.type],
      width: options.width,
    })
      .bind('resize', handleResize)
      .play();

    two.appendTo(mountElem);
    setViewBox(options.width, options.height);

    drawPaths();

    two.renderer.domElement.setAttribute(
      'style',
      `
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      `,
    );
  }

  function setViewBox(width, height) {
    two.renderer.domElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
  }

  function drawPaths() {
    drawBg();
    drawFg();
  }

  function getBaseShape() {
    const shape = options.shapeCreator(two, options);
    shape.noFill();
    shape.linewidth = options.linewidth;

    return shape;
  }

  function drawBg() {
    const bg = getBaseShape();
    bg.stroke = '#fff';
    bg.opacity = 0.4;

    paths.bg = bg;
  }

  function drawFg() {
    const {height, width} = two;
    const fg = getBaseShape();
    const gradientStops = options.colorStops.map(
      ({color, offset, opacity = 1}) => new Two.Stop(offset, color, opacity),
    );
    const gradient = two.makeLinearGradient(
      width / 2,
      -height / 2,
      width / 2,
      height / 2,
      ...gradientStops,
    );

    two.update();
    const pathNode = fg._renderer.elem;

    fg.stroke = gradient;
    pathLength = options.lengthCalculator(fg);

    pathNode.setAttribute('stroke-dasharray', pathLength);
    pathNode.setAttribute('stroke-dashoffset', pathLength);

    paths.fg = fg;
  }

  function animateStroke() {
    const timeDelta = performance.now() - timeAtLastTick;

    if (timeAtPause) {
      timeAtPause = undefined;
    }

    if (timeAtLastTick) {
      setTimeElapsed(timeElapsed + timeDelta);
    }

    timeAtLastTick = performance.now();

    if (timeElapsed + timeDelta > duration) {
      stop();
      setTimeElapsed(duration);

      if (options.onAnimEnd) {
        options.onAnimEnd(two);
      }
    }
  }

  function handleResize() {
    setViewBox(two.width, two.height);
    reset();
  }

  function reset() {
    /**
     * Only play again if the animation is actually playing
     */
    const resetSequence = [stop, destroyPaths, drawPaths, isPlaying ? play : undefined].filter(
      Boolean,
    );

    resetSequence.map(fn => fn());
  }

  function getIsPlaying() {
    return isPlaying;
  }

  function setOptions(opts) {
    options = {...options, ...opts};
    reset();
  }

  function setDuration(dur) {
    duration = dur;
  }

  function setTimeElapsed(elapsed) {
    timeElapsed = elapsed;

    setProgress(elapsed / duration);
  }

  function setProgress(progress) {
    const {fg} = paths;
    const node = fg._renderer.elem;
    const shouldUpdateOffset = progress <= 1 && progress >= 0;
    const offset = pathLength - pathLength * progress;

    currProgress = shouldUpdateOffset ? progress : currProgress;

    if (node && shouldUpdateOffset) {
      node.setAttribute('stroke-dashoffset', offset);

      if (options.onProgress) {
        options.onProgress(currProgress);
      }
    }
  }

  function play() {
    timeAtLastTick = performance.now();
    isPlaying = true;
    bindUpdateListeners();
  }

  function pause() {
    timeAtPause = performance.now();
    isPlaying = false;
    unbindUpdateListeners();
  }

  function stop() {
    unbindUpdateListeners();
    setTimeElapsed(0);
    isPlaying = false;
    timeAtPause = undefined;
    timeAtLastTick = undefined;
  }

  /*
   * Allow the canvas dimensions to be updated
   * Queue resize using requestAnimationFrame to prevent fast updates from
   * overriding each other mid-update
   *
   * @param dimensions {object} - dimensions to update canvas to
   * @param dimensions.width {number|string} - width to update canvas to
   * @param dimensions.height {number|string} - height to update canvas to
   * @return undefined
   */
  function updateDims({height, width}) {
    if (width) {
      two.width = parseInt(width, 10);
    }

    if (height) {
      two.height = parseInt(height, 10);
    }
    requestAnimationFrame(() => {
      two.trigger('resize');
    });
  }

  function bindUpdateListeners() {
    const isBound = two._events.update && two._events.update.indexOf(animateStroke) > -1;

    if (!isBound) {
      two.bind('update', animateStroke);
    }
  }

  function unbindUpdateListeners() {
    if (two._events.update) {
      two._events.update.map(fn => two.unbind('update', fn));
    }
  }

  function unbindEvents() {
    Object.keys(two._events).map(key => two._events[key].map(fn => two.unbind(key, fn)));
  }

  function destroyPaths() {
    two.clear();
    Object.keys(paths).map(key => delete paths[key]);
  }

  function destroy() {
    destroyPaths();
    unbindEvents();

    return true;
  }

  return {
    destroy,
    getIsPlaying,
    init,
    pause,
    play,
    reset,
    setDuration,
    setTimeElapsed,
    setOptions,
    stop,
    updateDims,
  };
};

export default animatedStrokeFactory;
