import React from 'react';

import descriptions from './shared/descriptions';

export default {
  key: 'swipe-sort',
  name: 'Swipe Sort',
  description: <div>{descriptions.categoryList}</div>,
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'swipe-sort',
  },

  vars: {
    categories: {
      type: 'category-list',
      help: 'manage swipe categories here',
      label: 'Categories',
      name: 'swipe-sort-category-list',
      maxLists: 2,
    },

    timeLimit: {
      type: 'slider',
      label: 'Time Limit',
      help: 'Time, in seconds, required to swipe all cards in this question.',
      name: 'swipe-sort-time-limit',
      min: 1,
      max: 600,
      step: 1,
      defaultValue: 30,
    },
  },
};
