import React from 'react';

import {FractionText} from '../fraction-text';
import {formatters as formatterTypes} from '../../enums/text-formatters';

// convert strings with *asterisks* to replace with bold text, similar to Markdown
export const BoldText = props => {
  const safeText = props.text || '';
  const regex = /\*.*?\*/g;
  const matches = (safeText.match(regex) || []).map(m => ({
    index: safeText.indexOf(m),
    text: m,
    formatters: [formatterTypes.bold],
  }));
  const nonMatches = safeText
    .split(regex)
    .map(m => ({index: safeText.indexOf(m), text: m, formatters: []}));
  const parts = matches.concat(nonMatches).sort((a, b) => (a.index > b.index ? 1 : -1));

  return (
    <span>
      {parts.map(({formatters, text}, i) => (
        <span key={i}>
          {formatters.indexOf(formatterTypes.bold) > -1 ? (
            <strong>
              <FractionText text={text.replace(/\*/g, '')} />
            </strong>
          ) : (
            <FractionText text={text} />
          )}
        </span>
      ))}
    </span>
  );
};

export default {BoldText};
