import React from 'react';

const PageTitle = ({children, className, ...rest}) => (
  <div className="text--center">
    <h2 className={className} {...rest}>
      {children}
    </h2>
  </div>
);

PageTitle.defaultProps = {
  className: 'fw--bold',
};

export default PageTitle;
