export default {
  key: 'numberline',
  name: 'Numberline',
  description: '',
  thumbnailUrl: '',
  resources: {},
  vars: {
    text: {type: 'text', label: 'Text'},

    unit: {
      type: 'string',
      label: 'Unit',
      placeholder: 'e.g. cm or mm',
      optional: true,
    },

    labelIncrement: {
      type: 'number',
      label: 'Label Increment',
      help: 'The value to increment each label on the numberline',
      defaultValue: 1,
    },

    numTicksBetweenLabels: {
      type: 'number',
      label: 'No. Marks Between Labels',
      help: 'e.g. on a ruler between 0cm and 1cm there are 9 marks',
      defaultValue: 0,
      optional: true,
    },

    min: {
      type: 'number',
      label: 'Minimum Value',
      defaultValue: 0,
    },

    max: {
      type: 'number',
      label: 'Maximum Value',
      defaultValue: 10,
    },

    answer: {
      type: 'number',
      label: 'Answer',
    },
  },
};
