import PropTypes from 'prop-types';
import React from 'react';
import {Field, reduxForm as connectForm} from 'redux-form';

import {combineValidators} from 'redux-form-revalid';

import isEmail from 'redux-form-revalid/validations/isEmail';
import {minLength} from 'revalid';
import {Button, InputField, InputFieldMessage, Loader} from '@edu-game/assets';

export const SignUpForm = ({handleSubmit, submitting, error}) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="email"
      msgClassName="fc--red-alpha-ltst"
      component={InputField}
      type="email"
      label="Email"
      className="field--trans"
      withIcon
    />

    <Field
      name="password"
      msgClassName="fc--red-alpha-ltst"
      component={InputField}
      type="password"
      label="Password"
      className="field--trans"
      withIcon
    />

    {error && error.message ? (
      <InputFieldMessage className="fc--red-alpha-ltst" tagName="p">
        {error.message}
      </InputFieldMessage>
    ) : null}

    <Button
      className="push--smaller--bottom"
      modifiers={['purple-alpha', 'full']}
      type="submit">
      Create Account &nbsp;
      {submitting ? <Loader className="loader--spin" numIcons={1} /> : null}
    </Button>
  </form>
);

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default connectForm({
  form: 'SignUpForm',
  validate: combineValidators({
    email: isEmail(),
    password: minLength(8),
  }),
})(SignUpForm);
