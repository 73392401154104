import React from 'react';

import descriptions from './shared/descriptions';

export default {
  key: 'word-elimination',
  name: 'Word Elimination',
  description: <div>{descriptions.answerBoundAnimations}</div>,

  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'word-elimination',
  },

  vars: {
    text: {
      type: 'text',
      label: 'Question / Hint',
      optional: true,
    },

    sentence: {
      type: 'text',
      label: 'Sentence',
      help: `
        Use {braces} to denote sections of the sentence that should be eliminated.
      `,
    },
  },
};
