export default {
  key: 'time-select',
  name: 'Time Select',
  description: '',

  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'time-select',
  },

  vars: {
    text: {type: 'text', label: 'Text'},
    time: {type: 'time', label: 'Time'},
  },
};
