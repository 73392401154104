import PropTypes from 'prop-types';
import React from 'react';

class GridWrap extends React.Component {
  static displayName = 'GridWrap';

  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    tagName: PropTypes.string,
  };

  static defaultProps = {
    tagName: 'div',
  };

  render() {
    const {children, className, tagName} = this.props;
    const CustomTagName = tagName;

    return <CustomTagName className={`gw ${className || ''}`}>{children}</CustomTagName>;
  }
}

export default GridWrap;
