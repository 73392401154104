import feathers from 'feathers-client';
import rest from 'feathers-rest/client';
import 'isomorphic-fetch';
import {utils} from '@edu-game/assets';

const {fetch} = global;
const {apiUrl, auth} = global.__appSettings;

export const storage = utils.localOrSessionStore({key: auth.rememberKey});

export default function createApp(options = {}) {
  return (
    feathers()
      /* eslint no-undef:0 */
      .configure(rest(apiUrl).fetch(fetch))
      .configure(feathers.hooks())
      .configure(
        feathers.authentication(
          Object.assign({}, options.authentication, {
            path: '/auth',
            storage,
          }),
        ),
      )
  );
}
