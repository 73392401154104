import ReactGA from 'react-ga';

const {analytics} = global.__appSettings;

const noop = () => {};
let logPageView = () => noop;

if (process.env.NODE_ENV === 'production') {
  logPageView = portalName => {
    const code = analytics.google[portalName];
    const init = code ? () => ReactGA.initialize(code) : noop;
    const returnFn = code
      ? () => {
          const {pathname, search} = window.location;

          ReactGA.set({page: pathname + search});
          ReactGA.pageview(pathname + search);
        }
      : noop;

    init();

    return returnFn;
  };
}

export default logPageView;
