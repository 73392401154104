import padCharsStart from 'lodash/fp/padCharsStart';

export const COLORS = {
  bgFill: '#fff',
  bgStroke: '#faec6f',
  correct: '#7ccfb2',
  dialCenter: '#818181',
  hand: '#818181',
  hitArea: 'skyblue',
  incorrect: '#ff6a76',
  text: '#818181',
};

export const PI = Math.PI;
export const TAU = 2 * PI;

export const flatMap = arrayOfArrays => [].concat.apply([], arrayOfArrays);

export function convertDegreesToDigitalTime(degrees, calculateHours = true) {
  const multiplier = calculateHours ? 12 : 60;
  const normalisedDegrees = Math.round(degrees) % 360;
  const time = normalisedDegrees / 360 * multiplier;

  return calculateHours && time === 0 ? multiplier : time;
}

export function padMinute(minute) {
  return padCharsStart('0')(2)(minute);
}

export function convertDigitalTimeToRadians(
  timeObj = {},
  clockDialPoints = 12
) {
  ['hour', 'minute'].map(key => {
    if (!(key in timeObj)) {
      throw new Error(`no ${key} property defined`);
    }

    return timeObj;
  });

  return {
    hour: convertDigitalHourToRadians(timeObj.hour, clockDialPoints),
    minute: convertDigitalMinuteToRadians(timeObj.minute),
  };
}

function convertDigitalHourToRadians(hour) {
  const numHours = 12;
  const arcWidth = 2 * PI / numHours;

  return (hour % numHours) * arcWidth;
}

function convertDigitalMinuteToRadians(minute) {
  const numMinutes = 60;
  const arcWidth = 2 * PI / numMinutes;

  return (minute % numMinutes) * arcWidth;
}

export function convertRadiansToDigitalTime(
  timeObj = {},
  clockDialPoints = 12
) {
  ['hour', 'minute'].map(key => {
    if (!(key in timeObj)) {
      throw new Error(`no ${key} property defined`);
    }

    return timeObj;
  });

  return {
    hour: convertRadiansToDigitalHour(timeObj.hour, clockDialPoints),
    minute: convertRadiansToDigitalMinute(timeObj.minute),
  };
}

function convertRadiansToDigitalHour(hourAngle, clockDialPoints) {
  const hour =
    Math.round(hourAngle / (2 * PI) * clockDialPoints) % clockDialPoints;

  return hour ? hour : 12;
}

function convertRadiansToDigitalMinute(minuteAngle) {
  const numMinutes = 60;

  return Math.round(minuteAngle / (2 * PI) * numMinutes) % numMinutes;
}
