import numeral from 'numeral';
import assign from 'lodash/assign';

import actionWrapper from './actionWrapper';
import localOrSessionStore from './localOrSessionStore';

const formatCurrency = (amount, format = '0[.]00') => {
  return numeral(amount).format(format);
};

const nestedMerge = (...fields) => {
  return (...args) => {
    // "Deeper" merge the fields specified by 'fields',
    // the rest leave as is.
    const newNested = fields.reduce(
      (result, f) =>
        assign(result, {
          [f]: args.reduce((acc, arg) => assign(acc, arg[f]), {}),
        }),
      {}
    );

    return assign({}, ...args, newNested);
  };
};

export default {
  actionWrapper,
  localOrSessionStore,
  formatCurrency,
  nestedMerge,
};
