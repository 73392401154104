import {
  moneyOptionsFields,
  moneyLabelMap,
  moneyValueDefaults,
} from './shared/money';

export default {
  key: 'money-count',
  name: 'Money Count',
  description: '',
  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'money-count',
  },

  vars: {
    values: {
      type: 'object',
      label: 'Money Counts',
      help: 'Enter the number of coins to include in the question',
      isLocked: true,
      valueInputType: 'number',
      defaultValue: moneyValueDefaults,
      labelMap: moneyLabelMap,
    },

    options: moneyOptionsFields,
  },
};
