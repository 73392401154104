/* eslint-disable no-underscore-dangle */
import Two from '../two-no-conflict';

const bubbleCanvasFactory = mountElem => {
  if (!mountElem || !('nodeType' in mountElem)) {
    throw new Error('no mount element provided');
  }

  let options = {
    width: 360,
    height: 360,
    type: 'svg',
    gravity: 0,
    maxLife: 30,
    velocity: {
      x: {
        max: 7,
        min: -7,
      },
      y: {
        max: 5,
        min: -5,
      },
    },
    colors: ['#fff'],
  };
  let two;

  function init(opts) {
    options = Object.assign({}, options, opts);
    two = new Two({
      type: Two.Types[options.type],
      width: options.width,
      height: options.height,
    })
      .bind('resize', handleResize)
      .bind('update', explodeParticles);

    two.appendTo(mountElem);
    two.play();
    setViewBox(options.width, options.height);

    two.renderer.domElement.setAttribute(
      'style',
      `
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      `
    );
  }

  function explodeShape({top, left, width, height}) {
    Two.Resolution = 32;
    const ellipse = new Two.Ellipse(
      left + width / 2,
      top + height / 2,
      width / 2,
      height / 2
    );
    ellipse.vertices.map(({x, y}) =>
      addParticle({
        x: x + left + width / 2,
        y: y + top + height / 2,
      })
    );
  }

  function explode({x, y}) {
    Two.Resolution = 16;
    const circle = new Two.Circle(x, y, 10);
    circle.vertices.map(() => addParticle({x, y}));
  }

  function explodeParticles() {
    two.scene.children.map(p => {
      p.translation.addSelf(p.vel);
      p.vel.y = p.vel.y + options.gravity;
      p.opacity = 1 - p.life / p.maxLife;
      p.life = p.life + 1;
      const shouldRemove = p.life >= p.maxLife;

      if (shouldRemove) {
        two.remove(p);
      }

      return shouldRemove;
    });
  }

  function addParticle({x, y}) {
    const {colors, gravity, maxLife, velocity} = options;
    const radius = parseInt(Math.random() * 10, 10);
    const opacity = parseFloat(Math.random(), 3);
    const particle = two.makeCircle(x, y, radius);
    const colorIndex = Math.floor(Math.random() * colors.length);
    particle.noStroke();
    particle.fill = colors[colorIndex];
    particle.opacity = opacity;
    particle.life = 1;
    particle.gravity = gravity;
    particle.maxLife = maxLife;
    particle.vel = new Two.Vector(
      getParticleVectorComponent(velocity.x),
      getParticleVectorComponent(velocity.y)
    );
  }

  function getParticleVectorComponent(axis) {
    return Math.random() * (axis.max - axis.min) + axis.min;
  }

  function setViewBox(width, height) {
    two.renderer.domElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
  }

  function handleResize() {
    setViewBox(two.width, two.height);
    two.update();
  }

  function updateDims({height, width}) {
    two.width = parseInt(width, 10);
    two.height = parseInt(height, 10);
    two.trigger('resize');
  }

  function setOptions(opts) {
    options = Object.assign({}, options, opts);
  }

  function removeEvents() {
    two.unbind('resize', handleResize);
    two.unbind('update', explodeParticles);
  }

  function destroyPaths() {
    two.clear();
  }

  function destroy() {
    destroyPaths();
    removeEvents();

    return true;
  }

  return {
    init,
    updateDims,
    explodeShape,
    explode,
    setOptions,
    destroy,
  };
};

export default bubbleCanvasFactory;
