import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../Button';
import GridWrap from '../../GridWrap';
import GridCol from '../../GridCol';

const ListItem = ({item, onRemoveClick}) => (
  <GridWrap className="gw--small">
    <GridCol className="g--auto">{item}</GridCol>

    <GridCol className="g--shrink-wrap">
      <Button
        className="btn-circle--red-alpha-lt btn-circle--small"
        onClick={e => onRemoveClick(item, e)}
        iconId="c-icon-cross"
      />
    </GridCol>
  </GridWrap>
);

ListItem.propTypes = {
  item: PropTypes.node,
  onRemoveClick: PropTypes.func,
};

ListItem.displayName = 'ListItem';

export default ListItem;
