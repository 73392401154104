/* eslint-disable no-underscore-dangle */
import Two from '../two-no-conflict';

const playerFactory = ({debug = false, parentElem, svgNode}) => {
  const colors = {
    default: '#fff',
    hurt: '#f7d046',
    sick: '#ff6a76',
  };
  const origFills = {
    'head-shape': null,
    body: null,
  };
  const paths = {};
  let center;
  let two;
  let playerMount;
  let animateFaceTimer;
  let dims;
  let parentDims;

  function init() {
    playerMount = document.createElement('div');
    playerMount.style.position = 'absolute';
    parentElem.insertAdjacentElement('afterbegin', playerMount);
    two = new Two({
      type: Two.Types.svg,
    })
      .appendTo(playerMount)
      .play();

    paths.player = two.interpret(svgNode);
    paths.player.center();
    paths.player.opacity = 0;
    dims = svgNode.getBoundingClientRect();
    two.width = dims.width;
    two.height = dims.height;
    paths.player.translation.set(two.width / 2, two.height / 2);
    parentDims = parentElem.getBoundingClientRect();

    two.update();

    const headShape = paths.player.getById('head-shape');
    const headDims = headShape.getBoundingClientRect(true);
    Object.keys(origFills).map(key => (origFills[key] = paths.player.getById(key).fill));
    paths.gradient = two.makeRadialGradient(
      headDims.width / 2,
      headDims.height / 2,
      Math.max(headDims.width, headDims.height),
      new Two.Stop(0, colors.sick),
      new Two.Stop(0, colors.default),
    );

    paths.player._renderer.elem.style.cursor = 'move';
    two.bind('update', animatePlayerIn);
  }

  function setCenter({x, y}) {
    const {width, height} = dims;
    const {height: pHeight, width: pWidth} = parentDims;
    const continueX = x > width / 2 && x < pWidth - width / 2;
    const continueY = y > height / 2 && y < pHeight - height / 2;
    center = {
      x: continueX ? x : center.x,
      y: continueY ? y : center.y,
    };

    playerMount.style.transform = `translate3d(
        ${center.x - width / 2}px,
        ${center.y - height / 2}px,
        0px
      )`;
  }

  function getCenter() {
    return center;
  }

  function animatePlayerIn() {
    const opacityThreshold = debug ? 0.5 : 1;

    if (paths.player.opacity < opacityThreshold) {
      paths.player.opacity = paths.player.opacity + 0.05;
    }

    if (paths.player.opacity >= opacityThreshold) {
      two.unbind('update', animatePlayerIn);
    }
  }

  function animateFace() {
    const {stops} = paths.gradient;
    const stop = stops[0];
    const inc = stop.offset > 1 ? 0 : 0.075;

    if (inc === 0) {
      stop.offset = 1;
      two.unbind('update', animateFace);

      Array.apply(null, Array(7)).map((_, i) =>
        setTimeout(() => {
          Object.keys(origFills).map(fillKey => {
            const color = i % 2 === 0 ? origFills[fillKey] : stop.color;
            const shouldUpdate = !!paths.player;

            if (shouldUpdate) {
              paths.player.getById(fillKey).fill = color;
            }

            return true;
          });
        }, 75 * i),
      );

      animateFaceTimer = setTimeout(() => {
        Object.keys(origFills).map(fillKey => {
          const shouldUpdate = !!paths.player;
          if (shouldUpdate) {
            paths.player.getById(fillKey).fill = origFills[fillKey];
          }

          return true;
        });
        stop.offset = 0;
      }, 1000);
    }

    stop.offset = stop.offset + inc;
  }

  function animatePlayerFace(isHurt = false) {
    const {stops} = paths.gradient;
    const color = colors[isHurt ? 'hurt' : 'sick'];
    stops[0].color = color;

    Object.keys(origFills).map(key => (paths.player.getById(key).fill = paths.gradient));

    if (animateFaceTimer) clearTimeout(animateFaceTimer);

    two.bind('update', animateFace);
  }

  function destroy() {
    Object.keys(paths).map(key => {
      two.remove(paths[key]);

      return delete paths[key];
    });

    two.unbind('update', animatePlayerIn);
    two.unbind('update', animateFace);
    parentElem.removeChild(playerMount);
    playerMount = undefined;
  }

  init();

  return {
    animatePlayerFace,
    destroy,
    elem: paths.player._renderer.elem,
    getCenter,
    setCenter,
  };
};

export default playerFactory;
