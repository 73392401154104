import PropTypes from 'prop-types';
import React from 'react';

const ChartDonut = ({className, value, total, label, sublabel, padding}) => {
  const width = 250;
  const height = 250;

  const centerX = width / 2;
  const centerY = height / 2;
  const radius = width / 2 - padding;

  const endAngle = (2 * Math.PI * value) / total - 0.0001;
  const startX = centerX;
  const startY = centerY - radius;
  const endX = centerX + radius * Math.sin(endAngle);
  const endY = centerY - radius * Math.cos(endAngle);
  const big = endAngle > Math.PI ? 1 : 0;

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      className={`chart-donut ${label && sublabel ? 'has-both-labels' : ''} ${className || ''}`}
    >
      {label ? (
        <text className="chart-donut__label" x={centerX} y={centerY} dy={sublabel ? '0' : '.5em'}>
          {label}
        </text>
      ) : null}

      {sublabel ? (
        <text
          className="chart-donut__sublabel"
          x={centerX}
          y={centerY}
          dy={label ? '1.5em' : '.5em'}
        >
          {sublabel}
        </text>
      ) : null}

      <circle className="chart-donut__back" cx={centerX} cy={centerY} r={radius} />

      <path
        className="chart-donut__slice"
        d={`M ${startX},${startY} A ${radius},${radius} 0 ${big} 1 ${endX},${endY}`}
      />
    </svg>
  );
};

ChartDonut.displayName = 'ChartDonut';
ChartDonut.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  label: PropTypes.string,
  sublabel: PropTypes.string,
};

ChartDonut.defaultProps = {
  label: '',
  sublabel: '',
  padding: 16,
};

export default ChartDonut;
