import React from 'react';
import {SubmissionError} from 'redux-form';
import {Link} from 'react-router';

import {UserManagementContainer} from '@edu-game/assets';
import LinksFooter from '../LinksFooter';
import PageTitle from '../PageTitle';

import createApp, {storage} from '../../feathers';
import LoginForm from '../forms/Login';
import routes from '../../routes';

const {location} = global;
const {jwt} = global.__appSettings;

const app = createApp({
  authentication: {
    storageKey: jwt.userStorageKey,
  },
});

class LoginPage extends React.Component {
  static displayName = 'LoginPage';
  state = {isChecking: storage.isPersist() && storage.getItem(jwt.userStorageKey)};

  componentWillMount() {
    if (this.state.isChecking) {
      this.setState({isChecking: true});
      app
        .authenticate()
        .then(({user}) => {
          console.log('user: ', JSON.stringify(user));
          if (user) {
            this.redirectToApp(user);
            return;
          }
          this.setState({isChecking: false});
        })
        .catch(() => {
          this.setState({isChecking: false});
          storage.setShouldPersist(false);
        });
    }
  }

  redirectToApp = user => {
    location.href = routes.redirectForUser(user);
  };

  handleSubmit = values => {
    this.setState({isLoggingIn: true});
    storage.setShouldPersist(values.remember);
    console.log('login values: ', values);
    return app
      .authenticate({
        strategy: 'merged-local',
        user: values.user,
        password: values.password,
      })
      .then(({user}) => {
        this.redirectToApp(user);
      })
      .catch(err => {
        this.setState({isLoggingIn: false});
        throw new SubmissionError({_error: err});
      });
  };

  render() {
    const {history} = this.props;
    const {isChecking, isLoggingIn} = this.state;

    return (
      <UserManagementContainer showBack history={history}>
        <PageTitle className="push--smaller--bottom">Login</PageTitle>

        {isChecking ? null : (
          <div>
            <div className="push--smaller--bottom">
              <LoginForm onSubmit={this.handleSubmit} isLoading={isLoggingIn} />
            </div>

            <LinksFooter>
              {/* <Link className="fc--ntrl-min" to={routes.signUp}>
                Sign up
              </Link> */}

              <br />

              <Link className="fc--ntrl-min" to={routes.forgotten}>
                Forgot password?
              </Link>

              <span> | </span>

              <Link className="fc--ntrl-min" to={routes.resendConfirmation}>
                Resend confirmation?
              </Link>
            </LinksFooter>
          </div>
        )}
      </UserManagementContainer>
    );
  }
}

export default LoginPage;
