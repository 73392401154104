export const LINEWIDTH = 4;

export const COLORS = {
  shapeFill: 'white',
  divStroke: '#9da9d6',
  divFill: '#633d8a',
};

export const getLargestDivisor = number => {
  let num = Math.ceil(Math.sqrt(number));

  if (!num) return num;

  while (number % num !== 0) {
    num = num - 1;
  }

  return num;
};

export const isPrime = number => {
  const sqrt = Math.sqrt(number);
  let start = 2;

  while (start <= sqrt) {
    if (number % start++ < 1) return false;
  }

  return number > 1;
};

export const getPolygonDivisions = (twoInstance, dims, polygon) => {
  /**
   * Get one fewer vertices than make the polygon, i.e. the L vertices
   */
  const vertices = polygon.vertices.filter(({command}) => command !== 'M');

  return vertices.map((_, i) => {
    const nextIndex = (i + 1) % vertices.length;
    const division = twoInstance.makePath(
      dims.ox,
      dims.oy,
      vertices[i].x + dims.width / 2,
      vertices[i].y + dims.height / 2,
      vertices[nextIndex].x + dims.width / 2,
      vertices[nextIndex].y + dims.height / 2,
    );
    setDivisionDefaults(division);

    return division;
  });
};

export const setShapeDefaults = shape => {
  const shp = shape;
  shp.fill = COLORS.shapeFill;
  shp.opacity = 0.7;
  shp.noStroke();

  return shp;
};

export const setDivisionDefaults = division => {
  const div = division;
  div.noFill();
  div.opacity = 0.6;
  div.stroke = COLORS.divStroke;
  div.linewidth = LINEWIDTH;
  div.join = 'bevel';

  return div;
};
