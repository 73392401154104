import PropTypes from 'prop-types';
import React from 'react';

const Logo = ({className, imgVariant}) => (
  <span className={`${className || 'logo'}`}>
    <img
      src={`/assets/img/common/edu-games-plus-logo${imgVariant ? `-${imgVariant}` : ''}.svg`}
      alt="Exam Papers Plus logo"
    />
  </span>
);

Logo.displayName = 'Logo';
Logo.propTypes = {
  className: PropTypes.string,
  imgVariant: PropTypes.string,
};

export default Logo;
