import PropTypes from 'prop-types';
import React from 'react';

const PortalContent = ({header, children}) => (
  <div className="portal-content">
    {header ? <div className="portal-content__header">{header}</div> : null}

    <div className="portal-content__inner">{children}</div>
  </div>
);

PortalContent.displayName = 'PortalContent';
PortalContent.propTypes = {
  header: PropTypes.any,
  children: PropTypes.node,
};

export default PortalContent;
