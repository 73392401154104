import PropTypes from 'prop-types';
import React from 'react';
import omit from 'lodash/fp/omit';

import fieldHOC from './Field';

const prepProps = omit(['optional', 'help', 'component', 'toQuery']);

class InputField extends React.Component {
  static displayName = 'InputField';
  static propTypes = {type: PropTypes.string};

  render() {
    const {type} = this.props;
    const preparedProps = prepProps(this.props);

    switch (type) {
      case 'checkbox':
      case 'radio':
        return <input {...preparedProps} className="visuallyhidden" />;
      default:
        return (
          <input autoCapitalize="none" autoComplete="none" autoCorrect="none" {...preparedProps} />
        );
    }
  }
}

export default fieldHOC(InputField);
