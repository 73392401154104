export const moneyValueDefaults = {
  penceOne: 0,
  penceTwo: 0,
  penceFive: 0,
  penceTen: 0,
  penceTwenty: 0,
  penceFifty: 0,
  poundsOne: 0,
  poundsTwo: 0,
  poundsFive: 0,
  poundsTen: 0,
  poundsTwenty: 0,
  poundsFifty: 0,
};

export const moneyOptionsDefaults = {
  penceOne: false,
  penceTwo: false,
  penceFive: false,
  penceTen: false,
  penceTwenty: false,
  penceFifty: false,
  poundsOne: false,
  poundsTwo: false,
  poundsFive: false,
  poundsTen: false,
  poundsTwenty: false,
  poundsFifty: false,
};

export const moneyLabelMap = {
  penceOne: 'One Pence',
  penceTwo: 'Two Pence',
  penceFive: 'Five Pence',
  penceTen: 'Ten Pence',
  penceTwenty: 'Twenty Pence',
  penceFifty: 'Fifty Pence',
  poundsOne: 'One Pound',
  poundsTwo: 'Two Pound',
  poundsFive: 'Five Pounds',
  poundsTen: 'Ten Pounds',
  poundsTwenty: 'Twenty Pounds',
  poundsFifty: 'Fifty Pounds',
};

export const moneyOptionsFields = {
  type: 'object',
  label: 'Money Options',
  help: 'Select which money values will be available to players',
  isLocked: true,
  valueInputType: 'checkbox',
  defaultValue: moneyOptionsDefaults,
  labelMap: moneyLabelMap,
  options: Object.keys(moneyLabelMap),
};
