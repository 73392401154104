import PropTypes from 'prop-types';
import React from 'react';

import reflectionCanvasFactory from '../lib/reflection-canvas';

const degToRad = deg => (parseInt(deg, 10) / 180) * Math.PI;

class ReflectionCanvas extends React.Component {
  static displayName = 'ReflectionCanvas';

  static propTypes = {
    angleInDeg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    disableEvents: PropTypes.bool,
    hints: PropTypes.arrayOf(PropTypes.bool),
    mode: PropTypes.oneOf(['hint', 'select']),
    numPointsX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    numPointsY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onGetSelection: PropTypes.func,
    onHintToggle: PropTypes.func,
    onSelectToggle: PropTypes.func,
    onUpdateDims: PropTypes.func,
    questionId: PropTypes.string,
    selections: PropTypes.array,
  };

  static defaultProps = {
    className: 'reflection-canvas',
    angleInDeg: 0,
    hints: [],
    selections: [],
    mode: 'select',
    numPointsX: 0,
    numPointsY: 0,
  };

  componentDidMount() {
    const {
      angleInDeg,
      disableEvents,
      mode,
      numPointsX,
      numPointsY,
      onHintToggle,
      onSelectToggle,
      selections,
    } = this.props;

    window.addEventListener('resize', this.updateDims);

    this.reflection = reflectionCanvasFactory(this.refMount);
    this.reflection.init({
      angle: degToRad(angleInDeg),
      disableEvents,
      mode,
      numPointsX,
      numPointsY,
      onHintToggle,
      onSelectToggle,
    });

    this.updateDims();
    this.setHints();
    this.setSelections(selections, mode);
  }

  componentDidUpdate(prevProps) {
    const {angleInDeg, hints, numPointsX, numPointsY, questionId} = this.props;

    if (prevProps.questionId !== questionId) {
      this.resetSelections();
    }

    if (prevProps.numPointsX !== numPointsX || prevProps.numPointsY !== numPointsY) {
      this.handlePointsUpdate();
    }

    if (prevProps.angleInDeg !== angleInDeg) {
      this.setAngle();
    }

    if (prevProps.hints !== hints) {
      this.setHints();
    }
  }

  componentWillUnmount() {
    this.reflection.destroy();
    window.removeEventListener('resize', this.updateDims);
  }

  setHints = () => {
    const {hints, mode} = this.props;

    this.reflection.setMode('hint');
    this.reflection.setHints(hints);
    this.reflection.setMode(mode);
  };

  resetSelections = () => {
    const {mode, numPointsX, numPointsY} = this.props;
    const selections = Array.from({length: numPointsX * numPointsY}).map(() => false);

    this.setSelections(selections, mode);
  };

  setSelections = (selections, currentMode) => {
    this.reflection.setMode('select');
    this.reflection.setSelections(selections);
    this.reflection.setMode(currentMode);
  };

  setAngle = () => {
    const {angleInDeg} = this.props;

    this.reflection.setAngle(degToRad(angleInDeg));
  };

  updateDims = () => {
    const {offsetHeight, offsetWidth} = this.refMount;

    this.reflection.updateDims({
      width: offsetWidth,
      height: offsetHeight,
    });
  };

  handlePointsUpdate = () => {
    const {numPointsX, numPointsY} = this.props;

    this.reflection.setPoints(numPointsX, numPointsY);
    this.setAngle();
  };

  render() {
    const {className} = this.props;

    return (
      <div className={className} data-testid="reflection-canvas" ref={c => (this.refMount = c)} />
    );
  }
}

export default ReflectionCanvas;
