import PropTypes from 'prop-types';
import React from 'react';

import animatedStrokeFactory from '../lib/animated-stroke-canvas';

class AnimatedStrokeCanvas extends React.Component {
  static displayName = 'AnimatedStrokeCanvas';

  static defaultProps = {className: 'animated-stroke-canvas'};

  static propTypes = {
    className: PropTypes.string,
    duration: PropTypes.number,
    height: PropTypes.number,
    isPaused: PropTypes.bool,
    onAnimEnd: PropTypes.func,
    onProgress: PropTypes.func,
    questionId: PropTypes.string,
  };

  state = {width: 0, height: 0};

  componentDidMount() {
    const {duration, onAnimEnd, onProgress} = this.props;
    const mount = this.mountRef;

    window.addEventListener('resize', this.updateCanvasDims);
    this.animatedStrokeCanvas = animatedStrokeFactory(mount);
    this.animatedStrokeCanvas.init({
      height: mount.offsetHeight,
      onAnimEnd,
      onProgress,
      width: mount.offsetWidth,
    });
    this.animatedStrokeCanvas.setDuration(duration);

    this.setAnimationPlayState();
  }

  componentDidUpdate(prevProps) {
    const {height, isPaused, questionId} = this.props;

    if (!this.animatedStrokeCanvas) return;

    if (isPaused !== prevProps.isPaused) {
      this.setAnimationPlayState();
    }

    if (height !== prevProps.height) {
      this.updateCanvasDims();
    }

    if (questionId !== prevProps.questionId) {
      this.handleNewQuestion();
    }
  }

  componentWillUnmount() {
    this.animatedStrokeCanvas.destroy();
    window.removeEventListener('resize', this.updateCanvasDims);
  }

  handleNewQuestion = () => {
    const {duration} = this.props;

    this.animatedStrokeCanvas.setDuration(duration);
    this.animatedStrokeCanvas.reset();
    this.animatedStrokeCanvas.play();
  };

  setAnimationPlayState = () => {
    const {isPaused} = this.props;
    const fn = isPaused ? 'pause' : 'play';

    this.animatedStrokeCanvas[fn]();
  };

  updateCanvasDims = () => {
    const {isPaused} = this.props;
    const {offsetWidth, offsetHeight} = this.mountRef;

    this.animatedStrokeCanvas.updateDims({
      height: offsetHeight,
      width: offsetWidth,
    });

    if (!isPaused) {
      this.animatedStrokeCanvas.play();
    }
  };

  render() {
    const {className} = this.props;

    return (
      <div
        className={className}
        data-testid="animated-stroke-canvas"
        ref={c => (this.mountRef = c)}
      />
    );
  }
}

export default AnimatedStrokeCanvas;
