// demo: https://codepen.io/larrybotha/pen/YZvYLK

import Two from '../two-no-conflict';

import reflectionPointFactory from './point';
import reflectionBgFactory from './background';

const reflectionCanvasFactory = mountElem => {
  if (!mountElem || !('nodeType' in mountElem)) {
    throw new Error('no mount element provided');
  }

  const eventMap = {
    mousedown: handleCursorDown,
    mouseup: handleCursorUp,
    touchstart: handleCursorDown,
    touchend: handleCursorUp,
  };
  const paths = {};
  let options = {
    angle: 0,
    width: 100,
    height: 100,
    mode: 'select',
    type: 'svg',
    numPointsX: 0,
    numPointsY: 0,
  };
  let two;

  function init(opts) {
    options = {...options, ...opts};
    two = new Two({
      type: Two.Types[options.type || 'svg'],
      width: options.width,
      height: options.height,
    })
      .bind('resize', handleResize)
      .play();

    two.appendTo(mountElem);

    initCanvas();

    if (!options.disableEvents) {
      initEvents();
    }

    two.renderer.domElement.setAttribute(
      'style',
      `
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      `,
    );
  }

  function initCanvas() {
    setMode(options.mode);

    setViewBox(options.width, options.height);
    initBg();

    setPoints(options.numPointsX, options.numPointsY);
    setAngle(options.angle);
  }

  function initBg() {
    paths.bg = reflectionBgFactory(two);
    two.update();
  }

  function handleResize() {
    setViewBox(two.width, two.height);
    paths.bg.draw(two.width, two.height);
    paths.points.map(p => p.updateCenter());
    two.update();
  }

  function drawPoints() {
    const {angle, disableEvents, numPointsX, numPointsY} = options;
    const coordArray = getCoordArray();

    paths.points = coordArray.map(coords =>
      reflectionPointFactory(two, {
        coords,
        hasActionArea: !disableEvents,
        numX: numPointsX,
        numY: numPointsY,
        refAngle: angle,
      }),
    );
  }

  function getCoordArray() {
    const xArray = Array.apply(null, Array(options.numPointsX));
    const yArray = Array.apply(null, Array(options.numPointsY));

    return xArray
      .map((_x, xi) => yArray.map((_y, yi) => ({x: xi, y: yi})))
      .reduce((acc, xs) => acc.concat(...xs), []);
  }

  function handleCursorDown(e) {
    e.preventDefault();
  }

  function handleCursorUp(e) {
    const {mode} = options;
    const point = getEventPoint(e);
    const pointToggleFn = mode === 'select' ? 'setSelected' : 'setHinted';
    const pointCb = mode === 'select' ? 'onSelectToggle' : 'onHintToggle';

    if (point) {
      point[pointToggleFn]();

      if (typeof options[pointCb] === 'function') {
        options[pointCb](paths.points.map(p => p.getState()));
      }
    }
  }

  function getEventPoint(e) {
    const touches = ['targetTouches', 'changedTouches'].reduce(
      (acc, targetType) => (e[targetType] && e[targetType].length ? e[targetType] : acc),
      null,
    );
    const event = touches ? touches[0] : e;
    const {target} = event;

    return paths.points.find(({shapes}) => {
      const ids = Object.keys(shapes)
        .filter(key => !!shapes[key])
        .map(key => shapes[key].id);

      return ids.indexOf(target.id) > -1;
    });
  }

  function getPoints() {
    return paths.points;
  }

  function setHints(hintsArray) {
    if (hintsArray.length === paths.points.length) {
      paths.points.map((_, i) => paths.points[i].setHinted(hintsArray[i]));
    }
  }

  function setSelections(selectionsArray) {
    if (selectionsArray.length === paths.points.length) {
      paths.points.map((_, i) => paths.points[i].setSelected(selectionsArray[i]));
    }
  }

  function setMode(newMode) {
    if (/^(select|hint)$/.test(newMode)) {
      options.mode = newMode;
    }
  }

  function setPoints(numPointsX, numPointsY) {
    options = {
      ...options,
      numPointsX: parseInt(numPointsX, 10),
      numPointsY: parseInt(numPointsY, 10),
    };

    if (paths.points) {
      destroyPoints();
    }

    drawPoints();
  }

  function setAngle(angleInRad) {
    const angle = parseFloat(angleInRad, 10);

    setBgAngle(angle);
    setPointAngles(angle);
  }

  function setBgAngle(angle) {
    paths.bg.setAngle(angle);
  }

  function setPointAngles(angle) {
    if (paths.points) {
      paths.points.map(p => p.setAngle(angle));
    }
  }

  function setViewBox(width, height) {
    two.renderer.domElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
  }

  function updateDims({height, width}) {
    options = {
      ...options,
      height: parseInt(height, 10) || options.height,
      width: parseInt(width, 10) || options.width,
    };

    two.height = options.height;
    two.width = options.width;

    two.trigger('resize');
  }

  function initEvents() {
    const domElement = two.renderer.domElement;

    Object.keys(eventMap).map(type => domElement.addEventListener(type, eventMap[type]));
  }

  function removeEvents() {
    const domElement = two.renderer.domElement;

    Object.keys(eventMap).map(type => domElement.removeEventListener(type, eventMap[type]));
  }

  function destroyPoints() {
    if (paths.points) {
      paths.points.map(p => p.destroy());
      delete paths.points;
    }
  }

  function destroyBg() {
    paths.bg.destroy();
    delete paths.bg;
  }

  function destroyPaths() {
    destroyPoints();
    destroyBg();
    two.clear();
  }

  function destroy() {
    removeEvents();
    destroyPaths();

    return true;
  }

  return {
    destroy,
    updateDims,
    getPoints,
    setAngle,
    setHints,
    setSelections,
    setPoints,
    setMode,
    init,
  };
};

export default reflectionCanvasFactory;
