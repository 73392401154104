import barGraph from './barGraph';
import bombDodge from './bomb-dodge';
import cartesianPlane from './cartesian-plane';
import cloze from './cloze';
import dragAndDropSort from './dragAndDropSort';
import dragAndDropOrder from './dragAndDropOrder';
import moneyCount from './money-count';
import moneySolve from './money-solve';
import customKeyboard from './customKeyboard';
import fractionsSelect from './fractionsSelect';
import fractionsMultipleChoice from './fractionsMultipleChoice';
import jumbledWords from './jumbledWords';
import mass from './mass';
import matchPairs from './matchPairs';
import multipleChoiceColumns from './multipleChoiceColumns';
import multipleChoiceFlip from './multipleChoiceFlip';
import multipleChoiceImage from './multipleChoiceImage';
import multipleChoiceLetters from './multipleChoiceLetters';
import multipleChoiceList from './multipleChoiceList';
import multipleChoiceTimed from './multipleChoiceTimed';
import multipleChoiceTimedCards from './multipleChoiceTimedCards';
import multipleChoiceTimedFall from './multipleChoiceTimedFall';
import numberline from './numberline';
import reflection from './reflection';
import sequencing from './sequencing';
import swipeSort from './swipeSort';
import timeSelect from './timeSelect';
import timeInput from './timeInput';
import wheel from './wheel';
import wordElimination from './wordElimination';
import wordSearch from './wordSearch';

const templates = [
  barGraph,
  bombDodge,
  cartesianPlane,
  cloze,
  dragAndDropSort,
  dragAndDropOrder,
  moneyCount,
  moneySolve,
  customKeyboard,
  fractionsSelect,
  fractionsMultipleChoice,
  jumbledWords,
  mass,
  matchPairs,
  multipleChoiceColumns,
  multipleChoiceFlip,
  multipleChoiceImage,
  multipleChoiceLetters,
  multipleChoiceList,
  multipleChoiceTimed,
  multipleChoiceTimedCards,
  multipleChoiceTimedFall,
  numberline,
  reflection,
  sequencing,
  swipeSort,
  timeSelect,
  timeInput,
  wordElimination,
  wordSearch,
  wheel,
];

templates.forSelect = function forSelect() {
  return templates.reduce(
    (obj, t) => Object.assign(obj, {[t.key]: t.name}),
    {}
  );
};

export default templates;
