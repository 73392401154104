import PropTypes from 'prop-types';
import React from 'react';
import getErrorMessage from '../../lib/getErrorMessage';
import pick from 'lodash/fp/pick';

import InputFieldMessage from '../InputFieldMessage';
import InlineSVGIcon from '../InlineSVGIcon';
import RadioCheckCustomIcon from './RadioCheckCustomIcon';

function isNully(value) {
  return value === undefined || value === null;
}

export const prepareInputProps = pick([
  'autoComplete',
  'autoFocus',
  'checked',
  'defaultValue',
  'disabled',
  'id',
  'max',
  'maxlength',
  'min',
  'multiple',
  'name',
  'onBlur',
  'onChange',
  'onClick',
  'onFocus',
  'onInput',
  'pattern',
  'placeholder',
  'required',
  'rows',
  'step',
  'style',
  'type',
  'value',
]);

let idBase = 1;
function nextId() {
  return idBase++;
}

const createComponent = Component => {
  return class extends React.Component {
    static displayName = `FieldHOC(${Component.displayName || 'Component'})`;

    static propTypes = {
      className: PropTypes.string,
      type: PropTypes.string,
      label: PropTypes.string,
      labelFullWidth: PropTypes.bool,
      msgClassName: PropTypes.string,
      withIcon: PropTypes.bool,
      fieldProps: PropTypes.object,
      help: PropTypes.string,
      input: PropTypes.object.isRequired,
      meta: PropTypes.object,
    };

    static defaultProps = {meta: {}};

    getIconMarkup = () => {
      const {
        meta: {touched, error},
      } = this.props;
      const iconId = error ? 'cross' : 'tick';

      return touched ? (
        <InlineSVGIcon
          iconId={`c-icon-${iconId}`}
          className="input-iconed__icon"
          classNameInner="input-iconed__icon__inner"
        />
      ) : null;
    };

    getComponentProps = () => {
      const {input, fieldProps} = this.props;

      return {
        id: this.getElementId(),
        ...prepareInputProps(this.props),
        ...input,
        ...fieldProps,
        value: isNully(input.value) ? (fieldProps || {}).defaultValue : input.value,
      };
    };

    getInputMarkup = () => {
      const {withIcon, meta} = this.props;
      const componentProps = this.getComponentProps();
      const component = <Component {...componentProps} />;
      const icon = withIcon ? this.getIconMarkup() : null;

      const inputClassName =
        withIcon && meta.touched
          ? meta.error
            ? 'input-iconed--error'
            : 'input-iconed--success'
          : '';

      return withIcon ? (
        <span className={`input-iconed ${inputClassName}`}>
          {component}
          {icon}
        </span>
      ) : (
        component
      );
    };

    getElementId = () => {
      const {input} = this.props;

      if (!this.elementId) {
        this.elementId = nextId();
      }

      return `field-${input.name}-${this.elementId}`;
    };

    getMessageMarkup = () => {
      const {meta, msgClassName} = this.props;

      return meta.touched && meta.error ? (
        <InputFieldMessage className={msgClassName}>{getErrorMessage(meta)}</InputFieldMessage>
      ) : null;
    };

    getLabelMarkup = () => {
      const {disabled, label, labelFullWidth, fieldProps} = this.props;
      const labelStyle = labelFullWidth ? {display: 'block'} : {};
      const labelText = label || (fieldProps || {}).label;
      const {type} = this.getComponentProps();

      return labelText ? (
        <label
          className={`${disabled ? 'is-disabled' : ''}`}
          htmlFor={this.getElementId()}
          style={labelStyle}
        >
          {type === 'checkbox' ? <RadioCheckCustomIcon /> : null} {labelText}
        </label>
      ) : null;
    };

    getHelpMarkup = () => {
      const {help, fieldProps} = this.props;
      const helpText = help || (fieldProps || {}).help;
      return helpText ? (
        <InputFieldMessage className="field__msg--info push--smallest--bottom">
          <small>{helpText}</small>
        </InputFieldMessage>
      ) : null;
    };

    render() {
      const {className} = this.props;
      const labelMarkup = this.getLabelMarkup();
      const inputMarkup = this.getInputMarkup();
      const msgMarkup = this.getMessageMarkup();
      const helpMarkup = this.getHelpMarkup();
      const componentProps = this.getComponentProps();

      return componentProps.type === 'checkbox' ? (
        <div className={`field ${className || ''}`}>
          {inputMarkup}
          {labelMarkup}
          {helpMarkup}
          {msgMarkup}
        </div>
      ) : (
        <div className={`field ${className || ''}`}>
          {labelMarkup}
          {helpMarkup}
          {inputMarkup}
          {msgMarkup}
        </div>
      );
    }
  };
};

export default createComponent;
