import PropTypes from 'prop-types';
import React from 'react';

import timerDialFactory from '../lib/timer-dial';

export default class extends React.Component {
  static displayName = 'TimerDial';

  static defaultProps = {
    className: '',
    options: {},
  };

  static propTypes = {
    options: PropTypes.object,
    timerInterval: PropTypes.number,
    timeRemaining: PropTypes.number.isRequired,
    totalTime: PropTypes.number.isRequired,
  };

  componentDidMount() {
    const {options, totalTime} = this.props;
    const numPoints = totalTime;

    this.timerDial = timerDialFactory(this.timerDialMount);
    this.timerDial.init(options);
    this.timerDial.drawArc(numPoints);
  }

  componentDidUpdate(prevProps) {
    const {timeRemaining: prevTimeRemaining} = prevProps;
    const {timeRemaining, totalTime} = this.props;

    if (this.timerDial && prevTimeRemaining !== timeRemaining) {
      const beginning = timeRemaining / totalTime;

      this.timerDial.updateArcProp('beginning', beginning);
    }
  }

  componentWillUnmount() {
    if (this.timerDial) {
      this.timerDial.destroy();
      delete this.timerDial;
    }
  }

  render() {
    const {className} = this.props;

    return (
      <div data-testid="timer-dial" className={className} ref={c => (this.timerDialMount = c)} />
    );
  }
}
