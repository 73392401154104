import PropTypes from 'prop-types';
import React from 'react';
import {Field, reduxForm as connectForm} from 'redux-form';

import {combineValidators} from 'redux-form-revalid';

import isEmail from 'redux-form-revalid/validations/isEmail';
import {validatePassword} from '@edu-game/assets/src/js/validations';
import {Button, InputField, InputFieldMessage, Loader} from '@edu-game/assets';

function getErrorComponent(error) {
  return (
    <InputFieldMessage className="fc--red-alpha-ltst" tagName="p">
      {error.message}
    </InputFieldMessage>
  );
}

class LoginForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isLoading: PropTypes.bool,
  };

  render() {
    const {handleSubmit, submitting, error, isLoading} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          className="field--trans"
          msgClassName="fc--red-alpha-ltst"
          name="user"
          component={InputField}
          type="text"
          disabled={isLoading}
          label="Email or Username"
          withIcon
        />

        <Field
          className="field--trans"
          msgClassName="fc--red-alpha-ltst"
          name="password"
          component={InputField}
          type="password"
          disabled={isLoading}
          label="Password"
        />

        <Field
          className="field--trans"
          msgClassName="fc--red-alpha-ltst"
          name="remember"
          component={InputField}
          disabled={isLoading}
          type="checkbox"
          label="Remember me"
        />

        {error ? getErrorComponent(error) : null}

        <Button
          className="push--smaller--bottom"
          modifiers={['purple-alpha', 'full']}
          type="submit"
        >
          {submitting || isLoading ? (
            <span>
              Logging in &nbsp;
              <Loader className="loader--spin" numIcons={1} />
            </span>
          ) : (
            <span>Log In</span>
          )}
        </Button>
      </form>
    );
  }
}

export default connectForm({
  form: 'LoginForm',
  validate: combineValidators({email: isEmail(), password: validatePassword}),
})(LoginForm);
