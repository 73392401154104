import PropTypes from 'prop-types';
import React from 'react';

import dialFactory from '../lib/dial-canvas';

class DialCanvas extends React.Component {
  static displayName = 'DialCanvas';

  static defaultProps = {
    className: 'dial-canvas',
  };

  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    onAnimate: PropTypes.func,
    options: PropTypes.shape({
      dialWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      maxWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      labelIncrement: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      numTicksBetweenLabels: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      unitText: PropTypes.string,
    }),
  };

  componentDidMount() {
    const {onAnimate} = this.props;
    const mount = this.mountRef;

    this.dial = dialFactory(mount);
    this.dial.init({
      height: mount.offsetHeight,
      onAnimate: onAnimate,
      width: mount.offsetWidth,
    });

    this.updateDial();
  }

  componentDidUpdate() {
    this.updateDial();
  }

  componentWillUnmount() {
    this.dial.destroy();
  }

  shouldComponentUpdate(nextProps) {
    const {id} = this.props;

    return id !== nextProps.id;
  }

  getHandAngle = () => {
    const {dialWeight, maxWeight} = this.props.options;

    return (+dialWeight / +maxWeight) * Math.PI * 2;
  };

  getTicksArr = () => {
    const {maxWeight, labelIncrement, numTicksBetweenLabels} = this.props.options;
    const numLabels = +maxWeight / +labelIncrement;
    const numTicks = Math.ceil(numLabels * (1 + +numTicksBetweenLabels));

    return Array.apply(null, {length: numTicks}).map((_, i) => i);
  };

  getLabelsArr = () => {
    const {maxWeight, labelIncrement} = this.props.options;
    const numLabels = Math.ceil(+maxWeight / +labelIncrement);

    return Array.apply(null, {length: numLabels}).map((_, i) => i * labelIncrement);
  };

  updateDial = () => {
    const {unitText} = this.props.options;
    const ticksArr = this.getTicksArr();
    const labelsArr = this.getLabelsArr();
    const handAngle = this.getHandAngle();

    this.dial.animateHand(handAngle);
    this.dial.setUnitText(unitText);
    this.dial.setTicks(ticksArr, labelsArr);
    this.dial.setLabels(labelsArr);
    this.dial.update();
  };

  render() {
    const {className} = this.props;

    return (
      <div data-testid="dial-canvas" className={`${className}`} ref={c => (this.mountRef = c)} />
    );
  }
}

export default DialCanvas;
