import React from 'react';

import {BoldText} from '../bold-text';

const FormattedText = ({text = '', whiteSpace = 'pre-wrap'}) => {
  return (
    <span className="formatted-text" data-css-white-space={whiteSpace}>
      <BoldText text={text} />
    </span>
  );
};

export {FormattedText};
