import {default as worldTypes} from './world-types';
import {default as daysOfTheWeek} from './days-of-the-week';
import {default as gameTemplates} from './game-templates';
import {default as gameCategories} from './game-categories';
import {default as soundData} from './sound-data';
import {badgeThresholds, portalColorSequence} from './player-portal';
import {MONEY_OPTIONS} from './money';
import {
  SEQUENCING_MATCH_BRACES_REGEX,
  SEQUENCING_MATCH_GROUPED_TEXT_REGEX,
} from './sequencing-challenge';

export default {
  MONEY_OPTIONS,
  SEQUENCING_MATCH_BRACES_REGEX,
  SEQUENCING_MATCH_GROUPED_TEXT_REGEX,
  badgeThresholds,
  daysOfTheWeek,
  gameCategories,
  gameTemplates,
  portalColorSequence,
  soundData,
  worldTypes,
};
