import harpArpeggioMp3 from '../../audio/challenges/harp-arpeggio.mp3';
import harpArpeggioWebm from '../../audio/challenges/harp-arpeggio.webm';
import synthSuccessWebm from '../../audio/challenges/synth-success.webm';
import synthSuccessMp3 from '../../audio/challenges/synth-success.mp3';
import twinkleFallMp3 from '../../audio/challenges/twinkle-fall.mp3';
import twinkleFallWebm from '../../audio/challenges/twinkle-fall.webm';
import xylophoneArpeggioMp3 from '../../audio/challenges/xylophone-arpeggio.mp3';
import xylophoneArpeggioWebm from '../../audio/challenges/xylophone-arpeggio.webm';

import boinkMp3 from '../../audio/challenges/boink.mp3';
import boinkWebm from '../../audio/challenges/boink.webm';
import pianoBonkMp3 from '../../audio/challenges/piano-bonk.mp3';
import pianoBonkWebm from '../../audio/challenges/piano-bonk.webm';
import synthAwwwMp3 from '../../audio/challenges/synth-awww.mp3';
import synthAwwwWebm from '../../audio/challenges/synth-awww.webm';
import synthBonkMp3 from '../../audio/challenges/synth-bonk.mp3';
import synthBonkWebm from '../../audio/challenges/synth-bonk.webm';
import synthOofMp3 from '../../audio/challenges/synth-oof.mp3';
import synthOofWebm from '../../audio/challenges/synth-oof.webm';
import synthWarningMp3 from '../../audio/challenges/synth-warning.mp3';
import synthWarningWebm from '../../audio/challenges/synth-warning.webm';

import drumPuhLopMp3 from '../../audio/challenges/drum-puh-lop.mp3';
import drumPuhLopWebm from '../../audio/challenges/drum-puh-lop.webm';
import bubblePopMp3 from '../../audio/challenges/bubble-pop.mp3';
import bubblePopWebm from '../../audio/challenges/bubble-pop.webm';
import lidOpenPopMp3 from '../../audio/challenges/lid-open-pop.mp3';
import lidOpenPopWebm from '../../audio/challenges/lid-open-pop.webm';
import metallicChirpMp3 from '../../audio/challenges/metallic-chirp.mp3';
import metallicChirpWebm from '../../audio/challenges/metallic-chirp.webm';
import tickMp3 from '../../audio/challenges/tick.mp3';
import tickWebm from '../../audio/challenges/tick.webm';

const createSoundConfig = src => {
  return {
    html5: false,
    mute: false,
    src,
  };
};

export default {
  // success sounds
  'harp-arpeggio': createSoundConfig([harpArpeggioWebm, harpArpeggioMp3]),
  'synth-success': createSoundConfig([synthSuccessWebm, synthSuccessMp3]),
  'twinkle-fall': createSoundConfig([twinkleFallWebm, twinkleFallMp3]),
  'xylophone-arpeggio': createSoundConfig([xylophoneArpeggioWebm, xylophoneArpeggioMp3]),

  // error sounds
  boink: createSoundConfig([boinkWebm, boinkMp3]),
  'piano-bonk': createSoundConfig([pianoBonkWebm, pianoBonkMp3]),
  'synth-awww': createSoundConfig([synthAwwwWebm, synthAwwwMp3]),
  'synth-bonk': createSoundConfig([synthBonkWebm, synthBonkMp3]),
  'synth-oof': createSoundConfig([synthOofWebm, synthOofMp3]),
  'synth-warning': createSoundConfig([synthWarningWebm, synthWarningMp3]),

  // neutral sounds
  'drum-puh-lop': createSoundConfig([drumPuhLopWebm, drumPuhLopMp3]),
  'bubble-pop': createSoundConfig([bubblePopWebm, bubblePopMp3]),
  'lid-open-pop': createSoundConfig([lidOpenPopWebm, lidOpenPopMp3]),
  'metallic-chirp': createSoundConfig([metallicChirpWebm, metallicChirpMp3]),
  tick: createSoundConfig([tickWebm, tickMp3]),
};
