import React from 'react';

import descriptions from './shared/descriptions';

export default {
  key: 'drag-and-drop-sort',
  name: 'Drag And Drop Sort',

  description: <div>{descriptions.categoryList}</div>,

  resources: {
    thumbnailUrl: '',
  },

  summary: {
    type: 'category-sort',
  },

  vars: {
    text: {
      label: 'Text',
      type: 'text',
      optional: true,
    },

    categories: {
      type: 'category-list',
      help: 'manage drag and drop categories here',
      label: 'Categories',
      name: 'drag-and-drop-category-list',
      maxLists: 3,
      hideDescription: true,
    },
  },
};
