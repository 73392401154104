import PropTypes from 'prop-types';
import React from 'react';
import {Loader, UserManagementContainer} from '@edu-game/assets';

import LinksFooter from '../LinksFooter';
import PageTitle from '../PageTitle';

import createApp from '../../feathers';
import routes from '../../routes';

const {location} = global;
const app = createApp();

class ConfirmationPage extends React.Component {
  static displayName = 'ConfirmationPage';

  static propTypes = {
    routeParams: PropTypes.object.isRequired,
  };

  state = {};

  componentWillMount() {
    const {token} = this.props.routeParams;
    app
      .service('/account/confirmations')
      .update(token, {})
      .then(() => {
        location.href = routes.redirectUser;
      })
      .catch(() => {
        this.setState({errored: true});
      });
  }

  render() {
    const {history} = this.props;
    const {success, errored} = this.state;

    return (
      <UserManagementContainer showBack history={history}>
        <PageTitle>Account confirmation</PageTitle>

        {!success && !errored ? <Loader /> : null}

        {errored ? <div className="alert--error">This confirmation token is not valid.</div> : null}

        <LinksFooter />
      </UserManagementContainer>
    );
  }
}

export default ConfirmationPage;
