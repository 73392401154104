// used in the admin for creating questions, and for
// displaying money images on challenges, and for stack options
const imgPathname = '/assets/img/games/challenges/money';

export const MONEY_OPTIONS = {
  poundsFifty: {
    gridWidth: '2-of-3',
    imgUrl: `${imgPathname}/money-pounds-fifty.svg`,
    label: '£50',
    name: 'Fifty Pounds',
    value: 5000,
  },

  poundsTwenty: {
    gridWidth: '2-of-3',
    imgUrl: `${imgPathname}/money-pounds-twenty.svg`,
    label: '£20',
    name: 'Twenty Pounds',
    value: 2000,
  },

  poundsTen: {
    gridWidth: '2-of-3',
    imgUrl: `${imgPathname}/money-pounds-ten.svg`,
    label: '£10',
    name: 'Ten Pounds',
    value: 1000,
  },

  poundsFive: {
    gridWidth: '2-of-3',
    imgUrl: `${imgPathname}/money-pounds-five.svg`,
    label: '£5',
    name: 'Five Pounds',
    value: 500,
  },

  poundsTwo: {
    gridWidth: '1-of-3',
    imgUrl: `${imgPathname}/money-pounds-two.svg`,
    label: '£2',
    name: 'Two Pounds',
    value: 200,
  },

  poundsOne: {
    gridWidth: '1-of-3',
    imgUrl: `${imgPathname}/money-pounds-one.svg`,
    label: '£1',
    name: 'One Pound',
    value: 100,
  },

  penceFifty: {
    gridWidth: '1-of-3',
    imgUrl: `${imgPathname}/money-pence-fifty.svg`,
    label: '50p',
    name: 'Fifty Pence',
    value: 50,
  },

  penceTwenty: {
    gridWidth: '1-of-3',
    imgUrl: `${imgPathname}/money-pence-twenty.svg`,
    label: '20p',
    name: 'Twenty Pence',
    value: 20,
  },

  penceTen: {
    gridWidth: '1-of-3',
    label: '10p',
    name: 'Ten Pence',
    imgUrl: `${imgPathname}/money-pence-ten.svg`,
    value: 10,
  },

  penceFive: {
    gridWidth: '1-of-3',
    label: '5p',
    name: 'Five Pence',
    imgUrl: `${imgPathname}/money-pence-five.svg`,
    value: 5,
  },

  penceTwo: {
    gridWidth: '1-of-3',
    label: '2p',
    name: 'Two Pence',
    imgUrl: `${imgPathname}/money-pence-two.svg`,
    value: 2,
  },

  penceOne: {
    gridWidth: '1-of-3',
    imgUrl: `${imgPathname}/money-pence-one.svg`,
    label: '1p',
    name: 'One Pence',
    value: 1,
  },
};
