import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';

import InlineSVGIcon from './InlineSVGIcon';

class SideMenuItem extends React.Component {
  static displayName = 'SideMenuItem';

  static propTypes = {
    children: PropTypes.any.isRequired,
    iconId: PropTypes.string,
    onClick: PropTypes.func,
    onlyActiveOnIndex: PropTypes.bool,
    to: PropTypes.string.isRequired,
  };

  render() {
    const {onlyActiveOnIndex, to, iconId, children, onClick} = this.props;

    return (
      <li className="side-menu__item">
        <Link
          activeClassName="is-active"
          onlyActiveOnIndex={onlyActiveOnIndex}
          className="side-menu__item-link"
          onClick={onClick}
          to={to}
        >
          {iconId ? (
            <InlineSVGIcon
              iconId={iconId}
              className="side-menu__item-img"
              classNameInner="side-menu__item-img__inner"
            />
          ) : null}

          <span className="side-menu__item-text">{children}</span>
        </Link>
      </li>
    );
  }
}

export default SideMenuItem;
