import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';
import Isvg from 'react-inlinesvg';

import bombDodgeFactory from '../lib/bomb-dodge';

import bombSvg from '../../img/raw/inline/games/challenges/bomb-dodge/bomb.svg';
import playerSvg from '../../img/raw/inline/games/challenges/bomb-dodge/player.svg';
import hintSvg from '../../img/raw/inline/games/challenges/bomb-dodge/hint.svg';

const SVGS = [
  {name: 'bomb', url: bombSvg},
  {name: 'hint', url: hintSvg},
  {name: 'player', url: playerSvg},
];
const DRAW_PLAYER_TIMEOUT = 1000;

class BombDodge extends React.Component {
  static displayName = 'BombDodge';

  static propTypes = {
    className: PropTypes.string,
    handleBombCollision: PropTypes.func,
    handleHintCollision: PropTypes.func,
    hints: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    isPaused: PropTypes.bool,
    isQuestionOver: PropTypes.bool,
    lastHint: PropTypes.object,
    lastLifeLost: PropTypes.object,
    numBombs: PropTypes.number,
  };

  static defaultProps = {className: 'bomb-dodge-canvas'};

  state = {
    height: 0,
    numSvgsLoaded: 0,
    width: 0,
  };

  timerId = null;

  componentDidUpdate(prevProps) {
    const {id, isQuestionOver, isPaused, lastHint, lastLifeLost} = this.props;

    if (!this.bombDodge) return;

    if (id !== prevProps.id) {
      this.handleNewHints();
      this.handleNewNumBombs();
    }

    if (isPaused !== prevProps.isPaused) {
      this.handlePauseUpdate();
    }

    if (isQuestionOver !== prevProps.isQuestionOver && isQuestionOver) {
      this.handleNewQuestion();
    }

    if (lastHint.timestamp !== prevProps.lastHint.timestamp) {
      this.handleHintToRemove();
    }

    if (lastLifeLost.timestamp !== prevProps.lastLifeLost.timestamp) {
      this.handleLifeLost();
    }
  }

  componentWillUnmount() {
    if (this.bombDodge) {
      this.bombDodge.destroy();
      window.removeEventListener('resize', this.updateCanvasDims);
    }

    clearTimeout(this.timerId);
  }

  handleNewHints = () => {
    const {hints} = this.props;

    this.bombDodge.drawHints(hints);
  };

  handleNewQuestion = () => {
    if (this.bombDodge.hasGameItem('player')) {
      this.bombDodge.destroyPlayer();
    }
  };

  handleNewNumBombs = () => {
    const {numBombs} = this.props;
    const normalisedBombs = !isNaN(Number(numBombs)) ? Number(numBombs) : 0;

    this.bombDodge.drawBombs(normalisedBombs);
  };

  handlePauseUpdate = () => {
    const {isPaused} = this.props;

    this.bombDodge.setPause(isPaused);
    this.handleDrawPlayer();
  };

  handleDrawPlayer = () => {
    const {isPaused} = this.props;

    clearTimeout(this.timerId);

    if (!isPaused && !this.bombDodge.hasGameItem('player')) {
      this.timerId = setTimeout(this.bombDodge.drawPlayer, DRAW_PLAYER_TIMEOUT);
    }
  };

  handleHintToRemove = () => {
    const {lastHint} = this.props;

    this.bombDodge.setHintToRemove(lastHint.hintId);
  };

  handleLifeLost = () => {
    const {lastLifeLost} = this.props;
    const shouldHurt = lastLifeLost.type === 'bomb';

    this.bombDodge.animatePlayer(shouldHurt);
  };

  handleSvgLoad = () => {
    this.setState(
      ({numSvgsLoaded}) => ({numSvgsLoaded: numSvgsLoaded + 1}),
      () => {
        if (this.state.numSvgsLoaded === SVGS.length && !this.bombDodge) {
          this.initialiseBombDodge();
        }
      },
    );
  };

  initialiseBombDodge = () => {
    const {onBombCollision, onHintCollision} = this.props;
    const mount = this.mountRef;
    const svgNodes = SVGS.reduce((acc, {name}) => {
      return {...acc, [name]: findDOMNode(this[`${name}Svg`]).querySelector('svg')};
    }, {});

    window.addEventListener('resize', this.updateCanvasDims);
    this.bombDodge = bombDodgeFactory(mount);
    this.bombDodge.init({
      height: mount.offsetHeight,
      width: mount.offsetWidth,
      handleHintCollision: onHintCollision,
      handleBombCollision: onBombCollision,
      svgNodes,
    });
    this.handleNewNumBombs();
    this.handleNewHints();
    this.handlePauseUpdate();
  };

  updateCanvasDims = () => {
    const {offsetWidth, offsetHeight} = this.mountRef;

    this.bombDodge.updateDims({width: offsetWidth, height: offsetHeight});

    this.handleDrawPlayer();
  };

  render() {
    const {className} = this.props;

    return (
      <div className={`${className}`}>
        <div className={`${className}__images`}>
          {SVGS.map(({url, name}) => (
            <Isvg
              cacheGetRequests
              key={name}
              onLoad={() => this.handleSvgLoad(name)}
              ref={c => (this[`${name}Svg`] = c)}
              src={url}
              uniquifyIDs={false}
            />
          ))}
        </div>

        <div className={`${className}__canvas`} ref={c => (this.mountRef = c)} />
      </div>
    );
  }
}

export default BombDodge;
