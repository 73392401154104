/* eslint-disable no-underscore-dangle */
import Two from '../two-no-conflict';

const hintFactory = ({debug = false, label, numGridDivisions, svgNode, twoInstance}) => {
  const paths = {};
  const hitRadiusScalar = 0.75;
  let center;
  let isActive = true;
  let dims;

  function init() {
    const {width, height} = twoInstance;
    const hintWidth = width / numGridDivisions;

    const group = twoInstance.makeGroup();
    const image = twoInstance.interpret(svgNode);
    const imageDims = image.getBoundingClientRect();
    const text = twoInstance.makeText(label, width / 2, height / 2);
    let hitArea;
    image.center();
    image.translation.set(twoInstance.width / 2, twoInstance.height / 2);
    text.weight = 'bold';
    text.baseline = 'auto';

    if (imageDims.width > hintWidth) {
      image.scale = hintWidth / imageDims.width;
    }

    if (debug) {
      hitArea = twoInstance.makeCircle(
        text.translation.x,
        text.translation.y,
        imageDims.width * hitRadiusScalar * image.scale,
      );
      hitArea.stroke = '#fff';
      hitArea.noFill();
      hitArea.opacity = 0.5;
    }

    group.add(...[hitArea, image, text].filter(Boolean));

    paths.group = group;
    paths.image = image;
    paths.label = text;
    setTranslation({x: 0, y: 0});
  }

  function setTranslation({x, y}) {
    paths.group.translation.set(x, y);
    twoInstance.update();
    dims = paths.image.getBoundingClientRect();
    paths.label.size = dims.width / 3;
    center = new Two.Vector(dims.left + dims.width / 2, dims.top + dims.height / 2);
  }

  function getProp(prop) {
    return paths.group[prop];
  }

  function getDims() {
    return dims;
  }

  function getLabel() {
    return label;
  }

  function getIsActive() {
    return isActive;
  }

  function isCollision(point) {
    const {width} = dims;
    const distance = center.distanceTo(point);
    const didCollide = distance <= width * hitRadiusScalar;

    return didCollide;
  }

  function destroy() {
    if (paths.group) {
      paths.group.remove(paths.group.children);
      twoInstance.remove(paths.group);
    }

    isActive = false;

    Object.keys(paths).map(key => delete paths[key]);
  }

  init();

  return {
    path: paths.group,
    destroy,
    getDims,
    getLabel,
    getProp,
    getIsActive,
    isCollision,
    setTranslation,
  };
};

export default hintFactory;
