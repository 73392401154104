import PropTypes from 'prop-types';
import React from 'react';

class SideMenu extends React.Component {
  static displayName = 'SideMenu';
  static propTypes = {
    children: PropTypes.object,
  };

  render() {
    const {children} = this.props;

    return <nav className="side-menu">{children}</nav>;
  }
}

export default SideMenu;
