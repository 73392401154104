import PropTypes from 'prop-types';
import React from 'react';

import waveCanvasFactory from '../lib/wave-canvas';

class WaveCanvas extends React.Component {
  static displayName = 'WaveCanvas';

  static defaultProps = {
    className: 'wave-canvas',
    numWaves: 4,
  };

  static propTypes = {
    className: PropTypes.string,
    duration: PropTypes.number,
    isPaused: PropTypes.bool,
    questionId: PropTypes.string,
    numWaves: PropTypes.number,
    onAnimEnd: PropTypes.func,
    onProgress: PropTypes.func,
  };

  state = {width: 0, height: 0};

  componentDidMount() {
    const {duration, isPaused, numWaves, onAnimEnd, onProgress} = this.props;
    const mount = this.mountRef;

    window.addEventListener('resize', this.updateCanvasDims);
    this.waveCanvas = waveCanvasFactory(mount);
    this.waveCanvas.init({
      height: mount.offsetHeight,
      width: mount.offsetWidth,
      onAnimEnd,
      onProgress,
    });
    this.waveCanvas.setDuration(duration);
    this.waveCanvas.drawWaves(numWaves);

    if (!isPaused) {
      this.waveCanvas.play();
    }
  }

  componentDidUpdate(prevProps) {
    const {isPaused, numWaves, questionId} = this.props;

    if (!this.waveCanvas) return;

    if (isPaused !== prevProps.isPaused) {
      this.handlePauseChange();
    }

    if (numWaves !== prevProps.numWaves) {
      this.handleNumWavesChange();
    }

    if (questionId !== prevProps.questionId) {
      this.handleNewQuestion();
    }
  }

  componentWillUnmount() {
    this.waveCanvas.destroy();
    window.removeEventListener('resize', this.updateCanvasDims);
  }

  handleNewQuestion = () => {
    const {duration} = this.props;

    this.waveCanvas.setDuration(duration);
    this.waveCanvas.setTimeElapsed(0);

    if (!this.waveCanvas.isPlaying()) {
      this.waveCanvas.play();
    }
  };

  handlePauseChange = () => {
    const fn = this.props.isPaused ? 'pause' : 'play';

    this.waveCanvas[fn]();
  };

  handleNumWavesChange = () => {
    const {numWaves} = this.props;

    this.waveCanvas.drawWaves(numWaves);
  };

  updateCanvasDims = () => {
    const {isPaused} = this.props;
    const {offsetWidth, offsetHeight} = this.mountRef;

    this.waveCanvas.updateDims({
      width: offsetWidth,
      height: offsetHeight,
    });

    if (!isPaused) {
      this.waveCanvas.play();
    }
  };

  render() {
    const {className} = this.props;

    return <div data-testid="wave-canvas" className={className} ref={c => (this.mountRef = c)} />;
  }
}

export default WaveCanvas;
