/* eslint-disable no-underscore-dangle */
import {COLORS} from './constants';

const PI = Math.PI;

const toDecimal = (num, decimals = 1) => +Number(num).toFixed(decimals);

const reflectionPointFactory = (twoInstance, {coords, numX, numY, refAngle, hasActionArea}) => {
  const radius = 6;
  const maxPointDistanceScalar = 10;
  // 44px touch area
  const minActionRadius = 22;
  let shapes = {};
  let isSelected = false;
  let isHint = false;
  let angle;

  function init() {
    initShapes();
    setAngle(refAngle);
  }

  function initShapes() {
    const loc = getCoords();
    const selectCircle = twoInstance.makeCircle(loc.x, loc.y, radius);
    const hintCircle = twoInstance.makeCircle(loc.x, loc.y, radius);
    let actionArea;

    hintCircle.noStroke();
    selectCircle.noStroke();
    selectCircle.noFill();

    if (hasActionArea) {
      actionArea = twoInstance.makeCircle(loc.x, loc.y, minActionRadius);
      actionArea.noFill();
      actionArea.noStroke();

      twoInstance.update();

      actionArea._renderer.elem.setAttribute('style', 'cursor: pointer');
    }

    twoInstance.update();

    [selectCircle, hintCircle].map((shape, i) => {
      const className = shape.className
        .split(' ')
        .concat(['reflection-point', i === 1 ? 'reflection-point-hint' : ''])
        .filter(Boolean)
        .join(' ');
      shape.className = className;

      if (shape._renderer.elem) {
        shape._renderer.elem.setAttribute('data-testid', 'reflection-point');
      }

      return shape;
    });

    shapes = {actionArea, hintCircle, selectCircle};
  }

  function getPointDistance(distanceScalar) {
    const {width, height} = twoInstance;
    const dim = Math.min(width, height);
    const numInAxis = Math.max(numX, numY);
    const spaceRequired = radius * distanceScalar * numInAxis + 2 * minActionRadius;

    return spaceRequired > dim ? getPointDistance(distanceScalar - 1) : distanceScalar;
  }

  function updateCenter() {
    Object.keys(shapes)
      .filter(k => !!shapes[k])
      .map(k => {
        const loc = getCoords();

        return shapes[k].translation.set(loc.x, loc.y);
      });
  }

  function getCoords() {
    const {width, height} = twoInstance;
    const center = {
      x: width / 2,
      y: height / 2,
    };
    const pointDistance = getPointDistance(maxPointDistanceScalar) * radius;

    return Object.keys(coords).reduce((acc, key) => {
      const numPoints = key === 'x' ? numX : numY;
      const val = coords[key];
      const median = numPoints / 2;
      const unitsFromMedian = Math.abs(val - median);
      const mul = val === median ? 0 : val > median ? 1 : -1;
      const coord =
        center[key] +
        unitsFromMedian * (pointDistance + 2 * radius) * mul +
        pointDistance / 2 +
        radius;

      return {...acc, [key]: coord};
    }, {});
  }

  function destroy() {
    twoInstance.remove(Object.keys(shapes).map(key => shapes[key]));

    Object.keys(shapes).map(key => delete shapes[key]);
  }

  function setSelected(selected) {
    isSelected = selected !== undefined ? selected : !isSelected;
    shapes.selectCircle.fill = isSelected ? COLORS.SELECTED : 'transparent';
    shapes.selectCircle.scale = isSelected ? 2 : 1;
    twoInstance.update();
  }

  function setHinted(hinted) {
    isHint = hinted !== undefined ? hinted : !isHint;
    shapes.hintCircle.fill = isHint ? COLORS.HINTED : COLORS.WHITE;
    twoInstance.update();
  }

  function setAngle(angleInRad) {
    angle = angleInRad;
  }

  function getReflection() {
    const getRefFn =
      angle === PI / 2 || angle === (3 * PI) / 2 ? getReflectionOverY : getReflectionOverLine;

    return getRefFn();
  }

  function getReflectionOverY() {
    const {x, y} = shapes.hintCircle.translation;
    const {width, height} = twoInstance;
    const o = {
      x: width / 2,
      y: height / 2,
    };

    return {
      x: x + 2 * (o.x - x),
      y,
    };
  }

  function getReflectionOverLine() {
    const {x, y} = shapes.hintCircle.translation;
    const {width, height} = twoInstance;
    const o = {
      x: width / 2,
      y: height / 2,
    };
    // http://stackoverflow.com/a/3307181/895007
    const m = Math.tan(angle);
    const mSquared = Math.pow(m, 2);
    const c = o.y - m * o.x;
    const d = (x + (y - c) * m) / (1 + mSquared);

    return {
      x: toDecimal(2 * d - x),
      y: toDecimal(2 * d * m - y + 2 * c),
    };
  }

  function getState() {
    const {x, y} = shapes.hintCircle.translation;

    return {
      path: shapes.hintCircle._renderer.elem,
      isHint,
      isSelected,
      pos: {
        x: toDecimal(x),
        y: toDecimal(y),
      },
      posReflected: getReflection(),
    };
  }

  init();

  return {
    destroy,
    getState,
    shapes,
    setAngle,
    setHinted,
    setSelected,
    updateCenter,
  };
};

export default reflectionPointFactory;
