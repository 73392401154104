import Two from '../two-no-conflict';

import circleFactory from './circle';
import rectangleFactory from './rectangle';
import polygonFactory from './polygon';
import {COLORS} from './utils';

const shapeFactory = mountElem => {
  if (!mountElem || !('nodeType' in mountElem)) {
    throw new Error('no mount element provided');
  }

  const polygonMap = {
    triangle: {sides: 3},
    pentagon: {sides: 5},
    hexagon: {sides: 6},
    octagon: {sides: 8},
    decagon: {sides: 10},
    dodecagon: {sides: 12},
  };
  const eventMap = {
    mousedown: handleCursorDown,
    touchstart: handleCursorDown,
    mouseup: handleCursorUp,
    touchend: handleCursorUp,
  };
  let center = {};
  let options = {
    attachEvents: false,
    type: Two.Types.svg,
    width: 360,
    height: 360,
    shapeName: null,
    divisions: null,
    selectedColor: null,
    // onSelect :: Two.Path -> null
    onSelect: null,
    // onDeselect :: Two.Path -> null
    onDeselect: null,
  };
  let shapeObj = {};
  let eventHandledByTouch = false;
  let two;

  function init(opts = {}) {
    options = {...options, ...opts};

    two = new Two({
      height: options.height,
      type: options.type,
      width: options.width,
    }).appendTo(mountElem);

    setCenter();

    if (options.attachEvents) {
      initEvents();
    }

    two.update();
    two.renderer.domElement.setAttribute('viewBox', `0 0 ${two.width} ${two.height}`);
    two.renderer.domElement.setAttribute(
      'style',
      `
        -moz-user-select:none;
        -ms-user-select:none;
        -webkit-user-select:none;
        user-select:none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
      `,
    );
  }

  function setCenter() {
    center = {
      x: two.width / 2,
      y: two.height / 2,
    };
  }

  function setOptions(opts = {}) {
    options = {...options, ...opts};
  }

  function drawShape() {
    const {shapeName, divisions} = options;
    const numDivisions = parseInt(divisions, 10);

    if (shapeObj.group) {
      two.remove(shapeObj.group);
      shapeObj = {};
    }

    switch (shapeName) {
      case 'circle':
        shapeObj = circleFactory({
          twoInstance: two,
          center,
          numDivisions,
        });
        break;
      case 'square':
      case 'rectangle':
        shapeObj = rectangleFactory({
          twoInstance: two,
          center,
          numDivisions,
          shapeType: shapeName,
        });
        break;

      default:
        shapeObj = polygonFactory({
          twoInstance: two,
          numSides: polygonMap[shapeName].sides,
          center,
          numDivisions,
        });
        break;
    }

    two.update();

    if (options.attachEvents) {
      // eslint-disable-next-line no-underscore-dangle
      shapeObj.group._renderer.elem.setAttribute('style', 'cursor: pointer');
    }
  }

  function initEvents() {
    const domElement = two.renderer.domElement;
    two.update();

    Object.keys(eventMap).map(type => domElement.addEventListener(type, eventMap[type]));
  }

  function getSelection() {
    return shapeObj.divisions.map(child => child.isSelected);
  }

  function setSelection(selectionArr) {
    return shapeObj.divisions.map((ch, i) => setDivisionSelectionState(ch, selectionArr[i], false));
  }

  function setDivisionSelectionState(division, isSelected, shouldCb = true) {
    const div = division;
    const selectFn = isSelected ? 'onSelect' : 'onDeselect';

    div.isSelected = isSelected;
    div.fill = div.isSelected ? options.selectedColor || COLORS.divFill : 'transparent';

    if (typeof options[selectFn] === 'function' && shouldCb) {
      options[selectFn](div);
    }

    two.update();
  }

  function setRandomSelection(num) {
    const {divisions} = shapeObj;
    const randDiv = divisions[Math.floor(Math.random() * divisions.length)];
    const randIndex = divisions.indexOf(randDiv);

    divisions.map(div => setDivisionSelectionState(div, false));

    Array.apply(null, Array(num)).map((_, i) => {
      const divToUpdate = divisions[(i + randIndex) % divisions.length];

      return setDivisionSelectionState(divToUpdate, true);
    });
  }

  function handleCursorDown(e) {
    const type = e.type;
    const shouldSetSelectedState =
      type === 'touchstart' || (type === 'mousedown' && !eventHandledByTouch);
    eventHandledByTouch = type === 'touchstart';

    if (shouldSetSelectedState) {
      const {group} = shapeObj;
      const id = e.target.id;
      const childIds = Object.keys(group.children.ids);

      if (childIds.indexOf(id) > -1) {
        const child = group.getById(id);
        setDivisionSelectionState(child, !child.isSelected);
      }
    }
  }

  function handleCursorUp() {}

  function destroy() {
    const domElement = two.renderer.domElement;

    Object.keys(eventMap).map(type => domElement.removeEventListener(type, eventMap[type]));

    //  eslint-disable-next-line no-param-reassign
    shapeObj.divisions.map(div => delete div.selected);
    ['divisions', 'shape', 'group'].map(key => delete shapeObj[key]);
    two.clear();
  }

  return {
    destroy,
    drawShape,
    getSelection,
    init,
    setOptions,
    setRandomSelection,
    setSelection,
  };
};

export default shapeFactory;
