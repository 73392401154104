import PropTypes from 'prop-types';
import React from 'react';

const InlineSVGIcon = ({className, classNameInner, iconId}) => (
  <i className={`${className || 'icon'}`}>
    <i className={`${classNameInner || 'icon__inner'}`}>
      <svg>
        <use xlinkHref={`#${iconId}`} />
      </svg>
    </i>
  </i>
);

InlineSVGIcon.displayName = 'InlineSVGIcon';

InlineSVGIcon.propTypes = {
  className: PropTypes.string,
  classNameInner: PropTypes.string,
  iconId: PropTypes.string.isRequired,
};

export default InlineSVGIcon;
