import PropTypes from 'prop-types';
import React from 'react';

import numberlineFactory from '../lib/numberline';

class NumberlineCanvas extends React.Component {
  static displayName = 'NumberlineCanvas';

  static propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.shape({
      labelIncrement: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      numTicksBetweenLabels: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      unit: PropTypes.string,
    }),
    onSelect: PropTypes.func,
    onSetHandlePosition: PropTypes.func,
  };

  static defaultProps = {
    className: '',
  };

  componentDidMount() {
    const {onSelect, onSetHandlePosition} = this.props;
    const mount = this.refMount;
    this.lastTickSoundPos = undefined;

    this.numberline = numberlineFactory(mount, {
      height: mount.offsetHeight,
      onSelect,
      onSetHandlePosition,
      width: mount.offsetWidth,
    });

    this.updateNumberline();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    this.updateNumberline();
  }

  componentWillUnmount() {
    this.numberline.destroy();
    window.removeEventListener('resize', this.handleResize);
  }

  shouldComponentUpdate(nextProps) {
    const {id} = this.props;

    return id !== nextProps.id;
  }

  updateNumberline = () => {
    const {max, min, numTicksBetweenLabels, unit, labelIncrement} = this.props.options;

    this.numberline.setOptions({max, min, numTicksBetweenLabels, unit, labelIncrement});
  };

  handleResize = () => {
    const {offsetHeight, offsetWidth} = this.refMount;

    this.numberline.updateDims({height: offsetHeight, width: offsetWidth});
  };

  render() {
    const {className} = this.props;

    return <div className={`numberline-canvas ${className}`} ref={c => (this.refMount = c)} />;
  }
}

export default NumberlineCanvas;
